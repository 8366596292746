import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesDatasource(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    listItem: {
      color: '#424242'
    },
    iconItem: {
      minWidth: 'auto'
    },
    dragItem: {
      cursor: 'move',
      minWidth: 'auto'
    },
    datasourceActions: {
      flexGrow: 0,
      maxWidth: '14%',
      flexBasis: '14%',
      [`& >div >div`]: {
        display: 'flex'
      }
    },
    datasourceName: {
      flexGrow: 0,
      maxWidth: '31.5%',
      flexBasis: '31.5%',
      [`& >div >span`]: {
        top: '1px',
        position: 'relative',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
      },
      [`${theme.breakpoints.down(1024)}`]: {
        maxWidth: '38%',
        flexBasis: '38%',
        [`& >div >span`]: {
          top: '1px',
          position: 'relative'
        }
      }
    },
    descriptionGridItem: {
      display: 'flex'
    },
    descriptionItem: {
      marginLeft: '10px',
      minWidth: 'auto'
    },
    gridSwitchContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginLeft: '8px'
    },
    gridSwitchButton: {
      height: '36px',
      width: '36px'
    },
    healthSucessIcon: {
      color: '#009a44'
    },
    healthErrorText: {
      color: '#f68d2e',
      paddingLeft: '4px',
      flexBasis: '30%',
      [`${theme.breakpoints.down(1024)}`]: {
        display: 'none'
      }
    },
    healthErrorIcon: {
      color: '#f45246'
    },
    healthUnstableIcon: {
      color: '#f68d2e'
    },
    listAction: {
      display: 'flex',
      alignItems: 'center'
    }
  }))
}
