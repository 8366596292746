import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import SettingsStore from 'store/Settings'
import UserSettings from './UserSettings'
import { trackPageView } from 'utils/tracking'
import { authProvider } from 'utils/reactad'
import { ISeoCheckerData } from 'utils/seoChecker'
import { MsalProvider } from '@azure/msal-react'

type IAppProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  any

const App = (props: IAppProps) => {
  const { setIsLandscape } = props
  const [showRoadmap, setShowRoadmap] = useState(false)
  const [showFaq, setShowFaq] = useState(false)
  const [isImproveResultsModalOpen, setImproveResultsModal] = useState(false)
  const [isSEOCheckerModalOpen, setSEOCheckerModal] = useState(false)
  const [seoCheckerModalData, setSEOCheckerModalData] =
    useState<ISeoCheckerData>({
      url: '',
      seoType: null,
      uniqueId: null,
      siteId: null
    })

  const [loginTracked, setLoginTracked] = useState(false)
  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState(undefined), [])

  // Enforce a rerender on orientation change as not all layouting is done purely in CSS
  window.addEventListener('orientationchange', (e: any) => {
    let isLS = false
    if (
      e &&
      e.target &&
      e.target.screen &&
      e.target.screen.orientation &&
      e.target.screen.orientation.angle &&
      e.target.screen.orientation.angle === 90
    ) {
      isLS = true
    }
    setIsLandscape(isLS)
    forceUpdate()
  })

  useEffect(() => {
    if (localStorage.getItem('simple.state')) {
      localStorage.clear()
    }
  }, [])

  // Track page view
  trackPageView()

  return (
    <MsalProvider instance={authProvider}>
      <UserSettings
        showRoadmap={showRoadmap}
        setShowRoadmap={setShowRoadmap}
        showFaq={showFaq}
        setShowFaq={setShowFaq}
        isImproveResultsModalOpen={isImproveResultsModalOpen}
        setImproveResultsModal={setImproveResultsModal}
        isSEOCheckerModalOpen={isSEOCheckerModalOpen}
        seoCheckerModalData={seoCheckerModalData}
        setSEOCheckerModal={setSEOCheckerModal}
        setSEOCheckerModalData={setSEOCheckerModalData}
        authProvider={authProvider}
        loginTracked={loginTracked}
        setLoginTracked={setLoginTracked}
        forceUpdate={forceUpdate}
      />
    </MsalProvider>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    authToken: AuthStore.selectors.getAuthToken(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setAuthToken: (authToken: string) =>
      dispatch(AuthStore.actions.setAuthToken(authToken)),
    setIsLandscape: (isLandscape: boolean) =>
      dispatch(SettingsStore.actions.setIsLandscape(isLandscape))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
