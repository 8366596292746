import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import { klardenker } from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IKlardenkerResult } from 'components/models/KlardenkerResult'

export enum ResultsKlardenkerActionTypes {
  FETCH_REQUEST = 'resultKlardenker/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultKlardenker/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultKlardenker/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultKlardenker/FETCH_FAILURE',
  INITIALIZE_RESULTS_KLARDENKER = 'persist/REHYDRATE'
}

export interface IFetchRequest
  extends CustomAction<ResultsKlardenkerActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsKlardenkerActionTypes>
export interface IFetchSuccess
  extends CustomAction<ResultsKlardenkerActionTypes> {
  payload: {
    response: {
      queryResults: IKlardenkerResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsKlardenkerActionTypes> {
  payload: {
    response: { queryResults: IKlardenkerResult[] }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsKlardenkerActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IKlardenkerResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsKlardenkerActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    featuredResults
  },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: { queryResults: IKlardenkerResult[] },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsKlardenkerActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsKlardenkerActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsKlardenker = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('klardenker')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('klardenker', actionMetaData)
    )
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      const internalPageNumber = parseInt(
        (((currentPage || 1) - 1) / 2).toString()
      )
      const internalPageStartIndex = (((currentPage || 1) - 1) % 2) * 20

      const apiUrl = getAPIUrlByOrigin(
        searchQuery,
        filters,
        userSettings,
        internalPageNumber + 1
      )

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'klardenker',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      const t1 = performance.now()

      const executionTime = t1 - t0

      if (results && 'body' in results) {
        const primaryResults = results.body.splice(
          internalPageStartIndex,
          internalPageStartIndex + 20
        )

        let resultCount = results.body.length || 0

        if (results && results.headers && results.headers['X-WP-Total']) {
          resultCount = results.headers['X-WP-Total']
        }

        const klardenkerResult: IKlardenkerResult[] = []

        primaryResults.forEach((item: any) => {
          const title =
            item.title && item.title.rendered
              ? stripHtml(item.title.rendered)
              : ''
          const body =
            item.excerpt && item.excerpt.rendered
              ? stripHtml(item.excerpt.rendered)
              : ''
          const date = item.date ? item.date : ''
          const resultUrl = item.link ? item.link : ''
          let imageUrl = ''
          if (
            item._embedded &&
            item._embedded['wp:featuredmedia'] &&
            item._embedded['wp:featuredmedia'][0] &&
            item._embedded['wp:featuredmedia'][0].media_details &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes
              .thumbnail &&
            item._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail
              .source_url
          ) {
            imageUrl =
              item._embedded['wp:featuredmedia'][0].media_details.sizes
                .thumbnail.source_url
          } else {
            imageUrl = ''
          }

          klardenkerResult.push({
            id: item.id ? item.id : '',
            title: title,
            body: body,
            date: date,
            url: resultUrl,
            image: imageUrl
          })
        })

        dispatch(
          fetchSuccess(
            {
              queryResults: klardenkerResult,
              resultCount,
              executionTime,
              currentPage: currentPage || 1
            },
            featuredResults,
            actionMetaData
          )
        )
        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'klardenker',
            {
              executionTime: executionTime,
              resultsCount: resultCount
            },
            actionMetaData
          )
        )

        if (deviceSettings.renderMobile) {
          const resultsCombined = Selectors.getResultsCombined(getState())
          if ((currentPage || 1) === 1) {
            dispatch(
              fetchSuccessCombined(
                {
                  queryResults: klardenkerResult
                },
                actionMetaData
              )
            )
          } else {
            dispatch(
              fetchSuccessCombined(
                {
                  queryResults: [...resultsCombined, ...klardenkerResult]
                },
                actionMetaData
              )
            )
          }
        }
      } else {
        dispatch(
          fetchSuccess(
            {
              queryResults: [],
              resultCount: 0,
              executionTime,
              currentPage: currentPage || 1
            },
            featuredResults,
            actionMetaData
          )
        )
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'klardenker',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

const getAPIUrlByOrigin = (
  searchQuery: string,
  filters: {
    [key: string]: string
  },
  userSettings: IUserSetting,
  internalPageNumber: number
): string => {
  let apiUrl = `${
    Config.APIM_BASE_URL
  }klardenkerapi/getsearch?search=${encodeURIComponent(searchQuery)}`
  if (filters.orderBy) {
    const filterOBCat = klardenker.find(
      (fl: FilterCategory) => fl.key === 'orderBy'
    )
    if (filterOBCat) {
      const filterOBOpt = filterOBCat.options.find(
        (opt: Filter) => opt.key === filters.orderBy
      )
      if (filterOBOpt && filterOBOpt.value) {
        apiUrl += `&orderby=${filterOBOpt.value}`
      }
    }
  }

  apiUrl += `&page=${internalPageNumber}&processFeaturedResults=${
    internalPageNumber === 1 ? true : false
  }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
    userSettings.Country
  }&function=${userSettings.Function}`

  return apiUrl
}

export type ResultsKlardenkerActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
