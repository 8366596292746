import * as React from 'react'
import { IAADState } from 'store/Auth/reducers'
import { getTranslations } from 'utils/languageApi'
import { IResultTranslations } from 'components/models/Translation'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Typography
} from '@mui/material'
import {
  SupportedLanguage,
  supportedLanguages
} from 'constants/supportedLanguages'
import { useIntl } from 'react-intl'
import TranslateIcon from '@mui/icons-material/Translate'
import { getStylesTranslationDialog } from 'styles/contents/common/TranslationDialog'
import { useState } from 'react'
import UserSettingsStore from 'store/UserSettings'
import { Store } from 'store'
import { connect } from 'react-redux'

interface ITranslationDialogProps {
  isOpen: boolean
  closeDialog: (state: boolean) => void
  aadInfo: IAADState
  translationResult: IResultTranslations
  body: string
  setTranslationResult: (translationResult: IResultTranslations) => void
}

type AllProps = ReturnType<typeof mapStateToProps> & ITranslationDialogProps

function TranslationDialog(props: AllProps) {
  const {
    isOpen,
    closeDialog,
    aadInfo,
    translationResult,
    body,
    setTranslationResult,
    userSettings
  } = props

  const intl = useIntl()
  const classes = getStylesTranslationDialog()
  const [translationRunning, setTranslationRunning] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(
    userSettings.Language ? userSettings.Language : 'en'
  )

  const handleTranslate = () => {
    if (translationResult.hide && translationResult.to === selectedLanguage) {
      translationResult.hide = false
      setTranslationResult({ ...translationResult })
      closeDialog(false)

      return
    }

    setTranslationRunning(true)
    if (body && translationResult.values.length === 1) {
      translationResult.values.push({
        text: body.replace(/(\r\n|\n|\r)/gm, ' ')
      })
    }

    translationResult.to = selectedLanguage
    translationResult.supportedLanguage = supportedLanguages.find(
      (language: SupportedLanguage) => language.locale === selectedLanguage
    )
    translationResult.hide = false

    getTranslations(translationResult, aadInfo).then(
      (result: IResultTranslations) => {
        setTranslationRunning(false)
        setTranslationResult(result)
        closeDialog(false)
      }
    )
  }
  const languages = supportedLanguages

  return (
    <Dialog
      onClose={() => closeDialog(false)}
      open={isOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogContent>
        <Typography
          variant="h5"
          color="primary"
          component="div"
          className={classes.headline}
        >
          Translate title and description
        </Typography>
        <div>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.listItem}
          >
            <Grid item xs={2} sm={1}>
              <ListItemIcon className={classes.iconContainer}>
                <TranslateIcon className={classes.iconItem} />
              </ListItemIcon>
            </Grid>
            <Grid item xs={5} sm={6}>
              <ListItemText primary={'Select target language'} />
            </Grid>
            <Grid item xs={5} sm={5}>
              <Select
                key="languageSelect"
                variant={'standard'}
                disableUnderline
                value={selectedLanguage}
                className={classes.select}
                onChange={(event) => {
                  const updatedLanguage: string = event.target.value as string
                  setSelectedLanguage(updatedLanguage)
                }}
              >
                {languages &&
                  languages.map((entry: any, index: number) => (
                    <MenuItem key={index} value={entry.locale}>
                      {intl.formatMessage({
                        id: entry.translationId,
                        defaultMessage: entry.name
                      })}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
      <DialogActions>
        <Box
          justifyContent="space-evenly"
          display="flex"
          paddingBottom="10px"
          marginRight="16px"
        >
          <Button
            color="primary"
            size="small"
            onClick={() => closeDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleTranslate()}
            style={{ marginLeft: '10px' }}
          >
            <div>Translate</div>
            {translationRunning && (
              <CircularProgress className={classes.loadingSpinner} size={15} />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state)
  }
}

export default connect(mapStateToProps)(TranslationDialog)
