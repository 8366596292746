import * as Config from 'config'
import * as Filters from 'constants/filters'
import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { FetchWithCache } from 'utils/api'
import {
  buildRefinementFilters,
  getLanguageKeyFromName,
  sanitizeAppliedFilters,
  getLanguageNameFromKey
} from 'utils/filters'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { Store } from '..'
import AuthStore from '../Auth'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import * as Constants from 'constants/constants'
import { renewAuthorizationToken } from 'utils/token'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IFindConfiguration } from 'store/Settings/reducers'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { INewsResult } from 'components/models/NewsResult'

export enum ResultsNewsActionTypes {
  FETCH_REQUEST = 'resultsNews/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsNews/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsNews/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsNews/FETCH_FAILURE',
  FETCH_FILTERS_REQUEST = 'resultsNews/FETCH_FILTERS_REQUEST',
  FETCH_FILTERS_SUCCESS = 'resultsNews/FETCH_FILTERS_SUCCESS',
  FETCH_FILTERS_FAILURE = 'resultsNews/FETCH_FILTERS_FAILURE',
  INITIALIZE_RESULTS_NEWS = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsNewsActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
    resetResults: boolean
  }
}
export type IFetchFailure = CustomAction<ResultsNewsActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsNewsActionTypes> {
  payload: {
    response: {
      queryResults: INewsResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsNewsActionTypes> {
  payload: {
    response: {
      queryResults: INewsResult[]
    }
  }
}
export interface IFetchFiltersRequest
  extends CustomAction<ResultsNewsActionTypes> {
  payload: {
    resetFilters: boolean
  }
}
export type IFetchFiltersFailure = CustomAction<ResultsNewsActionTypes>
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsNewsActionTypes> {
  payload: {
    responseFilters: any
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  resetResults: boolean,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsNewsActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery,
    resetResults: Boolean(resetResults)
  },
  metaData: actionMetaData
})
export const fetchSuccess = (
  response: {
    queryResults: INewsResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsNewsActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: {
    queryResults: INewsResult[]
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsNewsActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsNewsActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchFiltersRequest = (
  actionMetaData: ActionMetaData,
  resetFilters?: boolean
): IFetchFiltersRequest => ({
  type: ResultsNewsActionTypes.FETCH_FILTERS_REQUEST,
  payload: { resetFilters: Boolean(resetFilters) },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchFiltersSuccess = (
  responseFilters: any = {},
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsNewsActionTypes.FETCH_FILTERS_SUCCESS,
  payload: { responseFilters },
  metaData: actionMetaData
})
export const fetchFiltersFailure = (
  actionMetaData: ActionMetaData
): IFetchFiltersFailure => ({
  type: ResultsNewsActionTypes.FETCH_FILTERS_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsNews = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('news')

    dispatch(ResultMetaDataStore.actions.fetchRequest('news', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(
          fetchRequest(false, searchQuery, true, {
            ...actionMetaData,
            isStartAction: true
          })
        )
      } else {
        dispatch(
          fetchRequest(true, searchQuery, true, {
            ...actionMetaData,
            isStartAction: true
          })
        )
      }
    } else {
      dispatch(
        fetchRequest(false, searchQuery, true, {
          ...actionMetaData,
          isStartAction: true
        })
      )
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const t0 = performance.now()

      let apiUrl = ''

      const sliceStartIndex = ((currentPage || 1) - 1) * 20

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
      }

      const queryText = removeStopWordsFromQuery(
        searchQuery,
        searchQueryLanguage,
        useCognitiveSearch,
        findConfiguration.CognitiveSearchEnabled
      )

      const refinementFilters = buildRefinementFilters(filters, Filters.news)
      if (refinementFilters.length > 0) {
        apiUrl = `${
          Config.APIM_BASE_URL
        }lexisnexisapi/getsearch/?query=${unifysearchQuery(
          queryText,
          'news'
        )}&expand=false&skip=${sliceStartIndex}${refinementFilters}&top=20&groupDuplicates=ModerateSimilarity&processFeaturedResults=${
          (currentPage || 1) === 1 ? true : false
        }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
          userSettings.Country
        }&function=${userSettings.Function}`
      } else {
        apiUrl = `${
          Config.APIM_BASE_URL
        }lexisnexisapi/getsearch/?query=${unifysearchQuery(
          searchQuery,
          'news'
        )}&expand=false&skip=${sliceStartIndex}&top=20&groupDuplicates=ModerateSimilarity&processFeaturedResults=${
          (currentPage || 1) === 1 ? true : false
        }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
          userSettings.Country
        }&function=${userSettings.Function}`
      }

      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        apiUrl += '&cognitiveEnabled=true'
      }

      if (filters.orderBy) {
        const filterOBCat = Filters.news.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            apiUrl += `&orderBy=${filterOBOpt.value}`
          }
        }
      }

      let fetchedResults

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'news',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        fetchedResults = response.responseJSON
      }

      const results = {
        News: fetchedResults && fetchedResults.News ? fetchedResults.News : [],
        TotalResults:
          fetchedResults && fetchedResults.TotalResults
            ? fetchedResults.TotalResults
            : 0
      }

      const featuredResults =
        fetchedResults && fetchedResults.FeaturedResults
          ? fetchedResults.FeaturedResults
          : []
      const synonymsApplied =
        fetchedResults && fetchedResults.SynonymsApplied
          ? fetchedResults.SynonymsApplied
          : []

      const t1 = performance.now()

      const newsResults: INewsResult[] = []

      results.News.forEach((i: any) => {
        const newsResult: INewsResult = {
          Title: stripHtml(i.Title),
          Summary: stripHtml(i.Summary.replace('... ', '')),
          Link: i.Link,
          Date: i.Date,
          Source: i.Source && i.Source.Name ? i.Source.Name : ''
        }

        newsResults.push(newsResult)
      })

      const executionTime = t1 - t0
      // In case TotalResults is not available
      const calculatedResultCount = results.News ? results.News.length : 0

      const resultCount = results.TotalResults || calculatedResultCount || 0

      // Dispatch reset filters false
      const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
      if (resultsCombinedQuery !== searchQuery) {
        if (resultsCombinedQuery === '') {
          dispatch(
            fetchRequest(false, searchQuery, false, {
              ...actionMetaData,
              isStartAction: false
            })
          )
        } else {
          dispatch(
            fetchRequest(true, searchQuery, false, {
              ...actionMetaData,
              isStartAction: false
            })
          )
        }
      } else {
        dispatch(
          fetchRequest(false, searchQuery, false, {
            ...actionMetaData,
            isStartAction: false
          })
        )
      }

      dispatch(
        fetchSuccess(
          {
            queryResults: newsResults,
            executionTime,
            resultCount,
            currentPage: currentPage || 1
          },
          featuredResults,
          synonymsApplied,
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'news',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )
      if (deviceSettings.renderMobile) {
        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: newsResults
              },
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...newsResults]
              },
              actionMetaData
            )
          )
        }
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'news',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export const fetchResultsNewsFilters = (
  searchQuery: string,
  currentPage: number,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('news', 'filters')

    dispatch(fetchFiltersRequest(actionMetaData))

    try {
      const state = getState()

      // Save initial filters from store
      const existingFilterObject = state.resultsNews.filters

      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFiltersFailure(actionMetaData))
        return
      }

      // Performs the expanded filters request
      const newsFilterResponse = await makeNewsFilterRequest(
        searchQuery,
        currentPage,
        filters,
        getState,
        findConfiguration
      )

      // Workaround to make sure we are actually displaying information for which country we are filtering at the moment
      const languageEnabledFilterFallback: Filter = {
        name: getLanguageNameFromKey(filters['language']),
        key: 'language',
        value: filters['language'],
        image: ''
      }

      // Obtain all already existing filter values in store
      const languageEnabledFilter = obtainCurrenFilterForId(
        state,
        filters,
        'language',
        'language',
        filters['language'] ? languageEnabledFilterFallback : null
      )

      const publicationtypeEnabledFilter = obtainCurrenFilterForId(
        state,
        filters,
        'publicationtype',
        'publicationtype'
      )
      const publisherEnabledFilter = obtainCurrenFilterForId(
        state,
        filters,
        'publisher',
        'publisher'
      )

      let retrievedFiltersLanguage = retrieveFiltersForLanguage(
        newsFilterResponse.result,
        'language'
      )
      let retrievedFiltersPublicationtype = retrieveFiltersForId(
        newsFilterResponse.result,
        'publicationtype'
      )
      let retrievedFiltersPublisher = retrieveFiltersForId(
        newsFilterResponse.result,
        'publisher'
      )

      const refetchedResponse = await handleNewsFilterReFetch(
        searchQuery,
        currentPage,
        filters,
        {
          language: retrievedFiltersLanguage,
          publicationtype: retrievedFiltersPublicationtype,
          publisher: retrievedFiltersPublisher
        },
        getState,
        findConfiguration
      )
      retrievedFiltersLanguage = refetchedResponse.retrievedFiltersLanguage
      retrievedFiltersPublicationtype =
        refetchedResponse.retrievedFiltersPublicationtype
      retrievedFiltersPublisher = refetchedResponse.retrievedFiltersPublisher

      const resultFiltersLanguage: Filter[] = [
        ...(languageEnabledFilter != null &&
        !contains(retrievedFiltersLanguage, languageEnabledFilter)
          ? [languageEnabledFilter]
          : []),
        ...retrievedFiltersLanguage
      ]

      const resultFiltersPublicationtype: Filter[] = [
        ...(publicationtypeEnabledFilter != null &&
        !contains(retrievedFiltersPublicationtype, publicationtypeEnabledFilter)
          ? [publicationtypeEnabledFilter]
          : []),
        ...retrievedFiltersPublicationtype
      ]
      const resultFiltersPublisher: Filter[] = [
        ...(publisherEnabledFilter != null &&
        !contains(retrievedFiltersPublisher, publisherEnabledFilter)
          ? [publisherEnabledFilter]
          : []),
        ...retrievedFiltersPublisher
      ]

      // Pass filter names as values for lexis as their API does not handle the keys properly
      for (const filter of resultFiltersLanguage) {
        filter.key = getLanguageKeyFromName(filter.name)
      }

      for (const filter of resultFiltersPublisher) {
        filter.key = filter.name
      }

      let filterObject = {
        currentQuery: searchQuery,
        language: resultFiltersLanguage,
        publicationtype: resultFiltersPublicationtype,
        publisher: resultFiltersPublisher
      }

      if (existingFilterObject.currentQuery === searchQuery) {
        const sanitizedFilters = sanitizeAppliedFilters(
          Filters.news,
          filters,
          [],
          true
        )
        let languageFilterObject = existingFilterObject.language
        let publicationtypeFilterObject = existingFilterObject.publicationtype
        let publisherFilterObject = existingFilterObject.publisher
        const sanitizedFilterKeys = Object.keys(sanitizedFilters)

        if (sanitizedFilterKeys.length > 1) {
          Object.keys(sanitizedFilters).map((currentlyAppliedFilter) => {
            // If filter is being applied, do not override it's content
            languageFilterObject =
              currentlyAppliedFilter === 'language'
                ? existingFilterObject &&
                  existingFilterObject.language &&
                  existingFilterObject.language.length > 0
                  ? existingFilterObject.language
                  : resultFiltersLanguage
                : resultFiltersLanguage
            publicationtypeFilterObject =
              currentlyAppliedFilter === 'publicationtype'
                ? existingFilterObject &&
                  existingFilterObject.publicationtype &&
                  existingFilterObject.publicationtype.length > 0
                  ? existingFilterObject.publicationtype
                  : resultFiltersPublicationtype
                : resultFiltersPublicationtype
            publisherFilterObject =
              currentlyAppliedFilter === 'publisher'
                ? existingFilterObject &&
                  existingFilterObject.publisher &&
                  existingFilterObject.publisher.length > 0
                  ? existingFilterObject.publisher
                  : resultFiltersPublisher
                : resultFiltersPublisher
            return false
          })
        }

        filterObject = {
          currentQuery: searchQuery,
          language:
            !resultFiltersLanguage || resultFiltersLanguage.length <= 0
              ? languageFilterObject
              : resultFiltersLanguage,
          publicationtype:
            !resultFiltersPublicationtype ||
            resultFiltersPublicationtype.length <= 0
              ? publicationtypeFilterObject
              : resultFiltersPublicationtype,
          publisher:
            !resultFiltersPublisher || resultFiltersPublisher.length <= 0
              ? publisherFilterObject
              : resultFiltersPublisher
        }
      }

      dispatch(
        fetchFiltersSuccess(
          {
            filters: filterObject
          },
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(fetchFailure(actionMetaData))
      dispatch(fetchFiltersFailure(actionMetaData))
      trackException('Error in fetching results news filters action', error)
    }
  }
}

interface INewsFilterRepsonse {
  result: any
  markedKey?: string
}

const handleNewsFilterReFetch = async (
  searchQuery: string,
  currentPage: number,
  filters: {
    [key: string]: string
  },
  filterResults: any,
  getState: () => Store,
  findConfiguration: IFindConfiguration
) => {
  const sanitizedFilters = sanitizeAppliedFilters(
    Filters.news,
    filters,
    [],
    true
  )
  const sanitizedFilterKeys = Object.keys(sanitizedFilters)
  const markedKeys: string[] = []
  const newFilters: any = { ...sanitizedFilters }

  sanitizedFilterKeys.forEach((key) => {
    const result = filterResults[key.toLowerCase()]
    if (result && result.length === 0) {
      markedKeys.push(key.toLowerCase())
    }
  })

  const filterCountResult = {
    retrievedFiltersLanguage: filterResults.language,
    retrievedFiltersPublicationtype: filterResults.publicationtype,
    retrievedFiltersPublisher: filterResults.publisher
  }

  const newsFilterReponseArray: Array<Promise<INewsFilterRepsonse>> = []

  markedKeys.forEach((key: string) => {
    const filtersToUse = { ...newFilters }
    delete filtersToUse[key]
    newsFilterReponseArray.push(
      makeNewsFilterRequest(
        searchQuery,
        currentPage,
        filtersToUse,
        getState,
        findConfiguration,
        key
      )
    )
  })

  await Promise.all(newsFilterReponseArray).then((values) => {
    if (values) {
      values.forEach((newsFilterResponse: INewsFilterRepsonse) => {
        switch (newsFilterResponse.markedKey) {
          case 'language':
            if (newsFilterResponse.result)
              filterCountResult.retrievedFiltersLanguage =
                retrieveFiltersForLanguage(
                  newsFilterResponse.result,
                  'language'
                )
            break
          case 'publicationtype':
            if (newsFilterResponse.result)
              filterCountResult.retrievedFiltersPublicationtype =
                retrieveFiltersForId(
                  newsFilterResponse.result,
                  'publicationtype'
                )
            break
          case 'publisher':
            if (newsFilterResponse.result)
              filterCountResult.retrievedFiltersPublisher =
                retrieveFiltersForId(newsFilterResponse.result, 'publisher')
            break
        }
      })
    }
  })

  return filterCountResult
}

const makeNewsFilterRequest = async (
  searchQuery: string,
  currentPage: number,
  filters: {
    [key: string]: string
  },
  getState: () => Store,
  findConfiguration: IFindConfiguration,
  markedKey?: string
): Promise<INewsFilterRepsonse> => {
  let apiUrl = ''

  const sliceStartIndex = ((currentPage || 1) - 1) * 20

  const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
    getState()
  )

  const refinementFilters = buildRefinementFilters({ ...filters }, Filters.news)
  if (refinementFilters.length > 0) {
    apiUrl = `${
      Config.APIM_BASE_URL
    }lexisnexisapi/getsearch/?query=${unifysearchQuery(
      searchQuery,
      'news'
    )}&expand=true&skip=${sliceStartIndex}${refinementFilters}&top=1&groupDuplicates=ModerateSimilarity`
  } else {
    apiUrl = `${
      Config.APIM_BASE_URL
    }lexisnexisapi/getsearch/?query=${unifysearchQuery(
      searchQuery,
      'news'
    )}&expand=true&skip=${sliceStartIndex}&top=1&groupDuplicates=ModerateSimilarity`
  }

  if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
    apiUrl += '&cognitiveEnabled=true'
  }

  if (filters.orderBy) {
    const filterOBCat = Filters.news.find(
      (fl: FilterCategory) => fl.key === 'orderBy'
    )
    if (filterOBCat) {
      const filterOBOpt = filterOBCat.options.find(
        (opt: Filter) => opt.key === filters.orderBy
      )
      if (filterOBOpt && filterOBOpt.value) {
        apiUrl += `&orderBy=${filterOBOpt.value}`
      }
    }
  }

  const newsFilterResponse: INewsFilterRepsonse = {
    markedKey: markedKey,
    result: null
  }

  // Get and check authentication token
  const aadInfo = AuthStore.selectors.getAADInfo(getState())
  const esToken = await renewAuthorizationToken(
    aadInfo.accessToken,
    aadInfo.instance,
    aadInfo.accounts
  )
  if (esToken !== aadInfo.accessToken) {
    AuthStore.actions.setAuthToken(esToken)
  }
  if (esToken === '') {
    throw new Error('Authentication: Cannot renew authentication token')
  }

  const response = await FetchWithCache(apiUrl, {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
      'content-type': 'application/json',
      Authorization: `Bearer ${esToken}`
    }
  })

  if (!response || response.hasError || !response.responseJSON) {
    return newsFilterResponse
  } else {
    newsFilterResponse.result = response.responseJSON
  }

  return newsFilterResponse
}

const retrieveFiltersForId = (results: any, id: string) => {
  return results && results.News
    ? results.News.filter((newsEntry: any) => Boolean(newsEntry.PostFilters))
        .map((newsEntry: any) => newsEntry.PostFilters)
        .flat()
        .filter((postFilter: any) => postFilter.PostFilterId === id)
        .map((postFilter: any) => postFilter.FilterItems)
        .flat()
        .map((filterItem: any) => ({
          name: filterItem.Name,
          key: filterItem.Id,
          image: '',
          resultCount: filterItem.Count
        }))
    : []
}

const retrieveFiltersForLanguage = (results: any, id: string) => {
  return results && results.News
    ? results.News.filter((newsEntry: any) => Boolean(newsEntry.PostFilters))
        .map((newsEntry: any) => newsEntry.PostFilters)
        .flat()
        .filter((postFilter: any) => postFilter.PostFilterId === id)
        .map((postFilter: any) => postFilter.FilterItems)
        .flat()
        .filter(
          (filterItem: any) =>
            Object.values(Constants.lexisNexisLanguages).indexOf(
              filterItem.Name
            ) > -1
        )
        .map((filterItem: any) => ({
          name: filterItem.Name,
          key: filterItem.Id,
          image: '',
          resultCount: filterItem.Count
        }))
    : []
}

// Obtains already existing filter for an id from the store
const obtainCurrenFilterForId = (
  state: any,
  enabledFilters: any,
  id: string,
  storeKey: string,
  fallback: any = null
) => {
  // get list of available filters for the news source
  const resultsNews = state['resultsNews']

  if (!resultsNews) {
    return fallback
  }

  const resultsNewsFilters = resultsNews['filters']

  if (!resultsNewsFilters) return fallback

  const resultsNewsFiltersForId = resultsNewsFilters[storeKey]

  if (!resultsNewsFiltersForId) return fallback

  if (!enabledFilters) return fallback

  const enabledFilterKey = enabledFilters[id]

  if (!enabledFilterKey) return fallback

  for (const filter of resultsNewsFiltersForId) {
    if (filter.key === enabledFilterKey) {
      return filter
    }
  }

  return fallback
}

const contains = (filters: Filter[], filter: Filter, comparisonKey = 'key') => {
  for (const currentFilter of filters) {
    const currentFilterKey = getLanguageKeyFromName(currentFilter.name)
    const filterKey = getLanguageKeyFromName(filter.name)
    if (currentFilterKey === filterKey) {
      return true
    }
  }

  return false
}

export type ResultsNewsActions =
  | IFetchRequest
  | IFetchFailure
  | IFetchSuccess
  | IFetchFiltersRequest
  | IFetchFiltersFailure
  | IFetchFiltersSuccess
