import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import { stripHtml } from 'utils/string'
import { renewAuthorizationToken } from 'utils/token'
import { FetchWithCache } from 'utils/api'
import { sanitizeAppliedFilters } from 'utils/filters'
import * as Filters from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { createModifiedFilterSource } from 'utils/filterslastmodified'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IFindConfiguration } from 'store/Settings/reducers'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { ISourceResult } from 'components/models/SourceResult'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'

export enum ResultsSourceActionTypes {
  FETCH_REQUEST = 'resultsSource/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsSource/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsSource/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsSource/FETCH_FAILURE',
  INITIALIZE_RESULTS_SOURCE = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsSourceActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsSourceActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsSourceActionTypes> {
  payload: {
    response: {
      queryResults: ISourceResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsSourceActionTypes> {
  payload: {
    response: { queryResults: ISourceResult[] }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsSourceActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: ISourceResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsSourceActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  metaData: actionMetaData
})

export const fetchSuccessCombined = (
  response: {
    queryResults: ISourceResult[]
  },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsSourceActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsSourceActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsSource = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('source')

    dispatch(ResultMetaDataStore.actions.fetchRequest('source', actionMetaData))
    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      const sliceStartIndex = ((currentPage || 1) - 1) * 20

      const sanitizedFilters = sanitizeAppliedFilters(
        Filters.source,
        filters,
        [],
        true
      )

      let filterString = ''
      let defaultTypeFilterString =
        '+and+type+in+(%22page%22%2C%22attachment%22)'
      if (sanitizedFilters) {
        if (sanitizedFilters['type']) {
          filterString += sanitizedFilters['type']
          defaultTypeFilterString = ''
        }

        if (sanitizedFilters['lastmodified'])
          filterString = createModifiedFilterSource(
            filterString,
            sanitizedFilters['lastmodified']
          )

        if (sanitizedFilters['space'])
          filterString += '+and+' + sanitizedFilters['space']
      }
      if (filters.orderBy) {
        const filterOBCat = Filters.source.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            filterString += '+' + filterOBOpt.value
          }
        }
      }

      let apiUrl = `${
        Config.APIM_BASE_URL
      }/sourceapi/getsearch?cql=siteSearch+~+"${unifysearchQuery(
        searchQuery,
        'source'
      )}"${defaultTypeFilterString}${filterString}&start=${sliceStartIndex}&limit=20&includeArchivedSpaces=false&excerpt=highlight&processFeaturedResults=${
        (currentPage || 1) === 1 ? true : false
      }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
        userSettings.Country
      }&function=${userSettings.Function}&city=${
        userSettings.City
      }&featuredResultsBaseQuery=${encodeURIComponent(searchQuery)}`

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      const t0 = performance.now()

      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        let searchQueryLanguage = ''
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })
        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }

        apiUrl +=
          '&cognitiveEnabled=true&searchQuery=' +
          unifysearchQuery(
            removeStopWordsFromQuery(
              searchQuery,
              searchQueryLanguage,
              useCognitiveSearch,
              findConfiguration.CognitiveSearchEnabled
            ),
            'source'
          )
      }

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let fetchedResults
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'source',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        fetchedResults = response.responseJSON
      }

      const featuredResults =
        fetchedResults && fetchedResults.FeaturedResults
          ? fetchedResults.FeaturedResults
          : []

      const synonymsApplied =
        fetchedResults && fetchedResults.SynonymsApplied
          ? fetchedResults.SynonymsApplied
          : []

      const results = {
        results:
          fetchedResults && fetchedResults.results
            ? fetchedResults.results
            : [],
        totalSize:
          fetchedResults && fetchedResults.totalSize
            ? fetchedResults.totalSize
            : 0
      }

      const t1 = performance.now()

      const sourceResults: ISourceResult[] = []

      results.results.forEach((item: any) => {
        const sourceResult: ISourceResult = {
          title: stripHtml(item.title),
          body: stripHtml(item.excerpt),
          date: item.lastModified,
          url: item.content
            ? `https://${Config.SOURCE_SERVICE_URL}${item.content._links.webui}`
            : item.url
            ? `https://${Config.SOURCE_SERVICE_URL}${item.url}`
            : '',
          timestamp: item.timestamp
        }

        sourceResults.push(sourceResult)
      })

      const executionTime = t1 - t0

      // Set result number of results
      const resultCount = results.totalSize

      dispatch(
        fetchSuccess(
          {
            queryResults: sourceResults,
            executionTime,
            resultCount: resultCount,
            currentPage: currentPage || 1
          },
          featuredResults,
          synonymsApplied,
          actionMetaData
        )
      )
      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'source',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      if (deviceSettings.renderMobile) {
        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: sourceResults
              },
              actionMetaData
            )
          )
        } else {
          dispatch(
            fetchSuccessCombined(
              {
                queryResults: [...resultsCombined, ...sourceResults]
              },
              actionMetaData
            )
          )
        }
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'source',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsSourceActions = IFetchRequest | IFetchFailure | IFetchFailure
