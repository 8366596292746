import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import { getSPOnlineToken, renewAuthorizationToken } from 'utils/token'
import { FetchWithCache } from 'utils/api'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import AuthStore from '../Auth'
import { IFindConfiguration } from 'store/Settings/reducers'
import {
  IOneIntranetPeopleQuery,
  removeStopWordsFromQuery
} from 'utils/oneIntranet'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import {
  buildCityRefinerForPeople,
  buildCountryRefinerForPeople,
  buildDepartmentRefinerForPeople,
  buildJobTitleRefinerForPeople
} from 'utils/filtersmultiselect'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { getCountryNameFromKey } from 'utils/filters'
import SettingsStore from 'store/Settings'
import { IPeopleResult } from 'components/models/PeopleResult'

export enum ResultsPeopleActionTypes {
  FETCH_REQUEST = 'resultsPeople/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsPeople/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsPeople/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsPeople/FETCH_FAILURE',
  INITIALIZE_RESULTS_PEOPLE = 'persist/REHYDRATE',
  FETCH_FILTERS = 'persist/FETCH_FILTERS',
  FETCH_FILTERS_FAILURE = 'persist/FETCH_FILTERS_FAILURE',
  FETCH_FILTERS_SUCCESS = 'persist/FETCH_FILTERS_SUCCESS'
}

interface IRefinerDefinition {
  refinerName: string
  filterName: string
  statePropertyName: string
}

export interface IFetchRequest extends CustomAction<ResultsPeopleActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsPeopleActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsPeopleActionTypes> {
  payload: {
    response: Array<IPeopleResult>
    resultCount: number
    synonymsApplied: Array<ISynonymsApplied>
    executionTime: number
    currentPage: number
    featuredResults: FeaturedResult[]
  }
}

export interface IFetchSuccessCombined
  extends CustomAction<ResultsPeopleActionTypes> {
  payload: {
    response: Array<IPeopleResult>
  }
}

export type IFetchFilters = CustomAction<ResultsPeopleActionTypes>
export const fetchFilters = (
  actionMetaData: ActionMetaData
): IFetchFilters => ({
  type: ResultsPeopleActionTypes.FETCH_FILTERS,
  metaData: actionMetaData
})
export type IFetchFiltersFailure = CustomAction<ResultsPeopleActionTypes>
export const fetchFiltersFailure = (
  actionMetaData: ActionMetaData
): IFetchFiltersFailure => ({
  type: ResultsPeopleActionTypes.FETCH_FILTERS_FAILURE,
  metaData: actionMetaData
})
export interface IFetchFiltersSuccess
  extends CustomAction<ResultsPeopleActionTypes> {
  payload: {
    refinerResultCountries: Array<any>
    refinerResultCities: Array<any>
    refinerResultDepartments: Array<any>
    refinerResultJobTitles: Array<any>
    lastFilters: {
      [key: string]: string
    }
  }
}
export const fetchFiltersSuccess = (
  refinerResultCountries: Array<any>,
  refinerResultCities: Array<any>,
  refinerResultDepartments: Array<any>,
  refinerResultJobTitles: Array<any>,
  lastFilters: {
    [key: string]: string
  },
  actionMetaData: ActionMetaData
): IFetchFiltersSuccess => ({
  type: ResultsPeopleActionTypes.FETCH_FILTERS_SUCCESS,
  payload: {
    refinerResultCountries: refinerResultCountries,
    refinerResultCities: refinerResultCities,
    refinerResultDepartments: refinerResultDepartments,
    refinerResultJobTitles: refinerResultJobTitles,
    lastFilters
  },
  metaData: actionMetaData
})

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsPeopleActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: Array<IPeopleResult> = [],
  resultCount: number,
  synonymsApplied: Array<ISynonymsApplied>,
  executionTime: number,
  currentPage: number,
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsPeopleActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    resultCount,
    synonymsApplied,
    executionTime,
    currentPage,
    featuredResults
  },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: Array<IPeopleResult> = [],
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsPeopleActionTypes.FETCH_SUCCESS_COMBINED,
  payload: {
    response
  },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsPeopleActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsPeople = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  filters: {
    [key: string]: string
  },
  findConfiguration: IFindConfiguration
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('people')

    dispatch(ResultMetaDataStore.actions.fetchRequest('people', actionMetaData))

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      if (currentPage === 1) {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      }
    }

    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      //prevent loading if cpf is enabled but not set because of async
      if (
        userSettings.EnablePrefiltering &&
        filters &&
        !filters.country &&
        !userSettings.Country
      ) {
        return
      }

      const state = getState()
      const lastAppliedFilters = state.resultsPeople.lastAppliedFilters
      const doNotUpdateFilters: IRefinerDefinition[] = []
      const peopleRefinerConfig: IRefinerDefinition[] = [
        {
          refinerName: 'RefinableString59',
          filterName: 'countriesFilter',
          statePropertyName: 'countries'
        },
        {
          refinerName: 'RefinableString60',
          filterName: 'cities',
          statePropertyName: 'cities'
        },
        {
          refinerName: 'Department',
          filterName: 'departments',
          statePropertyName: 'departments'
        },
        {
          refinerName: 'JobTitle',
          filterName: 'jobtitles',
          statePropertyName: 'jobtitles'
        }
      ]
      let dynamicRefiners: string = ''

      let firstLoadNotDoneOnFirstPage = false

      if ((currentPage || 1) === 1) {
        dispatch(fetchFilters(actionMetaData))
      } else if (!state.resultsPeople.filters.hasResultsBeenFetched) {
        firstLoadNotDoneOnFirstPage = true
      }

      const updateRefiners =
        (currentPage || 1) === 1 || firstLoadNotDoneOnFirstPage

      if (updateRefiners) {
        if (lastAppliedFilters && filters) {
          const currentFilterKeys = Object.keys(filters).filter((key: string) =>
            peopleRefinerConfig.some(
              (refiner: IRefinerDefinition) => refiner.filterName === key
            )
          )
          const lastFilterKeys = Object.keys(lastAppliedFilters).filter(
            (key: string) =>
              peopleRefinerConfig.some(
                (refiner: IRefinerDefinition) => refiner.filterName === key
              )
          )

          currentFilterKeys.forEach((key: string) => {
            const alreadyExisting = lastFilterKeys.find(
              (lastFilterKey: string) => key === lastFilterKey
            )
            if (
              (!alreadyExisting &&
                lastFilterKeys.length <= currentFilterKeys.length) ||
              (alreadyExisting && lastAppliedFilters[key] !== filters[key])
            ) {
              const refinerDefiniton = peopleRefinerConfig.find(
                (refiner: IRefinerDefinition) => refiner.filterName === key
              )
              if (refinerDefiniton) {
                doNotUpdateFilters.push(refinerDefiniton)
              }
            }
          })
        }

        const filteredRefiners = peopleRefinerConfig.filter(
          (refiner: IRefinerDefinition) => {
            return (
              !doNotUpdateFilters.some(
                (donotUpdateRefiner: IRefinerDefinition) => {
                  return donotUpdateRefiner.filterName === refiner.filterName
                }
              ) ||
              !(state.resultsPeople.filters as any)[
                refiner.statePropertyName
              ] ||
              (state.resultsPeople.filters as any)[refiner.statePropertyName]
                .length === 0
            )
          }
        )

        dynamicRefiners = filteredRefiners
          .map((refiner) => {
            return refiner.refinerName
          })
          .join(',')
      }

      const sliceStartIndex = ((currentPage || 1) - 1) * 20
      let primaryResults: IPeopleResult[] = []
      let resultCount = -1
      let executionTime = 0
      let refinerResultCities: Array<any> = []
      let refinerResultCountries: Array<any> = []
      let refinerResultDepartments: Array<any> = []
      let refinerResultJobTitles: Array<any> = []
      let synonymsApplied: ISynonymsApplied[] = []

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        return
      }

      let spoToken = ''
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        spoToken = await getSPOnlineToken(aadInfo.instance, aadInfo.accounts)
        if (spoToken === '') {
          throw new Error(
            'Authentication: Cannot renew SPO authentication token'
          )
        }
      }

      const refinementFiltersOI: Array<string> = []

      if (filters['countriesFilter']) {
        const refinerResult = buildCountryRefinerForPeople(
          filters['countriesFilter'].split('|')
        )
        refinementFiltersOI.push(refinerResult)
      }

      if (filters['cities']) {
        const refinerResult = buildCityRefinerForPeople(
          filters['cities'].split('|')
        )
        refinementFiltersOI.push(refinerResult)
      }

      if (filters['departments']) {
        const refinerResult = buildDepartmentRefinerForPeople(
          filters['departments'].split('|')
        )
        refinementFiltersOI.push(refinerResult)
      }

      if (filters['jobtitles']) {
        const refinerResult = buildJobTitleRefinerForPeople(
          filters['jobtitles'].split('|')
        )
        refinementFiltersOI.push(refinerResult)
      }

      const apiUrl = `${Config.APIM_BASE_URL}oneintranetapi/postpeople`

      const detectLanguageApiUrl = `${
        Config.APIM_BASE_URL
      }searchapi/detectlanguage?searchQuery=${encodeURIComponent(searchQuery)}`

      const useCognitiveSearch =
        SettingsStore.selectors.getUseCognitiveSearch(getState())

      let searchQueryLanguage = ''
      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }
      }

      const bodyObject: IOneIntranetPeopleQuery = {
        rowlimit: 20,
        rowsperpage: 20,
        querytext: removeStopWordsFromQuery(
          searchQuery,
          searchQueryLanguage,
          useCognitiveSearch,
          findConfiguration.CognitiveSearchEnabled
        ),
        startRow: sliceStartIndex,
        trimduplicates: false,
        dynamicRefiners: dynamicRefiners,
        genericRefiners: refinementFiltersOI,
        cognitiveEnabled: useCognitiveSearch,
        processFeaturedResults: (currentPage || 1) === 1 ? true : false,
        featuredResultsRowLimit: '3',
        featuredResultsOrigin: 'KPMGFind',
        country: userSettings.Country ? userSettings.Country : '',
        city: userSettings.City ? userSettings.City : '',
        function: userSettings.Function ? userSettings.Function : ''
      }

      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        bodyObject.cognitiveEnabled = true
      }

      const body = JSON.stringify(bodyObject)

      const t0 = performance.now()

      const response = await FetchWithCache(
        apiUrl,
        {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${spoToken}`,
            'Authorization-OnPrem': `${esToken}`
          },
          body: body
        },
        undefined,
        true,
        actionMetaData.transactionType,
        KPMGFindGlobalVariables.getCurrentTab() === 'people',
        searchQuery
      )

      let results
      if (!response || response.hasError || !response.responseJSON) {
        let hasOIError = false
        if (
          response.errorResponse &&
          (response.errorResponse.responseCode === 400 ||
            response.errorResponse.responseCode === 500) &&
          (currentPage || 1) === 1 &&
          !response.errorResponse.internalError
        ) {
          hasOIError = true
        }

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'people',
            response.errorResponse,
            undefined,
            actionMetaData,
            hasOIError
          )
        )
        dispatch(fetchFailure(actionMetaData))
        if ((currentPage || 1) === 1) {
          dispatch(fetchFiltersFailure(actionMetaData))
        }

        return
      } else {
        results = response.responseJSON
      }

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      let t1 = performance.now()
      if (response.ESTimeInQueue) {
        try {
          t1 = t1 - response.ESTimeInQueue
        } catch {}
      }
      primaryResults =
        results && results.QueryResults ? results.QueryResults : []

      primaryResults = primaryResults.map((singleResult: IPeopleResult) => {
        singleResult.pictureUrl = `${Config.GLOBAL_OI_TOKEN_ENDPOINT}${singleResult.pictureUrl}`
        return singleResult
      })

      if (results && results.refinerResult && updateRefiners) {
        const countryRefiner = results.refinerResult.find((refiner: any) => {
          return refiner.Name === 'RefinableString59'
        })
        const cityRefiner = results.refinerResult.find((refiner: any) => {
          return refiner.Name === 'RefinableString60'
        })
        const departmentRefiner = results.refinerResult.find((refiner: any) => {
          return refiner.Name === 'Department'
        })
        const jobTitleRefiner = results.refinerResult.find((refiner: any) => {
          return refiner.Name === 'JobTitle'
        })

        if (countryRefiner && countryRefiner.Options) {
          refinerResultCountries = countryRefiner.Options.map((option: any) => {
            return {
              name: getCountryNameFromKey(option.Name),
              key: option.Name,
              resultCount: option.Count
            }
          })

          if (filters['countriesFilter']) {
            filters['countriesFilter'].split('|').forEach((option: string) => {
              if (
                refinerResultCountries.findIndex(
                  (country: Filter) => country.key === option
                ) === -1
              )
                refinerResultCountries.unshift({
                  name: getCountryNameFromKey(option),
                  key: option,
                  image: '',
                  resultCount: undefined,
                  value: option
                })
            })
          }
        } else if (
          doNotUpdateFilters.some(
            (refiner: IRefinerDefinition) =>
              refiner.filterName === 'countriesFilter'
          ) &&
          state.resultsPeople.filters.countries
        ) {
          refinerResultCountries = state.resultsPeople.filters.countries
        } else if (filters['countriesFilter']) {
          filters['countriesFilter'].split('|').forEach((option: string) => {
            refinerResultCountries.unshift({
              name: getCountryNameFromKey(option),
              key: option,
              image: '',
              resultCount: undefined,
              value: option
            })
          })
        }

        if (cityRefiner && cityRefiner.Options) {
          refinerResultCities = cityRefiner.Options.map((option: any) => {
            return {
              name: option.Name,
              key: option.Name,
              resultCount: option.Count
            }
          })
        } else if (
          doNotUpdateFilters.some(
            (refiner: IRefinerDefinition) => refiner.filterName === 'cities'
          ) &&
          state.resultsPeople.filters.cities
        ) {
          refinerResultCities = state.resultsPeople.filters.cities
        }

        if (departmentRefiner && departmentRefiner.Options) {
          refinerResultDepartments = departmentRefiner.Options.map(
            (option: any) => {
              return {
                name: option.Name,
                key: option.Name,
                resultCount: option.Count
              }
            }
          )
        } else if (
          doNotUpdateFilters.some(
            (refiner: IRefinerDefinition) =>
              refiner.filterName === 'departments'
          ) &&
          state.resultsPeople.filters.departments
        ) {
          refinerResultDepartments = state.resultsPeople.filters.departments
        }

        if (jobTitleRefiner && jobTitleRefiner.Options) {
          refinerResultJobTitles = jobTitleRefiner.Options.map(
            (option: any) => {
              return {
                name: option.Name,
                key: option.Name,
                resultCount: option.Count
              }
            }
          )
        } else if (
          doNotUpdateFilters.some(
            (refiner: IRefinerDefinition) => refiner.filterName === 'jobtitles'
          ) &&
          state.resultsPeople.filters.jobtitles
        ) {
          refinerResultJobTitles = state.resultsPeople.filters.jobtitles
        }
      }

      resultCount =
        results && results.TotalRowCount ? parseInt(results.TotalRowCount) : 0

      executionTime = t1 - t0

      synonymsApplied =
        results && results.SynonymsApplied ? results.SynonymsApplied : []

      const resultsCombined = Selectors.getResultsCombined(getState())

      // filter out already fetched results
      if (
        (currentPage || 1) !== 1 &&
        resultsCombined &&
        resultsCombined.length > 0 &&
        primaryResults
      ) {
        primaryResults = primaryResults.filter(
          (newPeopleResult: IPeopleResult) => {
            return !resultsCombined.some((oldPeopleResult: IPeopleResult) => {
              return newPeopleResult.id === oldPeopleResult.id
            })
          }
        )
      }

      dispatch(
        fetchSuccess(
          primaryResults,
          resultCount,
          synonymsApplied,
          executionTime,
          currentPage || 1,
          featuredResults,
          actionMetaData
        )
      )
      if (updateRefiners) {
        dispatch(
          fetchFiltersSuccess(
            refinerResultCountries,
            refinerResultCities,
            refinerResultDepartments,
            refinerResultJobTitles,
            filters,
            actionMetaData
          )
        )
      }

      if ((currentPage || 1) === 1) {
        dispatch(fetchSuccessCombined(primaryResults, actionMetaData))
      } else {
        dispatch(
          fetchSuccessCombined(
            [...resultsCombined, ...primaryResults],
            actionMetaData
          )
        )
      }

      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'people',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'people',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
      if ((currentPage || 1) === 1) {
        dispatch(fetchFiltersFailure(actionMetaData))
      }
    }
  }
}

export const triggerPeopleDataSourceQuery = (
  searchQuery: string
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('people')

    dispatch(ResultMetaDataStore.actions.fetchRequest('people', actionMetaData))

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }

    return
  }
}

export type ResultsPeopleActions = IFetchRequest | IFetchFailure | IFetchSuccess
