import { IFindConfiguration } from 'store/Settings/reducers'
import { IErrorResponse } from './api'
import { ApiQueue, IQueueTask, IRunningTask } from './apiQueueClass'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'

interface IQueue {
  maxConcurrentCount: number
  enabled: boolean
  queue: ApiQueue
}

let requestQueue: IQueue = {
  maxConcurrentCount: 3,
  enabled: false,
  queue: new ApiQueue()
}

export const FetchWrapper = async (
  input: RequestInfo | URL,
  init?: RequestInit
): Promise<Response> => {
  const simulateAPIError = getSimulateAPIError()
  if (simulateAPIError) {
    return simulateAPIError
  }
  return fetch(input, init)
}

/**
 * Init the request queue after find config has been fetched
 * @param findConfiguration The find config
 */
export const initQueueSettings = (findConfiguration: IFindConfiguration) => {
  if (!findConfiguration || !findConfiguration.QueryConfiguration) return
  if (!requestQueue) {
    requestQueue = {
      maxConcurrentCount:
        findConfiguration.QueryConfiguration.maxConcurrentCalls,
      enabled: findConfiguration.QueryConfiguration.queueSPOCalls,
      queue: new ApiQueue()
    }
  } else if (requestQueue) {
    requestQueue.enabled = findConfiguration.QueryConfiguration.queueSPOCalls
    requestQueue.maxConcurrentCount =
      findConfiguration.QueryConfiguration.maxConcurrentCalls
  }
}

/**
 * Add fetches to queue or process them directly
 * @param url The url to request
 * @param fetchOptions The fetch options
 * @param queueFetch Flag indicating if the request must be queued
 * @returns The promise for the response
 */
export const fetchRequestAndQueueFetches = async (
  url: string,
  fetchOptions: RequestInit | undefined,
  queueFetch?: boolean | undefined,
  identifier?: string,
  isCurrentDataSourceFetch?: boolean,
  searchQuery?: string
): Promise<Response> => {
  const simulateAPIError = getSimulateAPIError()
  if (simulateAPIError) {
    return simulateAPIError
  }

  if (requestQueue && requestQueue.enabled && queueFetch) {
    // Add request to queue if enabled and is a spo query
    if (identifier) {
      await requestQueue.queue.addOperation(
        requestQueue.queue.filterOutTask,
        identifier,
        searchQuery,
        isCurrentDataSourceFetch
      )

      const result = await requestQueue.queue.addOperation(
        requestQueue.queue.getRunningTasks,
        identifier,
        searchQuery,
        isCurrentDataSourceFetch
      )

      if (result) {
        const runningFetches = result as IRunningTask[]
        runningFetches.forEach((runningFetch: IRunningTask) => {
          if (runningFetch.controller) {
            runningFetch.controller.abort()
          }
        })
      }

      return new Promise<Response>(async (resolve, reject) => {
        const task: IQueueTask = {
          url,
          fetchOptions,
          resolve,
          reject,
          identifier,
          enterQueueTime: performance.now(),
          searchQuery: searchQuery
        }

        const addAsFirst =
          isCurrentDataSourceFetch || identifier.startsWith('widget_')
        await requestQueue.queue.addOperation(
          requestQueue.queue.addTask,
          task,
          addAsFirst
        )

        processQueue()
      })
    } else {
      return fetch(url, fetchOptions)
    }
  } else {
    return fetch(url, fetchOptions)
  }
}

/**
 * Run the queued tasks
 * @returns Promise for all running tasks
 */
const processQueue = async () => {
  const result = await requestQueue.queue.addOperation(
    requestQueue.queue.takeTasks,
    requestQueue.maxConcurrentCount
  )
  const tasksToRun = result as IQueueTask[]

  if (!tasksToRun.length || tasksToRun.length < 1) {
    return
  }

  const queueTaskPromise = tasksToRun.map(async (task: IQueueTask) => {
    const controller = new AbortController()

    const runningTask: IRunningTask = {
      controller: controller,
      identifier: task.identifier,
      searchQuery: task.searchQuery
    }

    await requestQueue.queue.addOperation(
      requestQueue.queue.addRunningTask,
      runningTask
    )

    task.leaveQueueTime = performance.now()

    return fetch(task.url, { ...task.fetchOptions, signal: controller.signal })
      .then(async (resp: any) => {
        const timeInQueue = task.leaveQueueTime
          ? task.leaveQueueTime - task.enterQueueTime
          : 0

        resp.ESTimeInQueue = timeInQueue

        task.resolve(resp)
        await requestQueue.queue.addOperation(
          requestQueue.queue.removeRunningTask,
          task.identifier,
          task.searchQuery
        )

        processQueue()
        return
      })
      .catch(async (err) => {
        const isCanceled = err && err.name && err.name === 'AbortError'

        await requestQueue.queue.addOperation(
          requestQueue.queue.removeRunningTask,
          task.identifier,
          task.searchQuery
        )

        const errorResponse: IErrorResponse = {
          responseCode: isCanceled ? 499 : 500,
          message: isCanceled ? 'Fetch canceled' : 'Fetch failed',
          messageKey: 'error_unexpected_internal',
          isApimError: false,
          orginalResponseBody: '',
          internalError: true
        }

        task.resolve(
          new Response(JSON.stringify(errorResponse), {
            status: isCanceled ? 499 : 500,
            statusText: isCanceled ? 'Fetch canceled' : 'Fetch failed'
          })
        )
        processQueue()
        return
      })
  })
  return Promise.all(queueTaskPromise)
}

export const getSimulateAPIError = (): Promise<Response> | null => {
  const simulateAPIError = KPMGFindGlobalVariables.getSimulateAPIError()
  if (simulateAPIError) {
    if (simulateAPIError === 'unavailable') {
      return new Promise((resolve, reject) => {
        resolve({
          body: null,
          bodyUsed: true,
          headers: new Headers(),
          ok: false,
          redirected: false,
          status: 404,
          statusText: 'FAILED',
          type: 'cors',
          url: 'http://',
          clone: () => new Response(),
          arrayBuffer: (): Promise<ArrayBuffer> =>
            new Promise<ArrayBuffer>(() => {}),
          blob: (): Promise<Blob> => new Promise<Blob>(() => {}),
          formData: (): Promise<FormData> => new Promise<FormData>(() => {}),
          json: (): Promise<any> => new Promise<any>(() => {}),
          text: (): Promise<string> => new Promise<string>(() => {})
          // stack: ''
        } as Response)
      })
    } else if (simulateAPIError === 'AppGtwError') {
      return new Promise((resolve, reject) => {
        const headers = new Headers()
        headers.set('Content-Type', 'application/json')
        resolve({
          body: null,
          bodyUsed: true,
          headers: headers,
          ok: false,
          redirected: false,
          status: 403,
          statusText: 'FAILED',
          type: 'cors',
          url: 'http://',
          clone: () => new Response(),
          arrayBuffer: (): Promise<ArrayBuffer> =>
            new Promise<ArrayBuffer>(() => {}),
          blob: (): Promise<Blob> => new Promise<Blob>(() => {}),
          formData: (): Promise<FormData> => new Promise<FormData>(() => {}),
          json: (): Promise<any> =>
            new Promise<any>((res) => {
              res({ key: 'Microsoft-Azure-Application-Gateway' })
            }),
          text: (): Promise<string> => new Promise<string>(() => {}),
          stack: 'ClientSideAssets/772d111d-ac1b-4a99-8333-e57bc7c793c1'
        } as Response)
      })
    } else if (simulateAPIError === 'InvalidQuery') {
      return new Promise((resolve, reject) => {
        resolve({
          body: null,
          bodyUsed: true,
          headers: new Headers(),
          ok: false,
          redirected: false,
          status: 400,
          statusText: 'FAILED',
          type: 'cors',
          url: 'http://',
          clone: () => new Response(),
          arrayBuffer: (): Promise<ArrayBuffer> =>
            new Promise<ArrayBuffer>(() => {}),
          blob: (): Promise<Blob> => new Promise<Blob>(() => {}),
          formData: (): Promise<FormData> => new Promise<FormData>(() => {}),
          json: (): Promise<any> =>
            new Promise<any>((res) => {
              res({ key: 'Microsoft-Azure-Application-Gateway' })
            }),
          text: (): Promise<string> => new Promise<string>(() => {}),
          stack: ''
        } as Response)
      })
    }
  }
  return null
}
