import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { Box, Grid } from '@mui/material'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { googleMapsSearchUrl, enterpriseSearchEmail } from 'constants/constants'
import Link from '@mui/material/Link'
import {
  trackLeftWidgetClick,
  trackRightWidgetClick,
  trackWidgetShown
} from 'utils/tracking'
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js'
import { getCode } from 'country-list'
import { useIntl } from 'react-intl'
import KpmgLogo from 'images/kpmg_logo.svg'
import { getStylesOfficeLocationWidget } from 'styles/contents/resultpages/widgets/OfficeLocationWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { WidgetKey } from 'constants/widgets'

interface OfficeLocation {
  name: string
  streetAdd1: string
  streetAdd2: string
  mailAdd1: string
  mailAdd2: string
  phone: string
  fax: string
  isPrimary: boolean
  country: string
  cityName?: string
}
export interface OfficeLocationResult {
  name: string
  longitude: string
  latitude: string
  googleMapsImageUrl?: string
  offices: OfficeLocation[]
}

export interface RightLocationWidgetProps {
  searchQuery: any
  results: OfficeLocationResult
}

type AllProps = ReturnType<typeof mapStateToProps> & RightLocationWidgetProps

function OfficeLocationWidget(props: AllProps): JSX.Element {
  const { results, deviceSettings } = props

  const DOMPurify = createDOMPurify(window)

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const classes = getStylesOfficeLocationWidget(deviceSettings)

  const [itemsShown, setItemsShown] = useState(
    deviceSettings.renderMobile ? 1 : 10
  )

  const initialImage = `/externalContent/maps/${`KPMG ${DOMPurify.sanitize(
    results.name
  )} ${
    results && results.offices && results.offices[0]
      ? DOMPurify.sanitize(results.offices[0].streetAdd1)
      : ''
  }.png`
    .replace(/\s/g, '_')
    .replace(/\//g, '_')
    .replace(/"/g, '')
    .replace(/#/g, '')}`
  const [image, setImage] = useState(initialImage)
  const [isFallbackImage, showFallbackImage] = useState(false)

  const intl = useIntl()

  const showTen = () => {
    setItemsShown(10)
  }

  const showOne = () => {
    setItemsShown(1)
  }

  const trim = (text: string) => text.replace(/,/g, '').trim()

  const renderAddress = (
    country: string,
    city: string,
    officeLocation: OfficeLocation
  ) => {
    let address = ''

    if (officeLocation) {
      if (trim(officeLocation.streetAdd1)) {
        address += `${trim(DOMPurify.sanitize(officeLocation.streetAdd1))}`
      }

      if (trim(officeLocation.streetAdd2)) {
        address += `, ${trim(DOMPurify.sanitize(officeLocation.streetAdd2))}`
      }
    }

    address += Boolean(trim(city)) ? `, ${trim(DOMPurify.sanitize(city))}` : ''

    address += Boolean(trim(country))
      ? `, ${trim(DOMPurify.sanitize(country))}`
      : ''

    return address
  }

  const renderPhoneNumber = (officeLocation: OfficeLocation) => {
    const countryCode: CountryCode = getCode(
      DOMPurify.sanitize(officeLocation.country)
    ) as CountryCode

    if (countryCode) {
      return parsePhoneNumber(
        DOMPurify.sanitize(officeLocation.phone),
        countryCode
      )?.formatInternational()
    }

    return `${
      officeLocation.phone.startsWith('+') ? '' : '+'
    }${DOMPurify.sanitize(officeLocation.phone).replace(/\s/g, '')}`
  }

  const handleImageError = () => {
    setImage(KpmgLogo)
    showFallbackImage(true)
  }

  React.useEffect(() => {
    showFallbackImage(false)
    setImage(
      `/externalContent/maps/${`KPMG ${DOMPurify.sanitize(results.name)} ${
        results && results.offices && results.offices[0]
          ? DOMPurify.sanitize(results.offices[0].streetAdd1)
          : ''
      }.png`
        .replace(/\s/g, '_')
        .replace(/\//g, '_')
        .replace(/"/g, '')
        .replace(/#/g, '')}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results.name, results.offices])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: 'officelocation',
      widgetPane: deviceSettings.isMobile ? 'left' : 'right'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateIncorrectLinkUri = () => {
    const officeData: string[] = []
    results.offices.slice(0, itemsShown).forEach((office, index) => {
      //office data
      const name = office.cityName
        ? DOMPurify.sanitize(office.cityName)
        : DOMPurify.sanitize(results.name)

      const address = renderAddress(
        DOMPurify.sanitize(office.country),
        DOMPurify.sanitize(results.name),
        office
      )

      let phone: string | undefined = ''
      if (Boolean(office.phone.trim())) phone = renderPhoneNumber(office)

      //build body content
      officeData.push(
        `KPMG ${name}\n${intl.formatMessage({
          id: 'officelocationwidget_address',
          defaultMessage: 'Address'
        })}: ${address}\n${
          phone
            ? intl.formatMessage({
                id: 'officelocationwidget_phone',
                defaultMessage: 'Phone'
              }) +
              ': ' +
              phone
            : ''
        }`
      )
    })

    //generate link
    const incorrectLink = {
      address: enterpriseSearchEmail,
      subject: intl.formatMessage({
        id: 'officelocationwidget_correct_data_subject',
        defaultMessage: '[Content Feedback: Office Location]'
      }),
      body: intl
        .formatMessage({
          id: 'officelocationwidget_correct_data_body',
          defaultMessage:
            'Hi Team,\n\nI came across an inaccuracy in the Office Location data. The right office location is:\n\n{officelist}\n\n'
        })
        .replaceAll('\\n', '\n')
        .replace('{officelist}', officeData.join('\n\n'))
    }

    return `mailto:${incorrectLink.address}?subject=${encodeURI(
      incorrectLink.subject
    )}&body=${encodeURIComponent(incorrectLink.body)}`
  }

  return (
    <Box
      id={'con-widget-right-officelocation'}
      className={classes.widgetAreaContainer}
    >
      {results && results.offices && results.offices[0] && (
        <Link
          href={`${googleMapsSearchUrl}/?api=1&query=KPMG ${DOMPurify.sanitize(
            results.offices[0].name
          )} ${DOMPurify.sanitize(
            results.offices[0].country
          )} ${DOMPurify.sanitize(results.offices[0].streetAdd1)}`}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <img
            className={`${classes.mapImage} ${
              isFallbackImage ? classes.fallbackImage : ''
            }`}
            src={image}
            onError={handleImageError}
            alt="map of office location"
          />
        </Link>
      )}
      <Box className={classes.widgetArea}>
        {results.offices.slice(0, itemsShown).map((office, index) => {
          const isDifferentCountry =
            index > 0
              ? results.offices[index - 1].country !== office.country
              : false

          const name = office.cityName
            ? DOMPurify.sanitize(office.cityName)
            : DOMPurify.sanitize(results.name)
          return (
            <Box
              key={'olwb1' + index}
              className={classes.widgetText}
              style={{
                marginBottom:
                  index === results.offices.length - 1 ? 0 : undefined
              }}
            >
              {index > 0 && (
                <Box
                  key={'olwb2' + index}
                  className={classes.divider}
                  style={{
                    marginLeft: isDifferentCountry ? -20 : 0,
                    marginRight: isDifferentCountry ? -20 : 0
                  }}
                />
              )}
              <ResponsiveEllipsis
                className={classes.widgetPrimaryHeadline}
                basedOn="words"
                text={`KPMG ${name}`}
                maxLine="2"
                onClick={() =>
                  window.open(
                    `${googleMapsSearchUrl}/?api=1&query=KPMG ${
                      office ? DOMPurify.sanitize(office.name) : ''
                    } ${office ? DOMPurify.sanitize(office.country) : ''} ${
                      office ? DOMPurify.sanitize(office.streetAdd1) : ''
                    }`,
                    deviceSettings.openLinksInNewTab === true
                      ? '_blank'
                      : '_self'
                  )
                }
              />
              <table key={'olwt' + index} style={{ borderSpacing: 0 }}>
                <tr key={'olwbtr' + index} style={{ borderSpacing: 0 }}>
                  <td
                    key={'olwtd1' + index}
                    style={{
                      borderSpacing: 0,
                      verticalAlign: 'top',
                      paddingBottom: 10
                    }}
                  >
                    <b className={classes.prefix} style={{ marginRight: 8 }}>
                      {intl.formatMessage({
                        id: 'address',
                        defaultMessage: 'Address'
                      })}
                      {':'}
                    </b>
                  </td>
                  <td
                    key={'olwtd2' + index}
                    style={{
                      borderSpacing: 0,
                      verticalAlign: 'top',
                      paddingBottom: 10
                    }}
                  >
                    {office && (
                      <a
                        href={`${googleMapsSearchUrl}/?api=1&query=KPMG ${DOMPurify.sanitize(
                          office.name
                        )} ${DOMPurify.sanitize(
                          office.country
                        )} ${DOMPurify.sanitize(office.streetAdd1)}`}
                        style={{ textDecoration: 'none' }}
                        {...(deviceSettings.openLinksInNewTab === true
                          ? { target: '_blank', rel: 'noreferrer' }
                          : {})}
                      >
                        <TooltipTitle
                          title={renderAddress(
                            office.country,
                            results.name,
                            office
                          )}
                          key={'addresstitle'}
                          additionalClass={classes.address}
                        />
                      </a>
                    )}
                  </td>
                </tr>
                {Boolean(office.phone.trim()) && (
                  <tr key={'olwtr1' + index} style={{ borderSpacing: 0 }}>
                    <td key={'olwtd3' + index} style={{ verticalAlign: 'top' }}>
                      <b className={classes.prefix}>
                        {intl.formatMessage({
                          id: 'phone',
                          defaultMessage: 'Phone'
                        })}
                        {':'}
                      </b>
                    </td>
                    <td
                      key={'olwtd4' + index}
                      style={{ borderSpacing: 0, verticalAlign: 'top' }}
                    >
                      {deviceSettings.isMobile && renderPhoneNumber(office) ? (
                        <a
                          href={`tel:${renderPhoneNumber(office)}`}
                          style={{ textDecoration: 'none' }}
                          {...(deviceSettings.openLinksInNewTab === true
                            ? { target: '_blank', rel: 'noreferrer' }
                            : {})}
                        >
                          <TooltipTitle
                            title={
                              renderPhoneNumber(office)
                                ? renderPhoneNumber(office)
                                : ''
                            }
                            key={'addresstitle'}
                            additionalClass={classes.widgetFooter}
                          />
                        </a>
                      ) : (
                        <TooltipTitle
                          title={
                            renderPhoneNumber(office)
                              ? renderPhoneNumber(office)
                              : ''
                          }
                          key={'addresstitle'}
                          additionalClass={classes.widgetFooter}
                        />
                      )}
                    </td>
                  </tr>
                )}
              </table>
            </Box>
          )
        })}
        <div className={`${classes.widgetFooterLink} ${classes.footerGrid}`}>
          <Link
            data-interception={'off'}
            underline={'hover'}
            href={generateIncorrectLinkUri()}
            {...(deviceSettings.openLinksInNewTab === true
              ? { target: '_blank', rel: 'noreferrer' }
              : {})}
            onClick={() => {
              deviceSettings.isMobile
                ? trackLeftWidgetClick({
                    title:
                      results && results.offices && results.offices[0]
                        ? `KPMG ${DOMPurify.sanitize(results.offices[0].name)}`
                        : 'officeLocationWidget',
                    url: 'mailto:' + enterpriseSearchEmail,
                    widgetKey: WidgetKey.mobileOfficeLocation
                  })
                : trackRightWidgetClick({
                    title:
                      results && results.offices && results.offices[0]
                        ? `KPMG ${DOMPurify.sanitize(results.offices[0].name)}`
                        : 'officeLocationWidget',
                    url: 'mailto:' + enterpriseSearchEmail,
                    widgetKey: 'officelocation'
                  })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackRightWidgetClick({
                      title:
                        results && results.offices && results.offices[0]
                          ? `KPMG ${DOMPurify.sanitize(
                              results.offices[0].name
                            )}`
                          : 'officeLocationWidget',
                      url: 'mailto:' + enterpriseSearchEmail,
                      widgetKey: 'officelocation',
                      wasRightClicked: event?.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <TooltipTitle
              title={intl.formatMessage({
                id: 'officelocationwidget_correct_data',
                defaultMessage: 'Information incorrect? Let us know!'
              })}
              additionalClass={`${classes.widgetFooter} ${classes.link}`}
              singleLine={true}
              useCssOnly={true}
            />
          </Link>
        </div>
      </Box>
      {deviceSettings.isMobile && results.offices.length > 1 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={classes.footerGrid}
        >
          {itemsShown === 1 && (
            <Grid item className={classes.arrowContainer}>
              <KeyboardArrowDown
                onClick={showTen}
                className={classes.arrowItem}
              />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid item className={classes.arrowContainerExpanded}>
              <KeyboardArrowUp
                onClick={showOne}
                className={classes.arrowItem}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state)
  }
}

export default connect(mapStateToProps)(OfficeLocationWidget)
