import React from 'react'
import * as Config from 'config'
import { ResultLeftWidgetGenerator } from './interfaces'
import LeftSourceWidget from 'components/contents/resultpages/widgets/LeftSourceWidget'
import { stripHtml } from 'utils/string'
import { trackException } from 'utils/tracking'
import { FetchWithCache } from 'utils/api'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import { IDeviceSetting } from 'utils/deviceSettings'
import { IUserSetting } from 'utils/userSettings'
import { IFindConfiguration } from 'store/Settings/reducers'
import { ISourceResult } from 'components/models/SourceResult'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { renewAuthorizationToken } from 'utils/token'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { IAADState } from 'store/Auth/reducers'

export const leftSourceWidgetGenerator: ResultLeftWidgetGenerator = {
  fetch(
    aadInfo: IAADState,
    searchQuery: string,
    deviceSettings: IDeviceSetting,
    userSettings: IUserSetting,
    findConfiguration: IFindConfiguration,
    useCognitiveSearch: boolean
  ): Promise<{
    queryResult: ISourceResult[]
    synonymsApplied: ISynonymsApplied[]
  }> {
    return new Promise<{
      queryResult: ISourceResult[]
      synonymsApplied: ISynonymsApplied[]
    }>((resolve, reject) => {
      const fetchSource = async () => {
        if (!searchQuery || searchQuery === '' || searchQuery === '""') {
          trackException(
            'Error in fetching Source left widget in leftSourceWidget.tsx',
            new Error('Empty query. Please specify a valid query text')
          )
          reject()
          return
        }

        let apiUrl = `${
          Config.APIM_BASE_URL
        }sourceapi/getsearch?cql=siteSearch+~+"${unifysearchQuery(
          searchQuery,
          'source'
        )}"+and+type+in+(%22blogpost%22%2C%22page%22%2C%22space%22)&start=0&limit=10&includeArchivedSpaces=false&excerpt=highlight&processFeaturedResults=false`

        // Get and check authentication token
        const esToken = await renewAuthorizationToken(
          aadInfo.accessToken,
          aadInfo.instance,
          aadInfo.accounts
        )

        if (
          useCognitiveSearch &&
          findConfiguration.CognitiveSearchEnabled &&
          esToken !== ''
        ) {
          let searchQueryLanguage = ''
          const detectLanguageApiUrl = `${
            Config.APIM_BASE_URL
          }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
            searchQuery
          )}`

          const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
            method: 'POST',
            headers: {
              accept: 'application/json',
              'content-type': 'application/json',
              'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
              Authorization: `Bearer ${esToken}`
            }
          })
          if (
            languageResponse &&
            !languageResponse.hasError &&
            languageResponse.responseJSON
          ) {
            const languageJSON = languageResponse.responseJSON
            if (languageJSON && languageJSON.responseCode === 200) {
              searchQueryLanguage = languageJSON.language
            }
          }

          apiUrl +=
            '&cognitiveEnabled=true&queryText=' +
            unifysearchQuery(
              removeStopWordsFromQuery(
                searchQuery,
                searchQueryLanguage,
                useCognitiveSearch,
                findConfiguration.CognitiveSearchEnabled
              ),
              'source'
            )
        }

        const response = await FetchWithCache(apiUrl, {
          method: 'GET',
          headers: {
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            'content-type': 'application/json',
            Authorization: `Bearer ${esToken}`
          }
        })

        let fetchedResults
        if (!response || response.hasError || !response.responseJSON) {
          trackException(
            'Error in fetching Source left widget in leftSourceWidget.tsx',
            new Error(
              response && response.errorResponse
                ? response.errorResponse.responseCode +
                  ':' +
                  response.errorResponse.message
                : ''
            )
          )
          reject()
          return
        } else {
          fetchedResults = response.responseJSON
        }

        const results =
          fetchedResults && fetchedResults.results ? fetchedResults.results : []

        if (!results || results.length < 3) {
          reject()
          return
        }

        const sourceResults: ISourceResult[] = results.map((item: any) => {
          const sourceResult: ISourceResult = {
            title: item.title ? stripHtml(item.title) : '',
            body: item.excerpt ? stripHtml(item.excerpt).replace('…', '') : '',
            date: item.lastModified ? item.lastModified : '',
            url: item.content
              ? `https://${Config.SOURCE_SERVICE_URL}${item.content._links.webui}`
              : item.url
              ? `https://${Config.SOURCE_SERVICE_URL}${item.url}`
              : '',
            timestamp: item.timestamp
          }

          return sourceResult
        })

        resolve({
          queryResult: sourceResults,
          synonymsApplied: fetchedResults.SynonymsApplied
        })
      }

      try {
        fetchSource()
      } catch (error) {
        trackException(
          'Error in fetching Source left widget in leftSourceWidget.tsx',
          error
        )
        reject()
      }
    })
  },
  generate(
    resultsRaw: {
      queryResult: ISourceResult[]
      synonymsApplied: ISynonymsApplied[]
    },
    searchQuery: string
  ): Promise<React.ReactElement> {
    return new Promise<React.ReactElement>((resolve, reject) => {
      const generateSource = () => {
        const results = resultsRaw.queryResult
        const limit = 10
        if (results.length > 2) {
          let resultsList = results

          if (results.length > limit) {
            resultsList = results.slice(0, limit)
          }
          let hasContent = false
          for (let i = 0; i < results.length; i++) {
            if (results[i].title !== '') {
              hasContent = true
              break
            }
          }
          if (!hasContent) {
            reject()
            return
          }

          resolve(
            <LeftSourceWidget
              query={searchQuery}
              results={resultsList}
              synonymsApplied={resultsRaw.synonymsApplied}
            />
          )
        } else {
          reject()
          return
        }
      }

      try {
        generateSource()
      } catch (error) {
        trackException(
          'Error in generating Source left widget in leftSourceWidget.tsx',
          error
        )
        reject()
      }
    })
  }
}
