import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { Box, Grid } from '@mui/material'
import { useIntl } from 'react-intl'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { trackWidgetShown, trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SeeAllButton from './SeeAllButton'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import { getUrlParameterForCurrentFilters, useFilter } from 'utils/filters'
import FilterStore from 'store/Filters'
import RightThoughtLeadershipResult from './RightThoughtLeadershipWidgetResult'
import createDOMPurify from 'dompurify'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import LinesEllipsis from 'react-lines-ellipsis'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IThoughtLeadershipResult } from 'components/models/ThoughtLeaderShipResult'

/**
 * Formats the thought leadership items footer to be displayed in UI
 * @param dataOrigins The data origins
 * @param dates The dates
 * @return a formatted footer for all though leadership items
 */
export function formatThoughtLeadershipFooter(
  dataOrigin: string,
  date: string
): string {
  const DOMPurify = createDOMPurify(window)
  try {
    // eslint-disable-next-line array-callback-return

    let itemFooter = ''

    // dates[index] can be null
    if (date) {
      itemFooter += DOMPurify.sanitize(date)
    }

    // dataOrigins[index] can be null
    if (dataOrigin) {
      if (itemFooter !== '') {
        itemFooter += ' | '
      }

      let _dataOrigin = DOMPurify.sanitize(dataOrigin)

      // Multiple countries comma separated. Ex: Asia Pacific:China, Hong Kong & Macau:China: Take first
      if (_dataOrigin.indexOf(',') >= 0) {
        const dataMultiple = _dataOrigin.split(',')
        if (dataMultiple && dataMultiple.length > 0) {
          _dataOrigin = dataMultiple[0]
        }
      }
      // Region. Ex: Asia Pacific:China
      if (_dataOrigin.indexOf(':') >= 0) {
        const dataRegion = _dataOrigin.split(':')
        if (dataRegion && dataRegion.length > 1) {
          _dataOrigin = dataRegion[1]
        }
      }
      // Capitalize only first letter
      _dataOrigin
        .toLowerCase()
        .split(' ')
        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

      itemFooter += _dataOrigin
    }

    return itemFooter
  } catch (error) {
    trackException('Error formating Thought Leadership footer', error)
  }

  return ''
}

export interface RightThoughtLeadershipWidgetProps {
  searchQuery: any
  results: IThoughtLeadershipResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightThoughtLeadershipWidgetProps

function RightThoughtLeadershipWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    clearTemporaryFilter,
    setTemporaryFilter,
    currentfilters,
    synonymsApplied
  } = props

  const DOMPurify = createDOMPurify(window)

  const rightWidgetClasses = getStylesRightWidget()
  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const intl = useIntl()
  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: 'thoughtLeadership',
      widgetPane: 'right'
    })
  }, [])

  const headline = intl.formatMessage({
    id: 'thought_leadership_on',
    defaultMessage: 'Related Thought Leadership'
  })

  useEffect(() => {
    const newsDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === 'intranet'
    )
    const newsDatasourceEnabled = newsDatasource
      ? newsDatasource.enabled
      : false

    setDataSourceEnabled(newsDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  const seeAllLink = `/results/all/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  // If widget prefiltering is enabled, add it to the see all link
  const setTemporaryFilters = () => {
    clearTemporaryFilter()
    setTemporaryFilter({ key: 'country', value: 'GTL' })
    KPMGFindGlobalVariables.setIsRedirectFromWidget(true)
    window.scrollTo(0, 0)
  }

  return (
    <Box
      id={'con-widget-right-thoughtleadership'}
      className={
        results.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results
        .slice(0, itemsShown)
        .map((item: IThoughtLeadershipResult, index: number) => (
          <RightThoughtLeadershipResult
            title={item.title}
            documentUrl={item.documentUrl}
            abstract={item.abstract}
            image={item.image}
            footer={formatThoughtLeadershipFooter(
              item.dataOrigin,
              item.date
                ? intl.formatDate(
                    DOMPurify.sanitize(item.date),
                    dateFormatOptions
                  )
                : ''
            )}
            deviceSettings={deviceSettings}
            searchQuery={searchQuery}
            index={index}
            synonymsApplied={synonymsApplied}
          />
        ))}
      {results.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
            >
              <KeyboardArrowDown
                onClick={showTen}
                className={rightWidgetClasses.arrowItem}
              />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
            >
              <KeyboardArrowUp
                onClick={showThree}
                className={rightWidgetClasses.arrowItem}
              />
            </Grid>
          )}
          {dataSourceEnabled && results.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey="thoughtLeadership"
              onClickCallback={setTemporaryFilters}
            />
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RightThoughtLeadershipWidget)
