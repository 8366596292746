import React, { useState, useEffect, Dispatch } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { connect } from 'react-redux'
import { useIntl, FormattedMessage } from 'react-intl'
import update from 'immutability-helper'
import ConfirmationModal from 'components/contents/common/Dialog'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  Typography,
  Grid
} from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import LanguageIcon from '@mui/icons-material/Language'
import PinDropIcon from '@mui/icons-material/PinDrop'
import WidgetsIcon from '@mui/icons-material/Widgets'
import LensBlurIcon from '@mui/icons-material/LensBlur'
import PeoplePlaceholder from 'images/people_placeholder.png'
import { makeStyles } from '@mui/styles'
import Datasource from './Datasource'
import { trackEvents, trackUserSettings } from 'utils/tracking'
import { stripHtml } from 'utils/string'
import {
  checkDatasourceCriteria,
  navigateToEnabledDatasource
} from 'utils/dataSources'
import * as Config from 'config'
import {
  getCountryKeyFromName,
  isValidPrefilterCountry,
  useFilter
} from 'utils/filters'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import UserSettingsStore from 'store/UserSettings'
import { IUserSetting } from 'utils/userSettings'
import { getStylesSettings } from 'styles/contents/Settings'
import { IDataSource } from 'constants/datasourcesConfiguration'
import { supportedLanguages } from 'constants/supportedLanguages'
import { Preview } from 'react-dnd-preview'
import createDOMPurify from 'dompurify'
import FilterStore from 'store/Filters'
import { getUserAvailableDatasourcesByOrderConfig } from 'utils/dataSources'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import TabIcon from '@mui/icons-material/Tab'
import { IAADState } from 'store/Auth/reducers'

export interface SettingsProps {
  aadInfo: IAADState
  language: string
  onClose: () => void
  setLanguage: (language: string) => void
  userDisplayName: string
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  SettingsProps

function Settings(props: AllProps) {
  const {
    showSettings,
    onClose,
    language,
    setLanguage,
    userSettings,
    currentDataSources,
    setCurrentDataSources,
    setUserSettings,
    deviceSettings,
    setCurrentFilter,
    datasourceSettings,
    findConfiguration,
    aadInfo
  } = props

  const { detect } = require('detect-browser')
  const browser = detect()

  const DOMPurify = createDOMPurify(window)
  const [selectedLanguage, setSelectedLanguage] = useState(language)
  const [showResetSettings, setShowResetSettings] = useState(false)
  const initialEnablePrefilterSync = userSettings.EnablePrefiltering
  const [enablePrefiltering, setEnablePrefiltering] = useState<boolean>(
    userSettings.EnablePrefiltering
  )
  const initialWidgetEnablePrefilterSync = userSettings.EnableWidgetPrefiltering
  const [enableWidgetPrefiltering, setEnableWidgetPrefiltering] =
    useState<boolean>(userSettings.EnableWidgetPrefiltering)

  const initialCognitiveSearchEnabled = userSettings.CognitiveSearchEnabled
  const [enableCognitiveService, setEnableCognitiveService] = useState<boolean>(
    userSettings.CognitiveSearchEnabled
  )

  const intialEntityRecognitionEnabled = userSettings.EntityRecognitionEnabled
  const [enableEntityRecognition, setEnableEntityRecognition] =
    useState<boolean>(userSettings.EntityRecognitionEnabled)

  const [cancelButtonClick, setCancelButtonClick] = useState<boolean>(false)
  const [isValidPrefilter, setIsValidPrefilter] = useState<boolean>(false)
  const [pictureUrl, setPictureUrl] = useState<string>('')

  const initialDisabledWidgets = userSettings.DisabledWidgets
    ? userSettings.DisabledWidgets
    : []
  const [disabledWidgets, setDisabledWidgets] = useState<string[]>(
    userSettings.DisabledWidgets ? userSettings.DisabledWidgets : []
  )

  const [showDialogActions, setShowDialogActions] = useState<boolean>(
    browser && browser.name === 'safari' && deviceSettings.isMobile
      ? false
      : true
  )

  const initialLanguagesetting = language
  const initialSyncEnabledSetting = !userSettings.UseLocalSettings
  const [enableSyncAcrossDevices, setEnableSyncAcrossDevices] =
    useState<boolean>(!userSettings.UseLocalSettings)
  const initialOpenLinksInNewTabSetting = userSettings.OpenLinksInNewTab
  const [openLinksInNewTab, setOpenLinksInNewTab] = useState<boolean>(
    userSettings.OpenLinksInNewTab
  )

  const [localDataSourceObject, setLocalDataSourceObject] = useState<
    IDataSource[]
  >([])
  const [cpfValue] = useFilter('country', '')
  const classes = getStylesSettings()
  const intl = useIntl()
  const languages = supportedLanguages

  const userProfileStyles = makeStyles((theme) => ({
    peopleProfilepic: {
      height: 80,
      width: 80,
      backgroundImage: `url(${
        pictureUrl
          ? pictureUrl.replace('MThumb', 'LThumb').split(' ').join('%20')
          : ''
      }), url(${PeoplePlaceholder}) !important`,
      backgroundSize: '80px',
      backgroundRepeat: 'no-repeat',
      backgroundColor: '#b1b1b1',
      borderRadius: '50%'
    },
    defaultProfilepic: {
      height: 80,
      width: 80,
      backgroundImage: `url(${PeoplePlaceholder})`,
      backgroundColor: '#b1b1b1',
      backgroundSize: '80px',
      borderRadius: '50%'
    }
  }))

  const userProfileClasses = userProfileStyles()

  let buildNumber = Config.BUILD_NUMBER
  const index = buildNumber.indexOf('_')
  if (index > 0) {
    buildNumber = buildNumber.substring(index + 1)
  }

  // try to rerender dialogActions right after component rendering, uglyfix for 883783 (safari, iphone12)
  useEffect(() => {
    if (browser && browser.name === 'safari' && deviceSettings.isMobile) {
      setShowDialogActions(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (userSettings && userSettings.Country) {
      setIsValidPrefilter(isValidPrefilterCountry(userSettings.Country || ''))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.Country])

  useEffect(() => {
    const setPicURL = async () => {
      if (userSettings.PictureUrl && userSettings.PictureUrl !== '') {
        setPictureUrl(userSettings.PictureUrl)
      }
    }
    setPicURL()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettings.PictureUrl, userSettings.upn])

  useEffect(() => {
    if (showSettings) {
      // create an local deep copy of the current datasource object
      setLocalDataSourceObject(
        currentDataSources.map((source) => {
          return Object.assign({}, source)
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSettings, currentDataSources])

  const formatTrackableDatasources = (
    datasources: any[]
  ): { [key: string]: any[] } => {
    const trackableDatasources: { [key: string]: any[] } = {
      dataSources: []
    }

    let count = 1
    const dataSourceArray: any[] = []
    datasources.forEach((datasource) => {
      let index = -1
      if (datasource.enabled) {
        index = count
        count++
      }

      dataSourceArray.push({
        key: datasource.name,
        order: index
      })
    })

    trackableDatasources['dataSources'] = dataSourceArray.sort((a, b) => {
      if (a.order !== -1 && b.order !== -1) {
        return a.order - b.order
      } else {
        return a.order === -1 ? 1 : b.order === -1 ? -1 : 1
      }
    })

    return trackableDatasources
  }

  const moveDatasource = (dragIndex: number, hoverIndex: number) => {
    const dragDatasource = localDataSourceObject[dragIndex]
    const sortedDataSources = update(localDataSourceObject, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, dragDatasource]
      ]
    })
    setLocalDataSourceObject(sortedDataSources)
  }

  const toggleDataSourceItem = (enabled: boolean, index: number) => {
    if (
      !cancelButtonClick &&
      index >= 0 &&
      localDataSourceObject &&
      localDataSourceObject[index]
    ) {
      localDataSourceObject[index].enabled = enabled
      setLocalDataSourceObject(
        localDataSourceObject.map((item, innerIndex) => {
          if (innerIndex === index) {
            item.enabled = enabled
          }
          return item
        })
      )
    }
  }

  const setWidgetEnabled = (
    enabled: boolean,
    key: string | string[] | undefined
  ) => {
    if (key === undefined) return

    let changed = false
    let newDisabledWidgets = [...disabledWidgets]

    if (key && !enabled && areWidgetsEnabled(key, newDisabledWidgets)) {
      if (!Array.isArray(key)) {
        newDisabledWidgets.push(key)
      } else {
        key.forEach((item: string) => {
          newDisabledWidgets.push(item)
        })
      }
      changed = true
    }

    if (key && enabled && !areWidgetsEnabled(key, newDisabledWidgets)) {
      if (!Array.isArray(key)) {
        newDisabledWidgets = newDisabledWidgets.filter(
          (value: string) => value !== key
        )
      } else {
        newDisabledWidgets = newDisabledWidgets.filter(
          (value: string) => !key.includes(value)
        )
      }
      changed = true
    }

    if (changed) {
      setDisabledWidgets(newDisabledWidgets)
    }
  }

  const updateLanguage = (selectedLanguage: string) => {
    const language = languages.find((lang) => lang.locale === selectedLanguage)
    const previousLanguage = languages.find(
      (lang) => lang.locale === initialLanguagesetting
    )

    if (language !== previousLanguage) {
      trackEvents('language-changed', {
        languageLocale: selectedLanguage,
        languageName: language ? language.name : '',
        previousLanguageLocale: initialLanguagesetting,
        previousLanguage: previousLanguage ? previousLanguage.name : '',
        tab: KPMGFindGlobalVariables.getCurrentTab(),
        searchTerm: KPMGFindGlobalVariables.getSearchTerm()
      })
    }

    userSettings.LanguageManuallySeleted = true
    setLanguage(selectedLanguage)
  }

  const onCancelButtonClick = () => {
    onClose()
    setCancelButtonClick(true)

    userSettings.Language = initialLanguagesetting
    setSelectedLanguage(initialLanguagesetting)

    userSettings.UseLocalSettings = !initialSyncEnabledSetting
    setEnableSyncAcrossDevices(initialSyncEnabledSetting)

    userSettings.OpenLinksInNewTab = initialOpenLinksInNewTabSetting
    setOpenLinksInNewTab(initialOpenLinksInNewTabSetting)

    userSettings.EnablePrefiltering = initialEnablePrefilterSync
    setEnablePrefiltering(initialEnablePrefilterSync)

    userSettings.EnableWidgetPrefiltering = initialWidgetEnablePrefilterSync
    setEnableWidgetPrefiltering(initialWidgetEnablePrefilterSync)

    userSettings.CognitiveSearchEnabled = initialCognitiveSearchEnabled
    setEnableCognitiveService(initialCognitiveSearchEnabled)

    userSettings.EntityRecognitionEnabled = intialEntityRecognitionEnabled
    setEnableEntityRecognition(intialEntityRecognitionEnabled)

    userSettings.DisabledWidgets = initialDisabledWidgets
    setDisabledWidgets(initialDisabledWidgets)

    trackUserSettings(userSettings, false)
    setCancelButtonClick(false)
  }

  const onSaveButtonClick = () => {
    onClose()

    userSettings.Language = selectedLanguage
    userSettings.DataSourceOrder = JSON.stringify(
      localDataSourceObject.map((ds: any) => {
        return ds.name
      })
    )
    userSettings.DataSourceDisabled = JSON.stringify(
      localDataSourceObject.map((ds: any) => {
        return ds.enabled === false ? ds.name : ''
      })
    )

    updateLanguage(selectedLanguage)

    userSettings.UseLocalSettings = !enableSyncAcrossDevices
    userSettings.EnablePrefiltering = enablePrefiltering
    userSettings.OpenLinksInNewTab = openLinksInNewTab

    // In case the prefiltering value has changed
    // Update the url query param
    // --- set country filter
    // --- remove country filter
    if (initialEnablePrefilterSync !== enablePrefiltering) {
      const countryKey = getCountryKeyFromName(userSettings.Country)
      if (!enablePrefiltering) {
        if (cpfValue && cpfValue === countryKey) {
          // Update url query param
          setCurrentFilter([
            { key: 'page', value: '1' },
            { key: 'country', value: '' }
          ])
        }
      } else {
        if (countryKey && !cpfValue) {
          setCurrentFilter([
            { key: 'page', value: '1' },
            { key: 'country', value: countryKey }
          ])
        }
      }
    }

    userSettings.EnableWidgetPrefiltering = enableWidgetPrefiltering
    userSettings.DisabledWidgets = disabledWidgets
    userSettings.CognitiveSearchEnabled = enableCognitiveService
    userSettings.EntityRecognitionEnabled = enableEntityRecognition
    if (userSettings.CognitiveSearchEnabled)
      userSettings.ShowCognitiveMessage = true

    // update settings
    setUserSettings(userSettings)

    // Update the current datasources for the settings menu
    setCurrentDataSources(localDataSourceObject)

    trackEvents(
      'dataSourcesSettings',
      formatTrackableDatasources(localDataSourceObject)
    )
    let currentLocation =
      window && window.location && window.location.search
        ? window.location.search
        : window && window.location.hash
        ? window.location.hash
        : ''
    currentLocation = DOMPurify.sanitize(currentLocation)
    currentLocation = stripHtml(currentLocation)
    navigateToEnabledDatasource(currentLocation, localDataSourceObject)
  }

  // Construct user name
  let userName = userSettings.DisplayName
  if (
    userSettings.FirstName &&
    userSettings.FirstName !== '' &&
    userSettings.LastName &&
    userSettings.LastName !== ''
  ) {
    userName = userSettings.FirstName + ' ' + userSettings.LastName
  } else if (userSettings.DisplayName.indexOf(',') >= 0) {
    const userNameParts = userSettings.DisplayName.split(',')
    if (userNameParts.length === 2) {
      userName = userNameParts[1].split(' (')[0] + ' ' + userNameParts[0]
    }
  }

  //https://react-dnd.github.io/react-dnd/docs/backends/touch
  //Useful for improving performance in environments (iOS Safari) where document.elementsFromPoint is not available
  const hasNative = document && document.elementsFromPoint
  function getDropTargetElementsAtPoint(x: any, y: any, dropTargets: any) {
    return dropTargets.filter((t: any) => {
      const rect = t.getBoundingClientRect()
      return (
        x >= rect.left && x <= rect.right && y <= rect.bottom && y >= rect.top
      )
    })
  }

  // use custom function only if elementsFromPoint is not supported
  const backendOptions = {
    getDropTargetElementsAtPoint: !hasNative && getDropTargetElementsAtPoint
  }

  /**
   * Get number of enabled datasources
   * @returns num enabled datasources
   */
  const getNumEnabledDatasources = (): number => {
    const enabledDatasources = localDataSourceObject.filter(
      (datasource: IDataSource) => datasource.enabled
    )
    return enabledDatasources.length
  }

  const resetSettings = () => {
    setSelectedLanguage('en')
    setEnableSyncAcrossDevices(true)
    setEnablePrefiltering(true)
    setEnableWidgetPrefiltering(true)
    setEnableCognitiveService(findConfiguration.CognitiveSearchEnabled)
    setEnableEntityRecognition(findConfiguration.EntityRecognitionEnabled)
    setDisabledWidgets([])
    setOpenLinksInNewTab(false)
    const datasources = getUserAvailableDatasourcesByOrderConfig(userSettings)

    // Filter datasources by criteria config
    checkDatasourceCriteria(datasources, deviceSettings, datasourceSettings)

    setLocalDataSourceObject(datasources)
  }

  const areWidgetsEnabled = (
    mappedWidget: string | string[] | undefined,
    disabledWidgetsList: string[]
  ): boolean => {
    if (!mappedWidget) return false

    if (!Array.isArray(mappedWidget)) {
      return !disabledWidgetsList.includes(mappedWidget)
    } else {
      let found = false
      disabledWidgetsList.forEach((item) => {
        mappedWidget.forEach((_mappedWidget) => {
          if (_mappedWidget === item) found = true
        })
      })

      return !found
    }
  }

  //For compatibility reasons, we use TouchBackend instead of HTML5Backend on mobile devices.
  //TouchBackend doesn't offer drag effects (such as sticky moving objects),
  //so we're doing this through the react-dnd-preview for mobile devices.
  const generatePreview = ({
    itemType,
    item,
    style
  }: {
    itemType: any
    item: any
    style: any
  }) => {
    const entry: IDataSource = localDataSourceObject[item.index]
    return (
      <div style={{ ...style, width: 565, opacity: 0.5 }}>
        <Datasource
          id={entry.name}
          key={entry.name}
          index={index}
          icon={entry.icon}
          description={entry.description}
          name={entry.name}
          enabled={entry.enabled}
          move={moveDatasource}
          setEnabled={toggleDataSourceItem}
          isLast={index === localDataSourceObject.length - 1}
          healthStatus={entry.healthStatus}
          deviceSettings={deviceSettings}
          notDisableable={entry.enabled && getNumEnabledDatasources() === 1}
          widgetEnabled={
            entry.mappedWidget
              ? areWidgetsEnabled(entry.mappedWidget, disabledWidgets)
              : false
          }
          widgetKey={entry.mappedWidget}
          setWidgetEnabled={setWidgetEnabled}
        />
      </div>
    )
  }
  return (
    <Dialog
      id={'con-settings-01'}
      open={showSettings}
      onClose={onClose}
      className={classes.dialogContainer}
    >
      <DialogContent className={classes.dialogContent}>
        <Box id={'con-settings-02'} className={classes.background}>
          {userSettings && (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
              className={classes.userInfoContainer}
            >
              <div className={classes.personIconContainer}>
                <div className={classes.personIcon}>
                  <div
                    className={
                      userProfileClasses.peopleProfilepic +
                      ' ' +
                      userProfileClasses.defaultProfilepic
                    }
                  />
                </div>
              </div>
              <Typography
                align="center"
                variant="h6"
                className={classes.userInfoTitle}
              >
                <FormattedMessage id="hello" defaultMessage="Hello" />,{' '}
                {userName}
              </Typography>
              <Typography
                className={classes.userInfoDescription}
                align="center"
                variant="body1"
              >
                <FormattedMessage
                  id="user_settings_description"
                  defaultMessage="Manage your preferred language, data sources and see their availability to make KPMG Find work better for you."
                />
              </Typography>
            </Box>
          )}
          <Typography variant="h6" className={classes.sectionTitle}>
            <FormattedMessage id="general" defaultMessage="General" />
          </Typography>
          <Paper>
            <List
              component="nav"
              className={classes.list}
              disablePadding={true}
            >
              <ListItem key="language" className={classes.listItem}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} sm={1} className={classes.iconContainer}>
                    <ListItemIcon className={classes.iconItem}>
                      <LanguageIcon />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={5} sm={6}>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: 'language',
                        defaultMessage: 'Language'
                      })}
                    />
                  </Grid>
                  <Grid item xs={5} sm={5}>
                    <Select
                      key="languageSelect"
                      variant={'standard'}
                      disableUnderline
                      className={classes.select}
                      value={selectedLanguage}
                      onChange={(event) => {
                        const updatedLanguage: string = event.target
                          .value as string
                        userSettings.Language = updatedLanguage
                        setSelectedLanguage(updatedLanguage)
                      }}
                    >
                      {languages &&
                        languages.map((entry: any, index: number) => (
                          <MenuItem key={index} value={entry.locale}>
                            {intl.formatMessage({
                              id: entry.translationId,
                              defaultMessage: entry.name
                            })}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem key="syncAcrossDevices" className={classes.listItem}>
                <Grid container direction="row" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    className={classes.iconContainer}
                    style={{ alignSelf: 'center' }}
                  >
                    <ListItemIcon className={classes.iconItem}>
                      <SyncIcon />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: 'sync_across_devices',
                        defaultMessage:
                          'Synchronize user settings across devices'
                      })}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.gridSwitchContainer}>
                    <Switch
                      disabled={false}
                      color="primary"
                      checked={enableSyncAcrossDevices}
                      onChange={(event) => {
                        setEnableSyncAcrossDevices(event.target.checked)
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem key="openLinksInNewTab" className={classes.listItem}>
                <Grid container direction="row" alignItems="center">
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    className={classes.iconContainer}
                    style={{ alignSelf: 'center' }}
                  >
                    <ListItemIcon className={classes.iconItem}>
                      <TabIcon />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    <ListItemText
                      primary={intl.formatMessage({
                        id: 'open_link_innewtab',
                        defaultMessage: 'Open links in a new browser tab'
                      })}
                    />
                  </Grid>
                  <Grid item xs={2} className={classes.gridSwitchContainer}>
                    <Switch
                      disabled={false}
                      color="primary"
                      checked={openLinksInNewTab}
                      onChange={(event) => {
                        setOpenLinksInNewTab(event.target.checked)
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem key="prefiltering" className={classes.listItem}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} sm={1} className={classes.iconContainer}>
                    <ListItemIcon className={classes.iconItem}>
                      <PinDropIcon />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    {isValidPrefilter && (
                      <>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'enable_prefiltering',
                            defaultMessage:
                              'Prefilter results based on your country'
                          })}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'enable_prefiltering_message',
                            defaultMessage:
                              'Use this setting to pre-filter general search results based on your country. You can always deactivate this filter directly in the search.'
                          })}
                          className={classes.infoMessage}
                        />
                      </>
                    )}
                    {!isValidPrefilter && (
                      <ListItemText
                        primary={intl.formatMessage({
                          id: 'prefiltering_unavailable',
                          defaultMessage:
                            'Prefiltering is currently not available for your country'
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.gridSwitchContainer}>
                    <Switch
                      disabled={!isValidPrefilter}
                      color="primary"
                      checked={isValidPrefilter && enablePrefiltering}
                      onChange={(event) => {
                        setEnablePrefiltering(event.target.checked)
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              <Divider />
              <ListItem key="widget-prefiltering" className={classes.listItem}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={2} sm={1} className={classes.iconContainer}>
                    <ListItemIcon className={classes.iconItem}>
                      <WidgetsIcon />
                    </ListItemIcon>
                  </Grid>
                  <Grid item xs={8} sm={9}>
                    {isValidPrefilter ? (
                      <>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'enable_widget_prefiltering',
                            defaultMessage:
                              'Prefilter widget results based on your country'
                          })}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'enable_widget_prefiltering_message',
                            defaultMessage:
                              'Use this setting to pre-filter general search results in widgets based on your country (eg. local news or local engagement credentials).'
                          })}
                          className={classes.infoMessage}
                        />
                      </>
                    ) : (
                      <ListItemText
                        primary={intl.formatMessage({
                          id: 'prefiltering_widget_unavailable',
                          defaultMessage:
                            'Widget prefiltering is currently not available for your country'
                        })}
                      />
                    )}
                  </Grid>
                  <Grid item xs={2} className={classes.gridSwitchContainer}>
                    <Switch
                      disabled={!isValidPrefilter}
                      color="primary"
                      checked={isValidPrefilter && enableWidgetPrefiltering}
                      onChange={(event) => {
                        setEnableWidgetPrefiltering(event.target.checked)
                      }}
                    />
                  </Grid>
                </Grid>
              </ListItem>
              {findConfiguration.CognitiveSearchEnabled && (
                <>
                  <Divider />
                  <ListItem
                    key="cognitive-service"
                    className={classes.listItem}
                  >
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        className={classes.iconContainer}
                      >
                        <ListItemIcon className={classes.iconItem}>
                          <LensBlurIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'settings_cognitiveservice_enable',
                            defaultMessage: 'Enable AI improved querying'
                          })}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'settings_cognitiveservice_description',
                            defaultMessage:
                              'Use this setting to use AI improved querying for better results'
                          })}
                          className={classes.infoMessage}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.gridSwitchContainer}>
                        <Switch
                          disabled={!findConfiguration.CognitiveSearchEnabled}
                          color="primary"
                          checked={
                            findConfiguration.CognitiveSearchEnabled &&
                            enableCognitiveService
                          }
                          onChange={(event) => {
                            setEnableCognitiveService(event.target.checked)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
              {findConfiguration.EntityRecognitionEnabled && (
                <>
                  <Divider />
                  <ListItem
                    key="cognitive-service-entity-recognition"
                    className={classes.listItem}
                  >
                    <Grid container direction="row" alignItems="center">
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        className={classes.iconContainer}
                      >
                        <ListItemIcon className={classes.iconItem}>
                          <LensBlurIcon />
                        </ListItemIcon>
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'settings_cognitiveservice_entityrecognition_enable',
                            defaultMessage: 'Enable AI improved widgets'
                          })}
                        />
                        <ListItemText
                          primary={intl.formatMessage({
                            id: 'settings_cognitiveservice_entityrecognition_description',
                            defaultMessage:
                              'Use this setting to use AI improved widget selection'
                          })}
                          className={classes.infoMessage}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.gridSwitchContainer}>
                        <Switch
                          disabled={!findConfiguration.EntityRecognitionEnabled}
                          color="primary"
                          checked={
                            findConfiguration.EntityRecognitionEnabled &&
                            enableEntityRecognition
                          }
                          onChange={(event) => {
                            setEnableEntityRecognition(event.target.checked)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </>
              )}
            </List>
          </Paper>
          <Box className={classes.spacer} />
          <Typography variant="h6" className={classes.sectionTitle}>
            <FormattedMessage id="data_sources" defaultMessage="Data Sources" />
          </Typography>
          <Typography variant="body2" className={classes.sectionDescription}>
            (
            <FormattedMessage
              id="data_sources_description"
              defaultMessage="drag and drop sources to adjust tab order"
            />
            )
          </Typography>
          <Paper>
            <DndProvider
              backend={deviceSettings.isMobile ? TouchBackend : HTML5Backend}
              options={backendOptions}
            >
              <List
                component="nav"
                className={classes.dragAndDropList}
                disablePadding={true}
              >
                {localDataSourceObject &&
                  localDataSourceObject.length > 0 &&
                  localDataSourceObject.map(
                    (entry: IDataSource, index: number) => (
                      <Datasource
                        id={entry.name}
                        key={entry.name}
                        index={index}
                        icon={entry.icon}
                        description={entry.description}
                        name={entry.name}
                        enabled={entry.enabled}
                        move={moveDatasource}
                        setEnabled={toggleDataSourceItem}
                        isLast={index === localDataSourceObject.length - 1}
                        healthStatus={entry.healthStatus}
                        deviceSettings={deviceSettings}
                        notDisableable={
                          entry.enabled && getNumEnabledDatasources() === 1
                        }
                        widgetEnabled={
                          entry.mappedWidget
                            ? areWidgetsEnabled(
                                entry.mappedWidget,
                                disabledWidgets
                              )
                            : false
                        }
                        widgetKey={entry.mappedWidget}
                        setWidgetEnabled={setWidgetEnabled}
                      />
                    )
                  )}
                {deviceSettings.isMobile && (
                  <Preview generator={generatePreview} />
                )}
              </List>
            </DndProvider>
          </Paper>
          <Box className={classes.spacerLogout} />
          <div className={classes.logoutContainer}>
            <Button
              id={'btn-settings-resetsettings-01'}
              disableRipple={true}
              className={classes.resetSettingsBtn}
              variant="outlined"
              onClick={() => {
                setShowResetSettings(true)
              }}
            >
              <Typography>
                <FormattedMessage
                  id="reset_settings_button"
                  defaultMessage="Reset"
                />
              </Typography>
            </Button>
            <Button
              id={'btn-settings-logout-01'}
              disableRipple={true}
              className={classes.logoutBtn}
              variant="outlined"
              onClick={() => {
                onClose()
                if (aadInfo && aadInfo.instance) {
                  aadInfo.instance.logoutRedirect()
                }
              }}
            >
              <Typography>
                <FormattedMessage id="logout" defaultMessage="Logout" />
              </Typography>
            </Button>
          </div>
        </Box>
      </DialogContent>
      <ConfirmationModal
        handleConfirm={() => {
          setShowResetSettings(false)
          resetSettings()
        }}
        handleClose={() => {
          setShowResetSettings(false)
        }}
        isModalOpen={showResetSettings}
        title={intl.formatMessage({
          id: 'reset_settings_title',
          defaultMessage: 'Reset Settings'
        })}
        message={intl.formatMessage({
          id: 'reset_settings_message',
          defaultMessage:
            'Are you sure you want to reset your settings to default values?'
        })}
        hideCancelButton={false}
        confirmBtnText={intl.formatMessage({
          id: 'yes',
          defaultMessage: 'Yes'
        })}
        cancelBtnText={intl.formatMessage({
          id: 'no',
          defaultMessage: 'No'
        })}
      />
      {showDialogActions && (
        <DialogActions className={classes.dialogActions}>
          <div className={classes.divTable}>
            <div className={classes.divTableRow}>
              <div className={classes.divTableBuildCell}>
                <Box className={classes.descriptionBuild}>
                  {intl.formatMessage({
                    id: 'build_info',
                    defaultMessage: 'BUILD: '
                  })}
                  {buildNumber}
                </Box>
              </div>
              <div className={classes.divTableButtonsCell}>
                <Box>
                  <Button
                    id={'btn-settings-cancel-01'}
                    onClick={() => {
                      onCancelButtonClick()
                    }}
                    color="primary"
                    className={classes.cancelButton}
                  >
                    <FormattedMessage id="cancel" defaultMessage="Cancel" />
                  </Button>
                  <Button
                    id={'btn-settings-save-01'}
                    onClick={() => {
                      onSaveButtonClick()
                    }}
                    color="primary"
                    variant="contained"
                  >
                    <FormattedMessage id="save" defaultMessage="Save" />
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    showSettings: SettingsStore.selectors.getShowSettings(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    datasourceSettings: SettingsStore.selectors.getDatasourceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    setCurrentDataSources: (currentDataSources: any[]) =>
      dispatch(SettingsStore.actions.setCurrentDataSources(currentDataSources)),
    setCurrentFilter: (currentFilter: CurrentFilter[]) =>
      dispatch(FilterStore.actions.setCurrentFilter(currentFilter)),
    setUserSettings: (userSettings: IUserSetting, settingsChanged = true) =>
      dispatch(
        UserSettingsStore.actions.upSertUserSettings(
          userSettings,
          settingsChanged
        )
      )
  }
}

const mergeProps = (
  propsFromState: any,
  propsFromDispatch: any,
  ownProps: any
) => {
  return {
    ...ownProps,
    ...propsFromState,
    ...propsFromDispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(Settings)
