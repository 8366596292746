import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink } from 'react-router-dom'
import Slider from 'react-slick'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import { trackWidgetShown, trackException, trackEvents } from 'utils/tracking'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import { IVivaEngageResult } from 'components/models/VivaEngageResult'
import LeftVivaEngageWidgetResult from './LeftVivaEngageWidgetResult'
import AuthStore from 'store/Auth'

export interface LeftVivaEngageWidgetProps {
  results: IVivaEngageResult[]
  query: string
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftVivaEngageWidgetProps

function LeftVivaEngageWidget(props: AllProps): JSX.Element {
  const { query, results, deviceSettings, aadInfo, findConfiguration } = props

  const DOMPurify = createDOMPurify(window)
  const leftWidgetClasses = getStylesLeftWidget()

  const [slideIndex, setSlideIndex] = useState(0)
  const intl = useIntl()

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.vivaengage,
      widgetPane: 'left'
    })
  }, [])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    nextArrow:
      slideIndex + 3 >= results.length || deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      slideIndex === 0 || deviceSettings.renderMobile ? <></> : <LeftArrow />,
    afterChange: (newIndex: any) => setSlideIndex(newIndex)
  }

  let slides: JSX.Element[] = []
  try {
    slides = results.map((item: IVivaEngageResult, i: number) => (
      <LeftVivaEngageWidgetResult
        item={item}
        deviceSettings={deviceSettings}
        index={i}
        aadInfo={aadInfo}
      />
    ))
  } catch (error) {
    trackException('Error in parsing slides in LeftVivaEngageWidget.tsx', error)
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink =
    findConfiguration &&
    findConfiguration.SeeAllLinks &&
    findConfiguration.SeeAllLinks[WidgetKey.vivaengage]
      ? findConfiguration.SeeAllLinks[WidgetKey.vivaengage]
      : `https://web.yammer.com/main/feed`

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.vivaengage,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.vivaengage,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box
      id={'con-widget-left-vivaengage'}
      className={leftWidgetClasses.container}
    >
      {results && results.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.vivaengage,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.vivaengage,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({
                id: 'vivaengage_on',
                defaultMessage: 'Viva Engage'
              })}
            </Typography>
          </RouterLink>
          <Box className={leftWidgetClasses.cardContainer}>
            {!deviceSettings.renderMobile && slides.length > 0 && (
              <Slider className={leftWidgetClasses.slider} {...settings}>
                {slides}
              </Slider>
            )}
            {deviceSettings.renderMobile && slides.length > 0 && (
              <Box className={leftWidgetClasses.mobileScrollContainer}>
                {slides}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    aadInfo: AuthStore.selectors.getAADInfo(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state)
  }
}

export default connect(mapStateToProps)(LeftVivaEngageWidget)
