import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import * as Config from 'config'
import AuthStore from '../Auth'
import { renewAuthorizationToken } from 'utils/token'
import { stripHtml } from 'utils/string'
import { FetchWithCache } from 'utils/api'
import { kpmgwebsites } from 'constants/filters'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import { unifysearchQuery } from 'utils/unifysearchQuery'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IKpmgWebsitesResult } from 'components/models/KpmgWebsiteResult'

export enum ResultsAEMActionTypes {
  FETCH_REQUEST = 'resultsAEM/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsAEM/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsAEM/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsAEM/FETCH_FAILURE',
  INITIALIZE_RESULTS_AEM = 'persist/REHYDRATE'
}

export interface IFetchRequest extends CustomAction<ResultsAEMActionTypes> {
  payload: {
    resetCombined: boolean
    searchQuery: string
  }
}
export type IFetchFailure = CustomAction<ResultsAEMActionTypes>
export interface IFetchSuccess extends CustomAction<ResultsAEMActionTypes> {
  payload: {
    response: {
      queryResults: IKpmgWebsitesResult[]
      resultCount: number
      executionTime: number
      currentPage: number
    }
    featuredResults: FeaturedResult[]
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsAEMActionTypes> {
  payload: {
    response: {
      queryResults: IKpmgWebsitesResult[]
    }
  }
}

export const fetchRequest = (
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsAEMActionTypes.FETCH_REQUEST,
  payload: {
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: {
    queryResults: IKpmgWebsitesResult[]
    resultCount: number
    executionTime: number
    currentPage: number
  },
  featuredResults: FeaturedResult[],
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsAEMActionTypes.FETCH_SUCCESS,
  payload: {
    response,
    featuredResults
  },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: { queryResults: IKpmgWebsitesResult[] },
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsAEMActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  metaData: actionMetaData
})
export const fetchFailure = (
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsAEMActionTypes.FETCH_FAILURE,
  metaData: actionMetaData
})

export const fetchResultsAEM = (
  searchQuery: string,
  currentPage: number,
  deviceSettings: IDeviceSetting,
  userSettings: IUserSetting,
  filters: {
    [key: string]: string
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
): ThunkAction<Promise<void>, Store, {}, AnyAction> => {
  return async (
    // eslint-disable-next-line @typescript-eslint/ban-types
    dispatch: ThunkDispatch<{}, {}, AnyAction>,
    getState: () => Store
  ) => {
    const actionMetaData = getActionMetaData('kpmgcom')

    dispatch(
      ResultMetaDataStore.actions.fetchRequest('kpmg.com', actionMetaData)
    )

    const resultsCombinedQuery = Selectors.getResultsCombinedQuery(getState())
    if (resultsCombinedQuery !== searchQuery) {
      if (resultsCombinedQuery === '') {
        dispatch(fetchRequest(false, searchQuery, actionMetaData))
      } else {
        dispatch(fetchRequest(true, searchQuery, actionMetaData))
      }
    } else {
      dispatch(fetchRequest(false, searchQuery, actionMetaData))
    }
    try {
      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(actionMetaData))
        return
      }

      // Get and check authentication token
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )
      if (esToken !== aadInfo.accessToken) {
        AuthStore.actions.setAuthToken(esToken)
      }
      if (esToken === '') {
        throw new Error('Authentication: Cannot renew authentication token')
      }

      // Prevent loading if cpf is enabled but not set because of async
      if (
        userSettings.EnablePrefiltering &&
        filters &&
        !filters.country &&
        !userSettings.Country
      ) {
        return
      }

      const t0 = performance.now()

      // Build and get aem results
      let apiUrl = `${
        Config.APIM_BASE_URL
      }aemapi/getsearch?keyword=${unifysearchQuery(
        searchQuery,
        'kpmg.com'
      )}&processFeaturedResults=${
        (currentPage || 1) === 1 ? true : false
      }&featuredResultsRowLimit=3&origin=KPMGFind&country=${
        userSettings.Country
      }&function=${userSettings.Function}`

      if (filters.locale) {
        const filterLocale = kpmgwebsites.find(
          (fl: FilterCategory) => fl.key === 'locale'
        )
        if (filterLocale) {
          const filterLocaleOpt = filterLocale.options.find(
            (opt: Filter) => opt.key === filters.locale
          )
          if (filterLocaleOpt && filterLocaleOpt.value) {
            apiUrl += `&locale=${filterLocaleOpt.value}`
          }
        }
      } else {
        apiUrl += '&locale=xx/en'
      }

      if (filters.orderBy) {
        const filterOBCat = kpmgwebsites.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            apiUrl += `&sort=${filterOBOpt.value}`
          }
        }
      } else {
        apiUrl += '&sort=Relevance'
      }

      if (filters.type) {
        const filterTypeCat = kpmgwebsites.find(
          (fl: FilterCategory) => fl.key === 'type'
        )
        if (filterTypeCat) {
          const filterTypeOpt = filterTypeCat.options.find(
            (opt: Filter) => opt.key === filters.type
          )
          if (filterTypeOpt && filterTypeOpt.value) {
            apiUrl += `&contentType=${filterTypeOpt.value}`
          }
        }
      }

      // KPMG Websites / AEM does not implement paging, only a limit parameter
      // Ex: page 2 of KPMG find should retrieve pages 3 and 4 of AEM
      const limit = 20 * (currentPage ? currentPage : 1)

      apiUrl += `&limit=${limit}`

      const response = await FetchWithCache(apiUrl, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${esToken}`
        }
      })

      let results
      if (!response || response.hasError || !response.responseJSON) {
        dispatch(
          ResultMetaDataStore.actions.hasError(
            'kpmg.com',
            response.errorResponse,
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(actionMetaData))
        return
      } else {
        results = response.responseJSON
      }

      const t1 = performance.now()

      // Result count only needs first batch, as its present in the total attribute
      const resultCount =
        results &&
        results.meta &&
        results.meta.page &&
        results.meta.page.total_results
          ? parseInt(results.meta.page.total_results)
          : 0

      // Get the current page results: slice(start,end)
      const queryResults: IKpmgWebsitesResult[] = []
      const primaryResults =
        results && results.results
          ? results.results.slice(20 * (currentPage - 1), 20 * currentPage)
          : []

      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []

      primaryResults.forEach((item: any) => {
        let date = ''
        if (
          item &&
          item.kpmg_article_date_time &&
          item.kpmg_article_date_time.raw &&
          item.kpmg_article_date_time.raw !== ''
        ) {
          date = item.kpmg_article_date_time.raw
        } else if (
          item &&
          item.kpmg_event_start_time &&
          item.kpmg_event_start_time.raw &&
          item.kpmg_event_start_time.raw !== ''
        ) {
          date = item.kpmg_event_start_time.raw
        } else if (
          item &&
          item.kpmg_blog_date_time &&
          item.kpmg_blog_date_time.raw &&
          item.kpmg_blog_date_time.raw !== ''
        ) {
          date = item.kpmg_blog_date_time.raw
        } else {
          date = ''
        }

        const singleResult: IKpmgWebsitesResult = {
          title:
            item && item.kpmg_title && item.kpmg_title.raw
              ? item.kpmg_title.raw
              : '',
          body:
            item && item.kpmg_description && item.kpmg_description.raw
              ? stripHtml(item.kpmg_description.raw)
              : '',

          date: date,
          url:
            item && item.kpmg_url && item.kpmg_url.raw ? item.kpmg_url.raw : '',
          image:
            item &&
            item.kpmg_image &&
            item.kpmg_image.raw &&
            item.kpmg_image.raw !== ''
              ? `https://assets.kpmg/${item.kpmg_image.raw}`
              : '',
          tabType:
            item &&
            item.kpmg_tab_type &&
            item.kpmg_tab_type.raw &&
            item.kpmg_tab_type.raw !== ''
              ? item.kpmg_tab_type.raw
              : '',
          articleType:
            item &&
            item.kpmg_article_type &&
            item.kpmg_article_type.raw &&
            item.kpmg_article_type.raw !== ''
              ? item.kpmg_article_type.raw
              : ''
        }

        queryResults.push(singleResult)
      })

      const executionTime = t1 - t0
      dispatch(
        fetchSuccess(
          {
            queryResults,
            resultCount,
            executionTime,
            currentPage: currentPage || 1
          },
          featuredResults,
          actionMetaData
        )
      )

      dispatch(
        ResultMetaDataStore.actions.fetchSuccess(
          'kpmg.com',
          {
            executionTime: executionTime,
            resultsCount: resultCount
          },
          actionMetaData
        )
      )

      if (deviceSettings.renderMobile) {
        const resultsCombined = Selectors.getResultsCombined(getState())
        if ((currentPage || 1) === 1) {
          dispatch(fetchSuccessCombined({ queryResults }, actionMetaData))
        } else {
          dispatch(
            fetchSuccessCombined(
              { queryResults: [...resultsCombined, ...queryResults] },
              actionMetaData
            )
          )
        }
      }
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'kpmg.com',
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(actionMetaData))
    }
  }
}

export type ResultsAEMActions = IFetchRequest | IFetchFailure | IFetchFailure
