import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFooter(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    box: {
      background: '#efefef',
      borderTop: '1px solid',
      borderColor: 'Silver',
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 20,
      paddingTop: 20,
      display: 'flex',
      [`${theme.breakpoints.down(501)}`]: {
        justifyContent: 'center',
        alignContent: 'center'
      }
    },
    text: {
      fontSize: '15px',
      [`${theme.breakpoints.down(501)}`]: {
        fontSize: '12px',
        justifyContent: 'center',
        alignContent: 'center'
      }
    },
    icon: {
      fontSize: '0.8em',
      marginLeft: 5,
      marginRight: 5
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline'
      }
    }
  }))
}
