import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterBarMoreMenu(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    moreMenuItem: {
      paddingTop: 8,
      paddingBottom: 8,
      fontSize: 14,
      color: '#5f6368',
      paddingLeft: '24px',
      minWidth: '190px'
    },

    noResults: {
      color: '#dfe1e5'
    },

    moreMenuItemIcon: {
      marginRight: 5,
      height: 16,
      width: 16,
      '& > svg': {
        height: 16,
        width: 16
      }
    },
    loadingSpinner: {
      marginLeft: '2px'
    },
    datasourceGroupTitle: {
      fontSize: 14,
      fontWeight: 600,
      paddingLeft: '16px',
      paddingRight: '16px'
    },
    moreMenu: {
      '&>.MuiPaper-root': {
        top: '120px!important',
        minWidth: '380px'
      }
    },
    moreMenuMobile: {
      '&>.MuiPaper-root': {
        top: '140px!important',
        minWidth: '220px',
        maxWidth: '220px'
      }
    },
    root: {
      top: '120px',
      width: 370
    },
    rootMobile: {
      top: '140px',
      width: 210
    },
    linkText: {
      whiteSpace: 'pre',
      fontSize: 13
    }
  }))
}
