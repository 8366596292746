import { IErrorType } from 'components/contents/common/ErrorPage'

export class KPMGFindGlobalVariables {
  //tab & times
  private static currentTab = ''
  private static tabOpenSince: number
  private static loginTimeToApplication: number

  //search
  private static isAutoSuggestQuery = false
  private static searchTerm = ''
  private static searchTermStopWords = ''
  private static lastSearchTerm = ''
  private static enabledFilters: any
  private static isRedirectFromWidget = false

  //user
  private static userName: string | null = null
  private static personInfoCountry: string | null = null
  private static personInfoCity: string | null = null
  private static personInfoFunction: string | null = null
  private static personInfoDepartment: string | null = null
  private static simulateAPIError: IErrorType | null

  //popups
  private static setShowDisabledPopup: any
  private static setDisabledPopupDatasource: any

  //distinct ads, featuredresults & datasource
  private static adShownList: number[] = []
  private static featuredShownList: string[] = []
  private static datasourceShownList: string[] = []

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  //tab & times
  public static setCurrentTab(state: string) {
    KPMGFindGlobalVariables.currentTab = state
  }
  public static getCurrentTab(): string {
    return KPMGFindGlobalVariables.currentTab
  }

  public static setTabOpenSince(state: number) {
    KPMGFindGlobalVariables.tabOpenSince = state
  }
  public static getTabOpenSince(): number {
    return KPMGFindGlobalVariables.tabOpenSince
  }

  public static setLoginTimeToApplication(state: number) {
    KPMGFindGlobalVariables.loginTimeToApplication = state
  }
  public static getLoginTimeToApplication(): number {
    return KPMGFindGlobalVariables.loginTimeToApplication
  }

  //search
  public static setSearchTerm(state: string) {
    if (state && KPMGFindGlobalVariables.searchTerm !== state) {
      KPMGFindGlobalVariables.lastSearchTerm =
        KPMGFindGlobalVariables.searchTerm

      //reset distinct lists
      KPMGFindGlobalVariables.adShownList = []
      KPMGFindGlobalVariables.featuredShownList = []
      KPMGFindGlobalVariables.datasourceShownList = []
      KPMGFindGlobalVariables.searchTermStopWords = ''

      //set searchTerm
      KPMGFindGlobalVariables.searchTerm = state
    }
  }
  public static getSearchTerm(): string {
    return KPMGFindGlobalVariables.searchTerm
  }

  public static setSearchTermStopWords(state: string) {
    if (state && KPMGFindGlobalVariables.searchTermStopWords !== state) {
      //set searchTerm stop words
      KPMGFindGlobalVariables.searchTermStopWords = state
    }
  }
  public static getSearchTermStopWords(): string {
    return KPMGFindGlobalVariables.searchTermStopWords
  }

  public static setLastSearchTerm(state: string) {
    KPMGFindGlobalVariables.lastSearchTerm = state
  }
  public static getLastSearchTerm(): string {
    return KPMGFindGlobalVariables.lastSearchTerm
  }

  public static setEnabledFilters(state: any) {
    KPMGFindGlobalVariables.enabledFilters = state
  }
  public static getEnabledFilters(): string {
    return KPMGFindGlobalVariables.enabledFilters
  }

  public static setIsAutoSuggestQuery(state: boolean) {
    KPMGFindGlobalVariables.isAutoSuggestQuery = state
  }
  public static getIsAutoSuggestQuery(): boolean {
    return KPMGFindGlobalVariables.isAutoSuggestQuery
  }

  //user
  public static setUserName(state: string | null) {
    KPMGFindGlobalVariables.userName = state
  }
  public static getUserName(): string | null {
    return KPMGFindGlobalVariables.userName
  }

  public static setPersonInfoCountry(state: string | null) {
    KPMGFindGlobalVariables.personInfoCountry = state
  }
  public static getPersonInfoCountry(): string | null {
    return KPMGFindGlobalVariables.personInfoCountry
  }

  public static setPersonInfoCity(state: string | null) {
    KPMGFindGlobalVariables.personInfoCity = state
  }
  public static getPersonInfoCity(): string | null {
    return KPMGFindGlobalVariables.personInfoCity
  }

  public static setPersonInfoFunction(state: string | null) {
    KPMGFindGlobalVariables.personInfoFunction = state
  }
  public static getPersonInfoFunction(): string | null {
    return KPMGFindGlobalVariables.personInfoFunction
  }

  public static setPersonInfoDepartment(state: string | null) {
    KPMGFindGlobalVariables.personInfoDepartment = state
  }
  public static getPersonInfoDepartment(): string | null {
    return KPMGFindGlobalVariables.personInfoDepartment
  }

  public static setSimulateAPIError(state: IErrorType | null) {
    KPMGFindGlobalVariables.simulateAPIError = state
  }
  public static getSimulateAPIError(): IErrorType | null {
    return KPMGFindGlobalVariables.simulateAPIError
  }

  //popups
  public static setFuncSetShowDisabledPopup(state: any) {
    KPMGFindGlobalVariables.setShowDisabledPopup = state
  }
  public static getFuncSetShowDisabledPopup(): any {
    return KPMGFindGlobalVariables.setShowDisabledPopup
  }

  public static setFuncSetDisabledPopupDatasource(state: any) {
    KPMGFindGlobalVariables.setDisabledPopupDatasource = state
  }
  public static getFuncSetDisabledPopupDatasource(): any {
    return KPMGFindGlobalVariables.setDisabledPopupDatasource
  }

  //distinct ads, featuredresults & datasource
  public static getAdwordDistinct(id: number) {
    if (KPMGFindGlobalVariables.adShownList.includes(id)) return false

    KPMGFindGlobalVariables.adShownList.push(id)
    return true
  }
  public static getFeaturedResultDistinct(title: string) {
    if (KPMGFindGlobalVariables.featuredShownList.includes(title)) return false

    KPMGFindGlobalVariables.featuredShownList.push(title)
    return true
  }
  public static getDatasourceDistinct(id: string) {
    if (KPMGFindGlobalVariables.datasourceShownList.includes(id)) return false

    KPMGFindGlobalVariables.datasourceShownList.push(id)
    return true
  }
  public static setIsRedirectFromWidget(tempFiltersActive: boolean) {
    KPMGFindGlobalVariables.isRedirectFromWidget = tempFiltersActive
  }
  public static IsRedirectFromWidget() {
    return KPMGFindGlobalVariables.isRedirectFromWidget
  }
}
