import React, { Dispatch } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Typography, Box, Grid, CircularProgress, Link } from '@mui/material'
import { externalLinkClicked } from 'utils/tracking'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { IDeviceSetting } from 'utils/deviceSettings'
import { useSelector, useDispatch } from 'react-redux'
import ResultsStore from 'store/Results'
import { getStylesMarketResearchResult } from 'styles/results/MarketResearchResult'
import * as stringUtils from 'utils/string'
import { useFilter } from 'utils/filters'
import { filterParameterFromUrl } from 'utils/getValidURL'
import TooltipTitle from '../common/TooltipTitle'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { CustomDescription } from '../common/CustomDescription'
import mime from 'mime'
//const pdfjsViewer: any = require('pdfjs-dist/web/pdf_viewer');

interface MarketResearchResultProps {
  documentId: string
  releaseDate: string
  fileType: string
  fileName: string
  companyName: string
  headline: string
  synopsis: string
  pages: string
  language: string
  url: string
  primaryTicker: string
  deviceSettings: IDeviceSetting
  index: number
  disableNoWrap?: boolean
  searchQuery?: string
  isLoadingDocument?: number
  setDownloadMessage?: Dispatch<boolean>
  synonymsApplied: ISynonymsApplied[]
}

export function MarketResearchResult(
  props: MarketResearchResultProps
): JSX.Element {
  const {
    documentId,
    releaseDate,
    fileType,
    fileName,
    companyName,
    headline,
    synopsis,
    pages,
    language,
    url,
    primaryTicker,
    deviceSettings,
    index,
    disableNoWrap,
    searchQuery,
    isLoadingDocument,
    setDownloadMessage,
    synonymsApplied
  } = props
  const intl = useIntl()
  const navigateFunction = useNavigate()
  const classes = getStylesMarketResearchResult()

  const [, setDocumentId] = useFilter('mrDocumentId', '')
  const [, setFileType] = useFilter('mrFileType', '')
  const [isVisited, setVisited] = React.useState(
    useSelector((state: any) =>
      ResultsStore.selectors.isResultVisited(state, url)
    )
  )

  const dispatch = useDispatch()
  const setIsVisited = () => {
    if (!isVisited) {
      dispatch(ResultsStore.actions.storeResultHistory(url))
      setVisited(true)
    }
  }

  const currentUrl = filterParameterFromUrl(window.location.hash, [
    'mrDocumentId',
    'mrFileType'
  ])

  // Check if some special chars are inside the filetype
  let docTypeFlair: string | null = null
  if (!fileType.match(/[^A-Za-z0-9-]+/g) && mime.getType(fileType)) {
    const mimeType: string = mime.getType(fileType) as string
    docTypeFlair = mime.getExtension(mimeType)
  }
  if (!docTypeFlair && fileType === 'url') {
    docTypeFlair = 'url'
  }

  const maxBreadCrumbLength = deviceSettings.isMobile ? 35 : 79

  let itemBreadcrumbNumPages = ''
  if (parseInt(pages) > 0) {
    itemBreadcrumbNumPages =
      ' › ' +
      pages +
      ' ' +
      intl.formatMessage({
        id: 'pages',
        defaultMessage: 'pages'
      })
  }

  let itemBreadcrumbs =
    primaryTicker !== ''
      ? releaseDate +
        ' › Ptkr: ' +
        primaryTicker +
        ' › ' +
        companyName +
        ' › ' +
        language +
        itemBreadcrumbNumPages
      : releaseDate +
        ' › ' +
        companyName +
        ' › ' +
        language +
        itemBreadcrumbNumPages

  if (itemBreadcrumbs.length > maxBreadCrumbLength) {
    itemBreadcrumbs = stringUtils.truncate(itemBreadcrumbs, maxBreadCrumbLength)
  }

  const isDocumentUrl = () => {
    return fileType &&
      fileType.toLowerCase() === 'url' &&
      !(parseInt(pages) > 0)
      ? true
      : false
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Box className={classes.result}>
            <div className={classes.urlWrapper}>
              <Typography
                variant="body1"
                display="inline"
                className={
                  disableNoWrap
                    ? classes.urlBreadcrumbsWordWrap
                    : classes.urlBreadcrumbs
                }
              >
                {itemBreadcrumbs}
              </Typography>
              {docTypeFlair && (
                <Typography
                  variant="caption"
                  display="inline"
                  color="secondary"
                  className={`${classes.docType} ${classes.docTypeWithMargin}`}
                >
                  {docTypeFlair}
                </Typography>
              )}
            </div>
            <Link
              underline={'hover'}
              style={
                isLoadingDocument === -1 ||
                isLoadingDocument === parseInt(documentId)
                  ? { cursor: 'pointer' }
                  : { cursor: 'default', textDecoration: 'none' }
              }
              className={classes.link}
              {...(deviceSettings.openLinksInNewTab === true
                ? { target: '_blank', rel: 'noreferrer' }
                : {})}
              onClick={(event: any) => {
                setIsVisited()
                removeQueryParam(navigateFunction, 'cntx')
                externalLinkClicked({
                  title: headline,
                  url: isDocumentUrl() ? fileName : url,
                  index,
                  featured: false
                })
                if (
                  documentId &&
                  documentId !== '' &&
                  event.button === 0 &&
                  !isDocumentUrl() &&
                  deviceSettings.openLinksInNewTab === false
                ) {
                  setDocumentId(documentId)
                  setFileType(fileType)
                  if (setDownloadMessage) setDownloadMessage(false)
                  event.preventDefault()
                } else if (deviceSettings.openLinksInNewTab === true) {
                  event.preventDefault()
                }
                const linkUrl = isDocumentUrl()
                  ? fileName
                  : currentUrl +
                    '&mrDocumentId=' +
                    documentId +
                    '&mrFileType=' +
                    fileType
                if (deviceSettings.openLinksInNewTab === false) {
                  window.location.href = linkUrl
                } else {
                  window.open(linkUrl, '_blank')
                  event.preventDefault()
                  event.stopPropagation()
                }
              }}
              {...(!deviceSettings.isMobile
                ? {
                    onAuxClick: (event: any) => {
                      setIsVisited()
                      removeQueryParam(navigateFunction, 'cntx')
                      externalLinkClicked({
                        title: headline,
                        url: isDocumentUrl() ? fileName : url,
                        index,
                        featured: false,
                        wasRightClicked: event?.button === 2 ? true : false
                      })
                      if (
                        documentId &&
                        documentId !== '' &&
                        event.button === 0 &&
                        !isDocumentUrl()
                      ) {
                        setDocumentId(documentId)
                        setFileType(fileType)
                        event.preventDefault()
                      }
                    }
                  }
                : {})}
            >
              <Typography
                style={
                  isLoadingDocument === -1 ||
                  isLoadingDocument === parseInt(documentId)
                    ? {}
                    : { textDecoration: 'none' }
                }
                variant="h5"
                color="primary"
                className={`${classes.title} ${
                  isVisited ? classes.isVisitedResult : ''
                }`}
                component="div"
              >
                <div className={classes.titleContainer}>
                  <TooltipTitle
                    title={headline ? headline : ''}
                    singleLine={true}
                    additionalClass={classes.titleContent}
                    useCssOnly={true}
                    wrapperClass={classes.titleContentWrapper}
                  />
                  {isLoadingDocument === parseInt(documentId) && (
                    <div className={classes.titleDocCircularProgressContainer}>
                      <CircularProgress
                        size={14}
                        className={classes.docCircularProgress}
                      />
                    </div>
                  )}
                </div>
              </Typography>
            </Link>

            <Typography
              variant="body2"
              color="secondary"
              gutterBottom
              className={`${classes.body} txt-resultdescription-01`}
              component="div"
            >
              <CustomDescription
                descriptionValue={
                  synopsis
                    ? highlightedText(
                        synopsis.replace(/(\r\n|\n|\r)/gm, ' '),
                        prepareHighlightWords(
                          searchQuery,
                          synopsis.replace(/(\r\n|\n|\r)/gm, ' '),
                          synonymsApplied
                        ),
                        true
                      )
                    : ''
                }
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
