import React, { Dispatch, useMemo } from 'react'
import ReactPlaceholder from 'react-placeholder'
import { Box, Fade } from '@mui/material'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import 'Placeholder.css'
import { Store } from 'store'
import ResultsSourceStore from 'store/ResultsSource'
import TextResult from 'components/contents/results/TextResult'
import Pagination from '../Pagination'
import { trackException } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import SearchSuggestions from 'components/contents/common/SearchSuggestions'
import { capitalizeFirstLetter } from 'utils/string'
import { IDeviceSetting } from 'utils/deviceSettings'
import FeaturedResults from './FeaturedResults'
import LeftWidgetContainer from './widgetscontainer/LeftWidgetContainer'
import { getStylesResults } from 'styles/contents/resultpages/Results'
import { useFilter } from 'utils/filters'
import createDOMPurify from 'dompurify'
import { CognitiveMessage } from '../common/CognitiveMessage'
import { IUserSetting } from 'utils/userSettings'
import { formatDisplayBody } from 'utils/intranet'

interface ResultsSourceMobileProps {
  searchQuery: any
  deviceSettings: IDeviceSetting
  userSettings: IUserSetting
  findConfigCognitiveSearchEnabled: boolean
  cognitiveSearchEnabled: boolean
  showCognitiveMessage: boolean
  setShowCognitiveMessage: Dispatch<boolean>
  setUserSettings: Dispatch<IUserSetting>
}

type AllMobileProps = ReturnType<typeof mapStateToProps> &
  ResultsSourceMobileProps

function ResultsSourceMobile(props: AllMobileProps) {
  const {
    hasResultsBeenFetched,
    resultsCount,
    featuredResults,
    synonymsApplied,
    resultsCombined,
    searchQuery,
    deviceSettings,
    userSettings,
    findConfigCognitiveSearchEnabled,
    cognitiveSearchEnabled,
    showCognitiveMessage,
    setShowCognitiveMessage,
    setUserSettings
  } = props
  const DOMPurify = createDOMPurify(window)
  const resultClasses = getStylesResults()
  const intl = useIntl()
  const [currentPage] = useFilter('page', '1')

  const returnAllItems = (type: string) => {
    if (!resultsCombined || resultsCombined.length < 1) {
      return []
    }

    const items = []

    try {
      const firstResults =
        resultsCombined.length < 4 ? resultsCombined.length : 3

      if (type === 'first') {
        for (let i = 0; i < firstResults; i++) {
          const currentDate = resultsCombined[i].date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].date.replace('Z', '')),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(resultsCombined[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(resultsCombined[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              timeStamp={resultsCombined[i].timestamp}
            />
          )
        }
      } else {
        for (let i = firstResults; i < resultsCombined.length; i++) {
          const currentDate = resultsCombined[i].date
            ? intl.formatDate(
                DOMPurify.sanitize(resultsCombined[i].date.replace('Z', '')),
                dateFormatOptions
              )
            : ''
          const currentBody = DOMPurify.sanitize(resultsCombined[i].body) || ''
          const displayBody = formatDisplayBody(currentDate, currentBody, intl)
          items.push(
            <TextResult
              title={capitalizeFirstLetter(
                DOMPurify.sanitize(resultsCombined[i].title)
              )}
              body={displayBody}
              url={DOMPurify.sanitize(resultsCombined[i].url)}
              featured={false}
              showContextMenu={true}
              key={i}
              index={i}
              searchQuery={DOMPurify.sanitize(searchQuery)}
              deviceSettings={deviceSettings}
              timeStamp={resultsCombined[i].timestamp}
            />
          )
        }
      }
    } catch (error) {
      trackException(
        'Error returning all items in ResultsSourceMobile.tsx',
        error
      )
    }
    return items
  }

  const firstItems = useMemo(
    () => returnAllItems('first'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  const restItems = useMemo(
    () => returnAllItems('rest'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [resultsCombined, intl]
  )

  return (
    <Box className={resultClasses.container}>
      <Box className={resultClasses.resultsContainer}>
        <SearchSuggestions
          wildCardActive={true}
          resultCount={resultsCount}
          resultsFetchend={hasResultsBeenFetched}
        />
        <ReactPlaceholder
          ready={
            hasResultsBeenFetched || Number(currentPage) > 1 ? true : false
          }
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1 ? true : false}
            timeout={600}
          >
            <Box>
              {cognitiveSearchEnabled &&
                showCognitiveMessage &&
                findConfigCognitiveSearchEnabled &&
                synonymsApplied &&
                synonymsApplied.length > 0 && (
                  <CognitiveMessage
                    synonymsApplied={synonymsApplied}
                    setShowCognitiveMessage={setShowCognitiveMessage}
                    userSettings={userSettings}
                    setUserSettings={setUserSettings}
                  />
                )}
              {resultsCombined && resultsCount > 0 && (
                <FeaturedResults featuredResults={featuredResults} />
              )}
              {firstItems}
            </Box>
          </Fade>
        </ReactPlaceholder>
        {hasResultsBeenFetched &&
        (resultsCount < 1 || resultsCombined.length < 1) ? null : (
          <LeftWidgetContainer
            resultCount={resultsCount}
            hasResultsBeenFetched={hasResultsBeenFetched}
          />
        )}
        <ReactPlaceholder
          ready={hasResultsBeenFetched || Number(currentPage) > 1}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Fade
            in={hasResultsBeenFetched || Number(currentPage) > 1}
            timeout={600}
          >
            <Box>
              {restItems}
              {resultsCount != null &&
                resultsCount > 0 &&
                hasResultsBeenFetched && (
                  <Pagination
                    {...props}
                    totalRecords={resultsCount}
                    pageLimit={20}
                    pageNeighbours={4}
                  />
                )}
            </Box>
          </Fade>{' '}
        </ReactPlaceholder>
        <ReactPlaceholder
          ready={hasResultsBeenFetched ? hasResultsBeenFetched : false}
          type="text"
          rows={4}
          showLoadingAnimation={true}
          className={resultClasses.placeholder}
        >
          <Box />
        </ReactPlaceholder>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    featuredResults: ResultsSourceStore.selectors.getFeaturedResults(state),
    synonymsApplied: ResultsSourceStore.selectors.getSynonymsApplied(state),
    resultsCombined: ResultsSourceStore.selectors.getResultsCombined(state),
    resultsCount: ResultsSourceStore.selectors.getResultCount(state),
    executionTime: ResultsSourceStore.selectors.getExecutionTime(state),
    hasResultsBeenFetched:
      ResultsSourceStore.selectors.hasResultsBeenFetched(state)
  }
}

export default connect(mapStateToProps)(ResultsSourceMobile)
