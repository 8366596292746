import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Box, IconButton, Typography } from '@mui/material'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Link from '@mui/material/Link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
import { LeftArrow, RightArrow } from 'components/contents/common/Arrows'
import {
  trackWidgetShown,
  trackException,
  trackLeftWidgetClick,
  trackEvents
} from 'utils/tracking'
import highlightText, { prepareHighlightWords } from 'utils/highlightText'
import { removeQueryParam } from 'utils/queryParams'
import { WidgetKey } from 'constants/widgets'
import 'styles/slick_carousel/slick-theme.css'
import 'styles/slick_carousel/slick.css'
import { getStylesLeftWidget } from 'styles/contents/resultpages/widgets/LeftWidget'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import { getUrlParameterForCurrentFilters } from 'utils/filters'
import FilterStore from 'store/Filters'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import { CustomDescription } from 'components/contents/common/CustomDescription'
import { INewsResult } from 'components/models/NewsResult'

export interface LeftNewsWidgetProps {
  query: string
  results: INewsResult[]
  synonymsApplied: ISynonymsApplied[]
}

type AllProps = ReturnType<typeof mapStateToProps> & LeftNewsWidgetProps

function LeftNewsWidget(props: AllProps): JSX.Element {
  const { results, query, deviceSettings, currentfilters, synonymsApplied } =
    props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const leftWidgetClasses = getStylesLeftWidget()

  const [slideIndex, setSlideIndex] = useState(0)
  const intl = useIntl()

  useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.news,
      widgetPane: 'left'
    })
  }, [])

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    fade: false,
    dragable: true,
    nextArrow:
      slideIndex + 3 >= results.length || deviceSettings.renderMobile ? (
        <></>
      ) : (
        <RightArrow />
      ),
    prevArrow:
      slideIndex === 0 || deviceSettings.renderMobile ? <></> : <LeftArrow />,
    afterChange: (newIndex: any) => setSlideIndex(newIndex)
  }

  let slides: JSX.Element[] = []
  try {
    slides = results.map((item: INewsResult, i: number) => (
      <div key={'ln' + i}>
        <Box key={'lnb1' + i} style={{ width: 20 }} />
        <Link
          key={'lnl' + i}
          onClick={() => {
            removeQueryParam(navigateFunction, 'cntx')
            trackLeftWidgetClick({
              title: DOMPurify.sanitize(item.Title),
              url: DOMPurify.sanitize(item.Link),
              widgetKey: WidgetKey.news,
              index: i
            })
          }}
          {...(!deviceSettings.isMobile
            ? {
                onAuxClick: (event: any) => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackLeftWidgetClick({
                    title: DOMPurify.sanitize(item.Title),
                    url: DOMPurify.sanitize(item.Link),
                    widgetKey: WidgetKey.news,
                    index: i,
                    wasRightClicked: event?.button === 2 ? true : false
                  })
                }
              }
            : {})}
          href={DOMPurify.sanitize(item.Link)}
          className={leftWidgetClasses.cardLink}
          {...(deviceSettings.openLinksInNewTab === true
            ? { target: '_blank', rel: 'noreferrer' }
            : {})}
        >
          <Card key={'lnc' + i} className={leftWidgetClasses.card}>
            <CardContent
              key={'lncc' + i}
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Typography
                key={'lnt1' + i}
                variant="body1"
                color="primary"
                component="div"
                style={{
                  minHeight: 48
                }}
              >
                <TooltipTitle
                  title={DOMPurify.sanitize(item.Title?.trim())}
                  key={'lnd1' + i}
                />
              </Typography>
              <Typography
                key={'lnt2' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{
                  maxHeight: 120,
                  minHeight: 120,
                  maxWidth: 164,
                  overflow: 'hidden'
                }}
              >
                <CustomDescription
                  descriptionValue={
                    item.Summary
                      ? highlightText(
                          DOMPurify.sanitize(item.Summary?.trim()),
                          prepareHighlightWords(
                            DOMPurify.sanitize(query),
                            DOMPurify.sanitize(item.Summary?.trim()),
                            synonymsApplied
                          ),
                          true
                        )
                      : ''
                  }
                  maxLine="6"
                />
              </Typography>
              <Box key={'lnb2' + i} className={leftWidgetClasses.spacer} />
              <Typography
                key={'lnt3' + i}
                variant="body2"
                color="textSecondary"
                component="div"
                style={{ marginTop: 10, fontStyle: 'italic' }}
              >
                {intl.formatMessage({ id: 'origin', defaultMessage: 'Source' })}
                :{' '}
                {intl.formatMessage({
                  id: 'lexis_nexis',
                  defaultMessage: 'LexisNexis'
                })}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      </div>
    ))
  } catch (error) {
    trackException('Error in parsing slides in ResultsNewsFrame.tsx', error)
  }

  if (deviceSettings.renderMobile) {
    slides.push(<Box style={{ flex: '0 0 10px' }} />)
  }

  const seeAllLink = `/results/news/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  if (deviceSettings.renderMobile && slides.length >= 8)
    slides.push(
      <Box className={leftWidgetClasses.showallContainer}>
        <Box className={leftWidgetClasses.showallContent}>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.news,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.news,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <IconButton className={leftWidgetClasses.iconButton}>
              <ArrowForwardIcon></ArrowForwardIcon>
            </IconButton>
          </RouterLink>
          <Typography className={leftWidgetClasses.showallMessage}>
            <FormattedMessage id="show_all" defaultMessage="Show All" />
          </Typography>
        </Box>
      </Box>
    )

  return (
    <Box id={'con-widget-left-news'} className={leftWidgetClasses.container}>
      {results && results.length > 0 && (
        <>
          <RouterLink
            to={seeAllLink}
            style={{ textDecoration: 'none' }}
            onClick={() => {
              trackEvents('widget-see-all', {
                widgetType: WidgetKey.news,
                searchTerm: DOMPurify.sanitize(query),
                wasRightClicked: false
              })
            }}
            {...(!deviceSettings.isMobile
              ? {
                  onAuxClick: (event: any) => {
                    trackEvents('widget-see-all', {
                      widgetType: WidgetKey.news,
                      searchTerm: DOMPurify.sanitize(query),
                      wasRightClicked:
                        event && event.button === 2 ? true : false
                    })
                  }
                }
              : {})}
          >
            <Typography
              variant="h6"
              color="primary"
              component="p"
              className={leftWidgetClasses.link}
              gutterBottom
            >
              {intl.formatMessage({ id: 'news_on', defaultMessage: 'News on' })}
            </Typography>
          </RouterLink>
          <Box className={leftWidgetClasses.cardContainer}>
            {!deviceSettings.renderMobile && slides.length > 0 && (
              <Slider className={leftWidgetClasses.slider} {...settings}>
                {slides}
              </Slider>
            )}
            {deviceSettings.renderMobile && slides.length > 0 && (
              <Box className={leftWidgetClasses.mobileScrollContainer}>
                {slides}
              </Box>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

export default connect(mapStateToProps)(LeftNewsWidget)
