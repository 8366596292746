import { Theme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

export function getStylesTextResult(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    result: {
      marginBottom: '32px',
      [`${theme.breakpoints.down(1024)}`]: {
        backgroundColor: '#fff',
        margin: '0 0 10px 0',
        boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
        borderRadius: '4px',
        paddingTop: '12px',
        paddingBottom: '12px',
        paddingLeft: '16px',
        paddingRight: '16px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    title: {
      fontSize: '20px',
      paddingTop: '4px',
      marginBottom: '3px',
      color: '#004e98',
      width: '100%',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '18px',
        lineHeight: '20px',
        marginBottom: '12px',
        fontWeight: '400',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif'
      }
    },
    urlWrapper: {
      display: 'flex',
      alignItems: 'center'
    },
    url: {
      fontSize: '16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [`${theme.breakpoints.down(1024)}`]: {
        fontSize: '12px',
        fontFamility: 'Roboto, HelveticaNeue, Arial, sans-serif',
        marginBottom: '12px'
      }
    },
    urlBreadcrumbs: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      padding: '1px 0px',
      lineHeight: '1.3',
      color: '#3C4043',
      marginRight: '0',
      whiteSpace: 'nowrap',
      maxWidth: '100%'
    },
    urlBreadcrumbsWordWrap: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: '14px',
      padding: '1px 0px',
      lineHeight: '1.3',
      color: '#3C4043',
      marginRight: '0',
      maxWidth: '100%'
    },
    b: {
      color: '#3c4043',
      fontSize: 14
    },
    link: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1
    },
    body: {
      fontSize: '14px',
      lineHeight: '20px',
      wordWrap: 'break-word', // older browsers
      overflowWrap: 'break-word', // css3,
      color: '#3c4043'
    },
    docType: {
      border: '1px solid #ebebeb',
      borderRadius: '2px',
      color: '#3c4043',
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 'normal',
      height: '14px',
      letterSpacing: '0.75px',
      lineHeight: '16px',
      padding: '0 3px',
      textTransform: 'uppercase',
      position: 'relative',
      top: '-1.5px'
    },
    docTypeWithMargin: {
      marginLeft: '6px'
    },
    docTypeLink: {
      height: 18,
      padding: '1px 0'
    },
    multipleAttachments: {
      border: '1px solid #ebebeb',
      borderRadius: '2px',
      color: '#3c4043',
      display: 'inline-block',
      fontSize: '10px',
      fontWeight: 'normal',
      height: '14px',
      letterSpacing: '0.75px',
      lineHeight: '16px',
      padding: '0 3px',
      position: 'relative',
      top: '-1.5px',
      whiteSpace: 'nowrap'
    },
    isVisitedResult: {
      color: '#609',
      '&:hover': {
        textDecoration: 'underline',
        textDecorationColor: '#609'
      }
    },
    menu: {
      marginTop: '20px'
    },
    menuButton: {
      padding: 0,
      minWidth: '20px',
      marginRight: '0',
      color: 'rgba(0, 0, 0, 0.54)',
      '&:hover, &:focus': {
        backgroundColor: 'transparent'
      }
    },
    originImage: {
      height: 20,
      marginRight: 5
    }
  }))
}
