import React, { useState, Dispatch, useEffect, CSSProperties } from 'react'
import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'
import GridLoader from 'react-spinners/GridLoader'
import { Store } from 'store'
import AuthStore from 'store/Auth'
import UserSettingsStore from 'store/UserSettings'
import SettingsStore from 'store/Settings'
import TranslationsStore from 'store/Translations'
import ContactUsStore from 'store/ContactUs'
import Contents from 'components/contents/Contents'
import Footer from 'components/footer/Footer'
import createDOMPurify from 'dompurify'
import FindNotification from 'components/contents/common/FindNotification'

import {
  trackException,
  trackEvents,
  handleApplicationClose
} from 'utils/tracking'
import {
  IUserSetting,
  updateAdditionalUserSettingProperties,
  syncAndCleanLocalStorage,
  saveUserSettingsToLocalStorage,
  updateLastVisits
} from 'utils/userSettings'
import { authorizeResultPage, getDsNameByLocation } from 'utils/authorization'
import { Fade } from '@mui/material'
import * as Constants from 'constants/constants'
import { getUserDataSources } from 'utils/dataSources'
import {
  supportedLanguages,
  SupportedLanguage
} from 'constants/supportedLanguages'
import GlobalErrorPage from 'components/contents/common/GatewayErrorPage'
import { ISeoCheckerData } from 'utils/seoChecker'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import UnauthenticatedErrorPage from 'components/contents/common/UnauthenticatedErrorPage'
import ContactUsModal from 'components/contents/ContactUsModal'
import { HashRouter } from 'react-router-dom'
import {
  AccountInfo,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser'
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react'
import * as Config from './config'
import { renewAuthorizationToken } from 'utils/token'

interface UserSettingsProps {
  showRoadmap: boolean
  setShowRoadmap: Dispatch<boolean>
  showFaq: boolean
  setShowFaq: Dispatch<boolean>
  isImproveResultsModalOpen: boolean
  setImproveResultsModal: Dispatch<boolean>
  isSEOCheckerModalOpen: boolean
  seoCheckerModalData: ISeoCheckerData
  setSEOCheckerModal: Dispatch<boolean>
  setSEOCheckerModalData: Dispatch<ISeoCheckerData>
  authProvider: PublicClientApplication
  loginTracked: boolean
  setLoginTracked: Dispatch<boolean>
  forceUpdate: Dispatch<any>
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  UserSettingsProps

function UserSettings(props: AllProps) {
  const {
    setAuthToken,
    showRoadmap,
    setShowRoadmap,
    showFaq,
    setShowFaq,
    isImproveResultsModalOpen,
    setImproveResultsModal,
    isSEOCheckerModalOpen,
    seoCheckerModalData,
    setSEOCheckerModal,
    setSEOCheckerModalData,
    setAADInfo,
    loginTracked,
    setLoginTracked,
    fetchUserSettings,
    setUserSettings,
    userSettings,
    hasUserSettingsBeenFetched,
    hasUserSettingsFetchError,
    translations,
    fetchVersionsTranslations,
    fetchDatasourceSettings,
    fetchGlobalTranslations,
    setRenderMobile,
    setDeviceSettings,
    deviceSettings,
    datasourceSettings,
    setCurrentDataSources,
    forceUpdate,
    findConfiguration,
    hasDataSourceSettingsBeenFetched,
    hasFindConfigurationCriticalError,
    hasDataSourceSettingsCriticalError,
    fetchContactUsTiles,
    contactUsTiles,
    hasFindConfigurationBeenFetched,
    setUseCognitiveSearch,
    internalBroadcast
  } = props

  const DOMPurify = createDOMPurify(window)
  const [language, setLanguage] = useState(userSettings.Language || 'en')
  const [showIntroduction, setShowIntroduction] = useState(false)
  const [showDisabledPopup, setShowDisabledPopup] = useState(false)
  const [localizationMessages, setLocalizationMessages] = useState({})
  const [localizationLanguage, setLocalizationLanguage] = useState('')
  const [disabledPopupDatasource, setDisabledPopupDatasource] = useState('')
  const [stateUserName, setStateUserName] = useState('')
  const [stateUserDisplayName, setStateUserDisplayName] = useState('')
  const [showUserSettingsPopup, setShowUserSettingsPopup] = useState(false)
  const [showContactUs, setShowContactUs] = useState(false)
  const [showNoQueryPopup, setShowNoQueryPopup] = useState(false)
  const [tourOpen, setTourOpen] = useState<boolean>(false)
  const [fourceLogout, setForceLogout] = useState(false)

  // Used to block application rendering until the usersettings are loaded
  const [userSettingsFetched, setUserUserSettingsFetched] = useState(false)
  const [criticalError, setCriticalError] = useState(false)
  const [showUnauthenticatedError, setShowUnauthenticatedError] =
    useState(false)
  let unauthenticatedErrorTimer: NodeJS.Timeout | null = null

  KPMGFindGlobalVariables.setFuncSetShowDisabledPopup(setShowDisabledPopup)
  KPMGFindGlobalVariables.setFuncSetDisabledPopupDatasource(
    setDisabledPopupDatasource
  )

  const [windowSize, setWindowSize] = useState(window.innerWidth)

  const MobileDetect = require('mobile-detect')
  const md = new MobileDetect(window.navigator.userAgent)

  const gridLoaderCSS: CSSProperties = {}

  useEffect(() => {
    let renderMobileByWidth = false
    if (window.matchMedia) {
      renderMobileByWidth = window.matchMedia(
        '(max-width: ' + (Constants.desktopViewWidth - 0.5) + 'px)'
      ).matches
    } else {
      renderMobileByWidth = windowSize < Constants.desktopViewWidth
    }
    const renderMobile = renderMobileByWidth

    setRenderMobile(renderMobile)
    forceUpdate(renderMobile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize])

  useEffect(() => {
    setDeviceSettings(md.mobile() !== null)

    function handleResize() {
      // Set window width/height to state
      setWindowSize(window.innerWidth)
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isAuthenticated = useIsAuthenticated()
  const { instance, accounts } = useMsal()

  const request = {
    scopes: [Config.ACTIVE_DIRECTORY_SCOPE]
  }

  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    request
  )

  const loginRedirect = async () => {
    const authRedirectResult = await login(InteractionType.Redirect, request)
    if (!authRedirectResult) setForceLogout(true)
  }

  useEffect(() => {
    if (error) {
      loginRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (fourceLogout) {
      instance.logoutRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fourceLogout])

  useEffect(() => {
    if (isAuthenticated && accounts && accounts.length > 0 && !loginTracked) {
      trackLogin(accounts[0])
      setAADInfo(instance, accounts)
      instance.initialize().then(() => {
        renewAuthorizationToken('', instance, accounts).then(
          (authToken: string) => {
            setAuthToken(authToken)
          }
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  useEffect(() => {
    if (loginTracked) {
      // Fetch version translations from Cosmos DB
      fetchVersionsTranslations(Constants.translationTypes.faq)
      fetchVersionsTranslations(Constants.translationTypes.introduction)
      fetchVersionsTranslations(Constants.translationTypes.roadmap)
      fetchVersionsTranslations(Constants.translationTypes.popups)
      fetchVersionsTranslations(Constants.translationTypes.global).then(() => {
        // Do the global translation fetch after the version fetch is done
        // otherwise nothing will be done overall the first time
        if (
          !localizationMessages ||
          JSON.stringify(localizationMessages) === '{}' ||
          localizationLanguage !== language
        ) {
          fetchGlobalTranslations(language)
          setLocalizationLanguage(language)
        }
      })

      fetchContactUsTiles(language)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, loginTracked])

  useEffect(() => {
    if (translations && JSON.stringify(translations) !== '{}') {
      setLocalizationMessages(translations)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [translations])

  // Tracks the user login into App Insights
  const trackLogin = async (accountInfo: any) => {
    try {
      setStateUserDisplayName(accountInfo.name)
      setStateUserName(accountInfo.username)

      setLoginTracked(true)
    } catch (error) {
      trackException('Error in trackLogin method in App.tsx', error)
    }
  }

  useEffect(() => {
    if (loginTracked) {
      // Fetch datasource settings from Cosmos DB
      fetchDatasourceSettings()
      fetchUserSettings(stateUserName, stateUserDisplayName)

      KPMGFindGlobalVariables.setLoginTimeToApplication(Date.now())

      window.addEventListener('beforeunload', handleApplicationClose)

      return () =>
        window.removeEventListener('beforeunload', handleApplicationClose)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginTracked])

  useEffect(() => {
    if (loginTracked && hasUserSettingsBeenFetched) {
      try {
        if (!userSettings.LanguageManuallySeleted) {
          let userLang =
            window.navigator.language || (window.navigator as any).userLanguage
          userLang = userLang.split('-')[0]

          //check, language is supported
          const languageFound = supportedLanguages.find(
            (languageItem: SupportedLanguage) =>
              languageItem.locale === userLang
          )
          userLang = languageFound ? userLang : 'en'

          if (language !== userLang) {
            setLanguage(userLang)
            userSettings.Language = userLang
          }
        }

        // Sync and clean up the deprecated local storage if needed and sync settins
        syncAndCleanLocalStorage(userSettings.StorageVersion, userSettings)

        if (userSettings.Language !== '') {
          setLanguage(userSettings.Language)
        }

        setShowIntroduction(!userSettings.IntroductionShown)

        updateAdditionalUserSettingProperties(userSettings)

        // After this is done, tracking is available and can be triggered
        trackEvents('login', {
          login_webapp: md.mobile() == null,
          login_webpart: DOMPurify.sanitize(document.referrer),
          login_mobile: md.mobile() != null
        })

        updateLastVisits(userSettings, findConfiguration.FeedbackRequiredVisits)

        // initially set datasources, otherwise the app can't render
        const dataSources = getUserDataSources(
          userSettings,
          deviceSettings,
          datasourceSettings,
          false
        )
        setCurrentDataSources(dataSources)

        authorizeResultPage(
          getDsNameByLocation(window.location),
          userSettings,
          deviceSettings,
          DOMPurify.sanitize(window.location)
        )

        // additionally update the local storage entry for disabled sync
        const localStorageItem = localStorage.getItem(
          'userSettings_localStorage'
        )
        if (!userSettings.UseLocalSettings && !localStorageItem) {
          // Create the usersettings in localstorage if they are not present,
          // must only be done if nothing has changed
          saveUserSettingsToLocalStorage(userSettings)
        }

        if (!userSettings.UseLocalSettings && localStorageItem) {
          // Update country, function, department and city in local storage if has changed
          const localStorageItemDecoded = JSON.parse(localStorageItem)
          if (
            localStorageItemDecoded &&
            ((localStorageItemDecoded.Country &&
              userSettings.Country &&
              localStorageItemDecoded.Country !== userSettings.Country) ||
              (localStorageItemDecoded.Function &&
                userSettings.Function &&
                localStorageItemDecoded.Function !== userSettings.Function) ||
              (localStorageItemDecoded.Department &&
                userSettings.Department &&
                localStorageItemDecoded.Department !==
                  userSettings.Department) ||
              (localStorageItemDecoded.City &&
                userSettings.City &&
                localStorageItemDecoded.City !== userSettings.City))
          ) {
            saveUserSettingsToLocalStorage(userSettings)
          }
        }

        // in case there was an error loading the user settings show the error popup
        if (hasUserSettingsFetchError) {
          setShowUserSettingsPopup(true)
        }

        setUserUserSettingsFetched(true)
      } catch (error) {
        trackException('Error in trackLogin method in App.tsx', error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUserSettingsBeenFetched])

  // Handling datasource list generation for the current user settings after everything is known
  // Save is done every time on application start, to update the last visits
  useEffect(() => {
    if (userSettingsFetched && hasDataSourceSettingsBeenFetched) {
      const dataSourceOrder = userSettings.DataSourceOrder
      const dataSourceDisabled = userSettings.DataSourceDisabled

      const dataSources = getUserDataSources(
        userSettings,
        deviceSettings,
        datasourceSettings,
        userSettingsFetched
      )
      setCurrentDataSources(dataSources)

      let userSettingsChanged = false
      if (
        dataSourceOrder !== userSettings.DataSourceOrder ||
        dataSourceDisabled !== userSettings.DataSourceDisabled
      ) {
        userSettingsChanged = true
      }

      setUserSettings(userSettings, userSettingsChanged)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSettingsFetched, hasDataSourceSettingsBeenFetched])

  useEffect(() => {
    if (
      hasFindConfigurationCriticalError ||
      hasDataSourceSettingsCriticalError
    ) {
      setCriticalError(true)
    }
  }, [hasFindConfigurationCriticalError, hasDataSourceSettingsCriticalError])

  useEffect(() => {
    if (
      hasFindConfigurationBeenFetched &&
      !hasFindConfigurationCriticalError &&
      hasUserSettingsBeenFetched
    ) {
      setUseCognitiveSearch(
        findConfiguration.CognitiveSearchEnabled &&
          userSettings.CognitiveSearchEnabled,
        findConfiguration.EntityRecognitionEnabled &&
          userSettings.EntityRecognitionEnabled
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    findConfiguration.CognitiveSearchEnabled,
    findConfiguration.EntityRecognitionEnabled,
    hasFindConfigurationCriticalError,
    hasFindConfigurationBeenFetched,
    hasUserSettingsBeenFetched,
    userSettings.CognitiveSearchEnabled,
    userSettings.EntityRecognitionEnabled
  ])

  //handle force open more menu
  useEffect(() => {
    if (
      internalBroadcast &&
      internalBroadcast.includes('openModalContactUs') &&
      !showContactUs
    )
      setShowContactUs(true)

    if (
      internalBroadcast &&
      internalBroadcast.includes('closeModalContactUs') &&
      showContactUs
    )
      setShowContactUs(false)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalBroadcast])

  if (isAuthenticated) {
    if (criticalError) {
      return <GlobalErrorPage />
    } else if (!userSettingsFetched) {
      return (
        <Fade in={true} timeout={{ appear: 1500, enter: 15000 }}>
          <div
            className="noborder"
            style={{
              textAlign: 'center',
              backgroundColor: '#fff'
            }}
          >
            <div
              style={{
                display: 'inline-block',
                color: '#004e98',
                fontSize: '5em',
                fontFamily: 'KPMG'
              }}
            >
              KPMG Find
            </div>
            <br />
            <br />
            <div id="gridLoader" style={{ display: 'inline-block' }}>
              <GridLoader
                cssOverride={gridLoaderCSS}
                size={'20px'}
                color={'#004e98'}
                margin={'5px'}
                loading={true}
              />
            </div>
          </div>
        </Fade>
      )
    } else {
      return (
        <IntlProvider locale={language} messages={localizationMessages}>
          <div
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              padding: 0,
              margin: 0
            }}
          >
            <HashRouter>
              <div className="content" style={{ padding: 0, margin: 0 }}>
                <Contents
                  showRoadmap={showRoadmap}
                  setShowRoadmap={setShowRoadmap}
                  showFaq={showFaq}
                  setShowFaq={setShowFaq}
                  isImproveResultsModalOpen={isImproveResultsModalOpen}
                  setImproveResultsModal={setImproveResultsModal}
                  isSEOCheckerModalOpen={isSEOCheckerModalOpen}
                  seoCheckerModalData={seoCheckerModalData}
                  setSEOCheckerModal={setSEOCheckerModal}
                  setSEOCheckerModalData={setSEOCheckerModalData}
                  language={language}
                  setLanguage={setLanguage}
                  showIntroduction={showIntroduction}
                  setShowIntroduction={setShowIntroduction}
                  userDisplayName={stateUserDisplayName}
                  userName={stateUserName}
                  userSettingsFetched={userSettingsFetched}
                  setShowNoQueryPopup={setShowNoQueryPopup}
                  setTourOpen={setTourOpen}
                  tourOpen={tourOpen}
                />
              </div>

              <Footer
                setShowFaq={setShowFaq}
                setImproveResultsModal={setImproveResultsModal}
                userDisplayName={stateUserDisplayName}
                setShowContactUs={setShowContactUs}
                setTourOpen={setTourOpen}
              />
            </HashRouter>
            <FindNotification
              open={showDisabledPopup}
              onClose={() => setShowDisabledPopup(false)}
              translationKey={'snackbar_unavailable_datasource'}
              translationOption={{
                datasource: disabledPopupDatasource
              }}
              defaultMessage={`The data source you requested is not available to you. You have been taken to "${disabledPopupDatasource}" instead.`}
              deviceSettings={deviceSettings}
            />
            <FindNotification
              open={showUserSettingsPopup}
              onClose={() => setShowUserSettingsPopup(false)}
              translationKey={'snackbar_usersettings_errormessage'}
              translationOption={{
                datasource: disabledPopupDatasource
              }}
              defaultMessage={
                'There was an error loading your user settings, all new changes will not be synced to the global store.'
              }
              deviceSettings={deviceSettings}
            />
            {showNoQueryPopup && (
              <FindNotification
                open={showNoQueryPopup}
                onClose={() => setShowNoQueryPopup(false)}
                translationKey={'snackbar_noquery_errormessage'}
                translationOption={{
                  datasource: disabledPopupDatasource
                }}
                defaultMessage={
                  'Please enter a search term in order to get search results.'
                }
                deviceSettings={deviceSettings}
              />
            )}
          </div>
          {showContactUs && (
            <ContactUsModal
              open={showContactUs}
              onClose={() => setShowContactUs(false)}
              contactUsTiles={contactUsTiles}
              renderMobile={deviceSettings.renderMobile}
            />
          )}
        </IntlProvider>
      )
    }
  } else {
    if (unauthenticatedErrorTimer) clearTimeout(unauthenticatedErrorTimer)

    if (error && error.errorCode && error.errorCode === 'user_login_error') {
      unauthenticatedErrorTimer = setTimeout(() => {
        setShowUnauthenticatedError(true)
      }, 3500)
    }

    return <>{showUnauthenticatedError && <UnauthenticatedErrorPage />}</>
  }
}

const mapStateToProps = (state: Store) => {
  return {
    translations: TranslationsStore.selectors.getGlobalTranslations(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    hasUserSettingsBeenFetched:
      UserSettingsStore.selectors.hasUserSettingsBeenFetched(state),
    hasFindConfigurationCriticalError:
      SettingsStore.selectors.getFindConfigurationCriticalError(state),
    hasDataSourceSettingsCriticalError:
      SettingsStore.selectors.getDatasourceSettingsCriticalError(state),
    hasUserSettingsFetchError: UserSettingsStore.selectors.hasError(state),
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    datasourceSettings: SettingsStore.selectors.getDatasourceSettings(state),
    findConfiguration: SettingsStore.selectors.getFindConfiguration(state),
    hasDataSourceSettingsBeenFetched:
      SettingsStore.selectors.gethasDataSourceSettingsBeenFetched(state),
    contactUsTiles: ContactUsStore.selectors.getContactUsTiles(state),
    hasFindConfigurationBeenFetched:
      SettingsStore.selectors.gethasFindConfigurationBeenFetched(state),
    internalBroadcast: SettingsStore.selectors.getInternalBroadcast(state)
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setAuthToken: (authToken: string) =>
      dispatch(AuthStore.actions.setAuthToken(authToken)),
    fetchVersionsTranslations: (translationType: string) =>
      dispatch(
        TranslationsStore.actions.fetchVersionTranslations(translationType)
      ),
    fetchGlobalTranslations: (language: string) =>
      dispatch(TranslationsStore.actions.fetchGlobalTranslations(language)),
    fetchDatasourceSettings: () =>
      dispatch(SettingsStore.actions.fetchDatasourceSettings()),
    fetchUserSettings: (userUpn: string, userDisplayName: string) =>
      dispatch(
        UserSettingsStore.actions.fetchUserSettings(userUpn, userDisplayName)
      ),
    setUserSettings: (userSettings: IUserSetting, settingsChanged: boolean) =>
      dispatch(
        UserSettingsStore.actions.upSertUserSettings(
          userSettings,
          settingsChanged
        )
      ),
    setRenderMobile: (renderMobile: boolean) =>
      dispatch(SettingsStore.actions.setRenderMobile(renderMobile)),
    setDeviceSettings: (isMobile: boolean) =>
      dispatch(SettingsStore.actions.setDeviceSettings(isMobile)),
    setCurrentDataSources: (dataSources: any) =>
      dispatch(SettingsStore.actions.setCurrentDataSources(dataSources)),
    fetchContactUsTiles: (language: string) =>
      dispatch(ContactUsStore.actions.fetchContactUsTiles(language)),
    setUseCognitiveSearch: (
      cognitiveEnabled: boolean,
      entityRecognitionEnabled: boolean
    ) =>
      dispatch(
        SettingsStore.actions.setUseCognitiveSearch(
          cognitiveEnabled,
          entityRecognitionEnabled
        )
      ),
    setAADInfo: (instance: IPublicClientApplication, accounts: AccountInfo[]) =>
      dispatch(AuthStore.actions.setAADInfo(instance, accounts))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
