import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

export function getStylesFilterMenuLabel(): any {
  const renderStyles = getStyles()
  return renderStyles()
}

function getStyles() {
  return makeStyles((theme: Theme) => ({
    inputlabel: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      padding: 0,
      paddingRight: 30,
      whiteSpace: 'nowrap',
      margin: 'auto 0',
      cursor: 'pointer',
      flexShrink: 0
    },
    inputlabelnopointer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      padding: 0,
      paddingRight: 30,
      whiteSpace: 'nowrap',
      margin: 'auto 0',
      flexShrink: 0
    },
    menuDisabled: {
      color: 'rgba(0, 0, 0, 0.2)',
      cursor: 'default'
    },
    selectedText: {
      fontWeight: 700,
      lineHeight: '20px'
    },
    notSelected: {
      fontWeight: 100,
      lineHeight: '20px'
    },
    loadingSpinnerWrapper: {
      width: 20,
      height: 20,
      margin: 'auto 0',
      marginLeft: 8
    },
    loadingSpinner: {
      color: 'rgba(0, 0, 0, 0.2)',
      margin: 5
    },
    downArrow: {
      margin: 'auto 0',
      color: 'grey',
      marginLeft: 8,
      paddingBottom: 2
    },
    singleOptionIcon: {
      cursor: 'pointer',
      fontSize: '13px'
    },
    filterLabel: {
      overflow: 'hidden',
      maxWidth: '180px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    disabledAnimation: {
      transition: 'none'
    }
  }))
}
