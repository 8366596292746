import { AnyAction } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { Store } from '..'
import AuthStore from '../Auth'
import * as Config from 'config'
import { extractResult } from 'utils/o365'
import { getGraphAuthToken, renewAuthorizationToken } from 'utils/token'
import { trackEvents } from 'utils/tracking'
import { buildFiltersOffice365 } from 'utils/filters'
import * as Filters from 'constants/filters'
import { exchangeLicenseIds } from 'constants/exchangelicenseIds'
import Selectors from './selectors'
import ResultMetaDataStore from 'store/ResultMetaData'
import { IUserSetting } from 'utils/userSettings'
import { IDeviceSetting } from 'utils/deviceSettings'
import {
  ActionMetaData,
  CustomAction,
  getActionMetaData
} from 'store/extension/customAction'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import { FeaturedResult } from 'components/models/FeaturedResult'
import { IFindConfiguration } from 'store/Settings/reducers'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'
import SettingsStore from 'store/Settings'
import { removeStopWordsFromQuery } from 'utils/oneIntranet'
import { FetchWithCache } from 'utils/api'
import { FetchWrapper } from 'utils/apiQueue'

export interface IOfficeRequestBody {
  query: string
  from: number
  size: number
  orderBy?: string
  processFeaturedResults: boolean
  featuredResultsRowLimit: string
  featuredResultsOrigin: string
  country: string
  city: string
  function: string
  searchQuery?: string
  cognitiveEnabled?: boolean
}

export enum ResultsOffice365ActionTypes {
  FETCH_REQUEST = 'resultsOffice365/FETCH_REQUEST',
  FETCH_SUCCESS = 'resultsOffice365/FETCH_SUCCESS',
  FETCH_SUCCESS_COMBINED = 'resultsOffice365/FETCH_SUCCESS_COMBINED',
  FETCH_FAILURE = 'resultsOffice365/FETCH_FAILURE',
  FETCH_UNAVAILABLE = 'resultsOffice365/FETCH_UNAVAILABLE',
  FETCH_AVAILABLE = 'FETCH_AVAILABLE',
  INITIALIZE_RESULTS_OFFICE_365 = 'persist/REHYDRATE'
}
export type TabTypes = 'files' | 'mails' | 'calendar' | 'sites' | 'teams'
export interface IFetchRequest
  extends CustomAction<ResultsOffice365ActionTypes> {
  payload: {
    fetchResponse: any
    resetCombined: boolean
    searchQuery: string
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchFailure
  extends CustomAction<ResultsOffice365ActionTypes> {
  meta: {
    type: TabTypes
  }
}
export interface IFetchSuccess
  extends CustomAction<ResultsOffice365ActionTypes> {
  payload: {
    response: any
    featuredResults: FeaturedResult[]
    synonymsApplied: ISynonymsApplied[]
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchSuccessCombined
  extends CustomAction<ResultsOffice365ActionTypes> {
  payload: {
    response: any
  }
  meta: {
    type: TabTypes
  }
}
export interface IFetchUnavailable
  extends CustomAction<ResultsOffice365ActionTypes> {
  meta: {
    type: TabTypes
  }
}
export interface IFetchAvailable
  extends CustomAction<ResultsOffice365ActionTypes> {
  meta: {
    type: TabTypes
  }
}

export const fetchRequest = (
  fetchResponse: any = {},
  type: TabTypes,
  resetCombined: boolean,
  searchQuery: string,
  actionMetaData: ActionMetaData
): IFetchRequest => ({
  type: ResultsOffice365ActionTypes.FETCH_REQUEST,
  payload: {
    fetchResponse,
    resetCombined: resetCombined,
    searchQuery: searchQuery
  },
  meta: { type },
  metaData: {
    ...actionMetaData,
    isStartAction: true
  }
})
export const fetchSuccess = (
  response: any = {},
  featuredResults: FeaturedResult[],
  synonymsApplied: ISynonymsApplied[],
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchSuccess => ({
  type: ResultsOffice365ActionTypes.FETCH_SUCCESS,
  payload: { response, featuredResults, synonymsApplied },
  meta: { type },
  metaData: actionMetaData
})
export const fetchSuccessCombined = (
  response: any = {},
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchSuccessCombined => ({
  type: ResultsOffice365ActionTypes.FETCH_SUCCESS_COMBINED,
  payload: { response },
  meta: { type },
  metaData: actionMetaData
})

export const fetchFailure = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchFailure => ({
  type: ResultsOffice365ActionTypes.FETCH_FAILURE,
  meta: { type },
  metaData: actionMetaData
})
export const fetchUnavailable = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchUnavailable => ({
  type: ResultsOffice365ActionTypes.FETCH_UNAVAILABLE,
  meta: { type },
  metaData: actionMetaData
})
export const fetchAvailable = (
  type: TabTypes,
  actionMetaData: ActionMetaData
): IFetchAvailable => ({
  type: ResultsOffice365ActionTypes.FETCH_AVAILABLE,
  meta: { type },
  metaData: actionMetaData
})
export const fetchResultsOffice365 = (
  searchQuery: string,
  currentPage: number,
  userSettings: IUserSetting,
  deviceSettings: IDeviceSetting,
  findConfiguration: IFindConfiguration,
  filters: { [key: string]: string },
  type: TabTypes = 'files',
  hasLicenseBeenChecked = false,
  isExchangeLicenseAvailable = true,
  tracking = false,
  currentDataSourceName = ''
): ThunkAction<Promise<void>, Store, unknown, AnyAction> => {
  return async (
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
    getState: () => Store
  ) => {
    if (currentDataSourceName && currentDataSourceName === `office365${type}`) {
      return
    }

    const state = getState()
    const actionMetaData = getActionMetaData('office365', type)

    dispatch(
      ResultMetaDataStore.actions.fetchRequest(
        'office365' + type,
        actionMetaData
      )
    )

    try {
      const userName = userSettings.upn
      const userDisplayName = userSettings.DisplayName

      if (!searchQuery || searchQuery === '' || searchQuery === '""') {
        dispatch(fetchFailure(type, actionMetaData))
        return
      }

      // Get and check authentication token
      let graphToken = ''
      const aadInfo = AuthStore.selectors.getAADInfo(getState())
      if (Config.LOCAL_DEVELOPMENT.toString() === 'false') {
        graphToken = await getGraphAuthToken(aadInfo.instance, aadInfo.accounts)
      }

      let pathComponent = 'files'
      const pathLookup = {
        files: 'driveitems',
        mails: 'messages',
        calendar: 'events',
        sites: 'sites',
        teams: 'chatmessages'
      }
      pathComponent = pathLookup[type]

      const resultsCombinedQuery = Selectors.getResultsCombinedQuery(
        getState(),
        type
      )

      // Get and check authentication token
      const esToken = await renewAuthorizationToken(
        aadInfo.accessToken,
        aadInfo.instance,
        aadInfo.accounts
      )

      if (pathComponent === 'messages' || pathComponent === 'events') {
        if (!hasLicenseBeenChecked) {
          const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/getlicensedetails`
          if (
            (graphToken && graphToken !== '') ||
            Config.LOCAL_DEVELOPMENT.toString() === 'true'
          ) {
            const checkLicenseDetails = await fetch(graphAPIUrl, {
              method: 'GET',
              headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
                Authorization: `Bearer ${graphToken}`,
                JWTToken: esToken
              }
            })

            if (!checkLicenseDetails || checkLicenseDetails.status !== 200) {
              dispatch(fetchUnavailable(type, actionMetaData))
              dispatch(
                ResultMetaDataStore.actions.hasError(
                  'office365' + type,
                  {
                    message: 'Check license for user failed.'
                  },
                  undefined,
                  actionMetaData
                )
              )
              return
            } else {
              const response = await checkLicenseDetails.json()
              if (!response.value) {
                dispatch(
                  ResultMetaDataStore.actions.hasError(
                    'office365' + type,
                    {
                      message: 'Check license for user failed.'
                    },
                    undefined,
                    actionMetaData
                  )
                )
                dispatch(fetchUnavailable(type, actionMetaData))
                return
              }
              let hasExchangeLicenseApplied = false
              response.value.forEach((plan: any) => {
                if (plan.servicePlans) {
                  const foundPlan = plan.servicePlans.find(
                    (servicePlan: any) =>
                      exchangeLicenseIds.indexOf(servicePlan.servicePlanId) !==
                        -1 &&
                      servicePlan.provisioningStatus &&
                      servicePlan.provisioningStatus.toLowerCase() === 'success'
                  )
                  if (foundPlan) {
                    hasExchangeLicenseApplied = true
                  }
                }
              })
              if (!hasExchangeLicenseApplied) {
                dispatch(
                  ResultMetaDataStore.actions.hasError(
                    'office365' + type,
                    {
                      message:
                        'Exchange online is not available for the current user.'
                    },
                    undefined,
                    actionMetaData
                  )
                )
                dispatch(fetchUnavailable(type, actionMetaData))
                return
              } else {
                dispatch(fetchAvailable(type, actionMetaData))
              }
            }
          }
        } else if (!isExchangeLicenseAvailable) {
          dispatch(
            ResultMetaDataStore.actions.hasError(
              'office365' + type,
              {
                message:
                  'Exchange online is not available for the current user.'
              },
              undefined,
              actionMetaData
            )
          )
          dispatch(fetchUnavailable(type, actionMetaData))
          return
        }
      }

      if (resultsCombinedQuery !== searchQuery) {
        if (resultsCombinedQuery === '') {
          dispatch(
            fetchRequest(currentPage, type, false, searchQuery, actionMetaData)
          )
        } else {
          dispatch(
            fetchRequest(currentPage, type, true, searchQuery, actionMetaData)
          )
        }
      } else {
        dispatch(
          fetchRequest(currentPage, type, false, searchQuery, actionMetaData)
        )
      }

      let acceptedFilter = Filters.office365files
      if (pathComponent === 'messages') {
        acceptedFilter = Filters.office365mails
      } else if (pathComponent === 'events') {
        acceptedFilter = Filters.office365calendar
      } else if (pathComponent === 'sites') {
        acceptedFilter = Filters.office365sites
      } else if (pathComponent === 'chatmessages') {
        acceptedFilter = Filters.office365teams
      }

      let filter = buildFiltersOffice365(
        filters,
        acceptedFilter,
        userName,
        userDisplayName,
        pathComponent
      )

      // Add default filter for mails, to filter only the received mails
      if (pathComponent === 'messages' && !filters['mtype'] && userName) {
        filter += `${filter ? ' ' : ''}recipients:${userName}`
      }

      const query = `${searchQuery}${filter ? ' ' : ''}${filter}`

      const requestBody: IOfficeRequestBody = {
        query: query,
        from: ((currentPage || 1) - 1) * 20,
        size: 20,
        processFeaturedResults: (currentPage || 1) === 1 ? true : false,
        featuredResultsRowLimit: '3',
        featuredResultsOrigin: 'KPMGFind',
        country: userSettings.Country,
        city: userSettings.City,
        function: userSettings.Function
      }

      // Add order by filter for files
      // events and messages currently didn't support sorting
      if (
        filters.orderBy &&
        (pathComponent === 'driveitems' || pathComponent === 'sites')
      ) {
        const filterOBCat = acceptedFilter.find(
          (fl: FilterCategory) => fl.key === 'orderBy'
        )
        if (filterOBCat) {
          const filterOBOpt = filterOBCat.options.find(
            (opt: Filter) => opt.key === filters.orderBy
          )
          if (filterOBOpt && filterOBOpt.value) {
            requestBody.orderBy = filterOBOpt.value
          }
        }
      }

      const useCognitiveSearch = SettingsStore.selectors.getUseCognitiveSearch(
        getState()
      )

      if (useCognitiveSearch && findConfiguration.CognitiveSearchEnabled) {
        let searchQueryLanguage = ''
        const detectLanguageApiUrl = `${
          Config.APIM_BASE_URL
        }searchapi/detectlanguage?searchQuery=${encodeURIComponent(
          searchQuery
        )}`

        const languageResponse = await FetchWithCache(detectLanguageApiUrl, {
          method: 'POST',
          headers: {
            accept: 'application/json',
            'content-type': 'application/json',
            'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
            Authorization: `Bearer ${esToken}`
          }
        })

        if (
          languageResponse &&
          !languageResponse.hasError &&
          languageResponse.responseJSON
        ) {
          const languageJSON = languageResponse.responseJSON
          if (languageJSON && languageJSON.responseCode === 200) {
            searchQueryLanguage = languageJSON.language
          }
        }

        requestBody.cognitiveEnabled = true
        requestBody.searchQuery = removeStopWordsFromQuery(
          searchQuery,
          searchQueryLanguage,
          useCognitiveSearch,
          findConfiguration.CognitiveSearchEnabled
        )
      }
      const graphAPIUrl = `${Config.APIM_BASE_URL}msgraphapi/post${pathComponent}`

      const t0 = performance.now()

      const response = await FetchWrapper(graphAPIUrl, {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          'Ocp-Apim-Subscription-Key': `${Config.OCP_APIM_SUBSCRIPTION_KEY}`,
          Authorization: `Bearer ${graphToken}`,
          JWTToken: esToken
        },
        body: JSON.stringify(requestBody)
      })

      let results
      if (!response || !response.ok) {
        // In case we get a response back check for the return error response and add
        // it to the data attribute
        let error: any = {}
        try {
          if (response) {
            const contentType = response.headers.get('content-type')
            if (contentType && contentType.startsWith('application/json')) {
              error = await response.json()
            }
          }
        } catch {}

        dispatch(
          ResultMetaDataStore.actions.hasError(
            'office365' + type,
            {
              responseCode: response ? response.status : 500,
              message: response
                ? response.statusText
                : 'Error in fetching Results O365 action',
              exception: response
                ? new Error(response.status + ':' + response.statusText)
                : undefined,
              orginalResponseBody: JSON.stringify(error)
            },
            undefined,
            actionMetaData
          )
        )
        dispatch(fetchFailure(type, actionMetaData))
        return
      } else {
        results = await response.json()
      }

      const t1 = performance.now()
      const extractedResult = extractResult(results)
      const featuredResults =
        results && results.FeaturedResults ? results.FeaturedResults : []
      const synonymsApplied =
        results && results.SynonymsApplied ? results.SynonymsApplied : []

      if (extractedResult) {
        const executionTime = t1 - t0

        let resultCount = extractedResult.total
        if (type !== 'files') {
          resultCount = extractedResult.hits ? extractedResult.hits.length : 0
        }

        if (resultCount === 0 && tracking) {
          trackEvents('zero_result_queries', {
            searchTerm: searchQuery,
            tab: KPMGFindGlobalVariables.getCurrentTab(),
            enabledFilters: KPMGFindGlobalVariables.getEnabledFilters(),
            distinct: resultsCombinedQuery !== searchQuery
          })
        }

        let calculatedResultCount = resultCount
        let isUncertainCount = false

        // Workaround for the wrong informations of more results available for team results
        // So the page get not stuck in endless loadmore loading
        let moreResultsAvailable = extractedResult.moreResultsAvailable
        if (type !== 'files') {
          moreResultsAvailable =
            resultCount >= 20 && extractedResult.moreResultsAvailable
        }

        if (type !== 'files') {
          if ((currentPage || 1) !== 1 && calculatedResultCount > 0) {
            const currentSateResultCount =
              state.resultsOffice365[type].currentResultCount
            const lastPageReached = state.resultsOffice365[type].lastPageReached

            calculatedResultCount = resultCount + 20 * ((currentPage || 1) - 1)

            if (calculatedResultCount < currentSateResultCount) {
              calculatedResultCount = currentSateResultCount
            }

            if (!lastPageReached) {
              isUncertainCount = moreResultsAvailable
            }
          } else if ((currentPage || 1) !== 1 && calculatedResultCount === 0) {
            calculatedResultCount = 20 * ((currentPage || 1) - 1)
            isUncertainCount = moreResultsAvailable
          } else if (resultCount > 0) {
            isUncertainCount = moreResultsAvailable
          }
        }

        dispatch(
          ResultMetaDataStore.actions.fetchSuccess(
            'office365' + type,
            {
              executionTime: executionTime,
              resultsCount: calculatedResultCount,
              isUncertainCount: isUncertainCount
            },
            actionMetaData
          )
        )

        dispatch(
          fetchSuccess(
            {
              executionTime,
              resultCount: resultCount,
              results: extractedResult.hits ? extractedResult.hits : [],
              currentPage: currentPage || 1,
              moreResultsAvailable: moreResultsAvailable,
              searchQuery: searchQuery
            },
            featuredResults,
            synonymsApplied,
            type,
            actionMetaData
          )
        )

        if (deviceSettings.renderMobile) {
          if ((currentPage || 1) === 1) {
            dispatch(
              fetchSuccessCombined(
                {
                  results: extractedResult.hits ? extractedResult.hits : []
                },
                type,
                actionMetaData
              )
            )
          } else {
            const resultsCombined = Selectors.getResultsCombined(
              getState(),
              type
            )
            dispatch(
              fetchSuccessCombined(
                {
                  results: [
                    ...resultsCombined,
                    ...(extractedResult.hits ? extractedResult.hits : [])
                  ]
                },
                type,
                actionMetaData
              )
            )
          }
        }
      }
      return
    } catch (error) {
      dispatch(
        ResultMetaDataStore.actions.hasError(
          'office365' + type,
          undefined,
          error,
          actionMetaData
        )
      )
      dispatch(fetchFailure(type, actionMetaData))
    }
  }
}

export type ResultsOffice365Actions =
  | IFetchRequest
  | IFetchFailure
  | IFetchFailure
  | IFetchUnavailable
  | IFetchAvailable
