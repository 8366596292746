import React, { Dispatch, useState } from 'react'
import { Typography } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { getStylesCognitiveMessage } from 'styles/contents/common/CognitiveMessage'
import { FormattedMessage } from 'react-intl'
import { IUserSetting } from 'utils/userSettings'
import createDOMPurify from 'dompurify'
import { ISynonymsApplied } from 'components/models/SynonymsApplied'

export interface CognitiveMessageProps {
  synonymsApplied: ISynonymsApplied[]
  setShowCognitiveMessage: Dispatch<boolean>
  userSettings: IUserSetting
  setUserSettings: Dispatch<IUserSetting>
}

export function CognitiveMessage(props: CognitiveMessageProps): JSX.Element {
  const {
    synonymsApplied,
    setShowCognitiveMessage,
    userSettings,
    setUserSettings
  } = props

  const [collapseExpanded, setCollapseExpanded] = useState(false)

  const classes = getStylesCognitiveMessage()
  const DOMPurify = createDOMPurify(window)

  let synonyms = ''
  if (synonymsApplied && synonymsApplied.length > 0) {
    synonymsApplied.map((synonymApplied: ISynonymsApplied, index: number) => {
      if (synonymApplied.synonyms && synonymApplied.synonyms.length > 0) {
        if (index > 0 && synonyms !== '') synonyms += ', '
        synonyms += `Words[${synonymApplied.word}, ${
          synonymApplied.synonyms
            ? DOMPurify.sanitize(
                decodeURIComponent(synonymApplied.synonyms.join(', '))
              )
            : ''
        }]`
      }
      return true
    })
  }

  return (
    <>
      {synonyms.length > 0 ? (
        <Typography variant="body1" className={classes.message}>
          <FormattedMessage
            id="altered_search_message"
            defaultMessage="Your search has been altered by the AI to:"
          />
          {synonyms.length >= 190 ? (
            <>
              {collapseExpanded === true ? (
                <KeyboardArrowUpIcon
                  onClick={() => {
                    setCollapseExpanded(false)
                  }}
                  className={classes.arrowIcon}
                />
              ) : (
                <KeyboardArrowDownIcon
                  onClick={() => {
                    setCollapseExpanded(true)
                  }}
                  className={classes.arrowIcon}
                />
              )}
              <Collapse className={classes.collapse} in={collapseExpanded}>
                <Typography variant="body1" className={classes.messageSynonyms}>
                  {synonyms}
                </Typography>
              </Collapse>
            </>
          ) : (
            <Typography variant="body1" className={classes.messageSynonyms}>
              {synonyms}
            </Typography>
          )}

          <Typography
            variant="body1"
            className={classes.messageNoShowSynonyms}
            onClick={() => {
              setShowCognitiveMessage(false)
              userSettings.ShowCognitiveMessage = false
              setUserSettings(userSettings)
            }}
          >
            <FormattedMessage
              id="altered_search_no_show_message"
              defaultMessage="Do not show this message again"
            />
          </Typography>
        </Typography>
      ) : (
        <></>
      )}
    </>
  )
}
