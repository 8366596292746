import React, { useState, useEffect, Dispatch } from 'react'
import { connect } from 'react-redux'
import { Store } from 'store'
import SettingsStore from 'store/Settings'
// import LazyLoad from 'react-lazyload'
import { Box, Typography, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import { useIntl } from 'react-intl'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import { removeQueryParam } from 'utils/queryParams'
import { trackWidgetShown, trackRightWidgetClick } from 'utils/tracking'
import { dateFormatOptions } from 'constants/constants'
import { WidgetKey } from 'constants/widgets'
import SeeAllButton from './SeeAllButton'
import highlightedText, { prepareHighlightWords } from 'utils/highlightText'
import { getStylesRightIntranetWidget } from 'styles/contents/resultpages/widgets/RightIntranetWidget'
import { generateUrlBreadcrumbs } from 'utils/string'
import { getStylesRightWidget } from 'styles/contents/resultpages/widgets/RightWidget'
import {
  getCountryKeyFromName,
  getUrlParameterForCurrentFilters,
  useFilter
} from 'utils/filters'
import createDOMPurify from 'dompurify'
import TooltipTitle from 'components/contents/common/TooltipTitle'
import UserSettingsStore from 'store/UserSettings'
import FilterStore from 'store/Filters'
import { KPMGFindGlobalVariables } from 'store/KPMGFindGlobalVariables'
import LinesEllipsis from 'react-lines-ellipsis'
import HTMLEllipsis from 'react-lines-ellipsis/lib/html'
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC'
import { IOneIntranetResultStore } from 'store/ResultsOneIntranet/reducers'
import { IOneIntranetResult } from 'components/models/OneIntranetResult'

export interface RightIntranetWidgetProps {
  searchQuery: any
  results: IOneIntranetResultStore
}

type AllProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RightIntranetWidgetProps

function RightIntranetWidget(props: AllProps): JSX.Element {
  const {
    searchQuery,
    results,
    deviceSettings,
    currentDataSources,
    currentfilters,
    userSettings,
    clearTemporaryFilter,
    setTemporaryFilter
  } = props

  const DOMPurify = createDOMPurify(window)

  const navigateFunction = useNavigate()
  const rightWidgetClasses = getStylesRightWidget()
  const classes = getStylesRightIntranetWidget()

  const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis)
  const ResponsiveHTMLEllipsis = responsiveHOC()(HTMLEllipsis)

  const intl = useIntl()

  const [itemsShown, setItemsShown] = useState(3)
  const [dataSourceEnabled, setDataSourceEnabled] = useState(false)
  const [currentPage] = useFilter('page', '1')

  const showTen = () => {
    setItemsShown(10)
  }

  const showThree = () => {
    setItemsShown(3)
  }

  React.useEffect(() => {
    showThree()
  }, [currentPage])

  React.useEffect(() => {
    trackWidgetShown({
      widgetType: WidgetKey.intranet,
      widgetPane: 'right'
    })
  }, [])

  const headline = intl.formatMessage({
    id: 'intranet_on',
    defaultMessage: 'Intranet Related'
  })

  useEffect(() => {
    const intranetDatasource = currentDataSources.find(
      (ds) => ds.name.toLowerCase() === 'intranet'
    )
    const intranetDatasourceEnabled = intranetDatasource
      ? intranetDatasource.enabled
      : false

    setDataSourceEnabled(intranetDatasourceEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDataSources])

  const seeAllLink = `/results/all/${getUrlParameterForCurrentFilters(
    currentfilters,
    [{ key: 'page', value: '1' }]
  )}`

  // If widget prefiltering is enabled, add it to the see all link
  const setTemporaryFilters = () => {
    clearTemporaryFilter()
    let countryCode = 'none'
    if (
      userSettings &&
      userSettings.EnableWidgetPrefiltering === true &&
      userSettings.Country &&
      userSettings.Country !== ''
    ) {
      const countryKey = getCountryKeyFromName(userSettings.Country)
      countryCode = countryKey ? countryKey : countryCode
    }

    setTemporaryFilter({
      key: 'country',
      value:
        countryCode === 'none' ? countryCode : countryCode.toLocaleUpperCase()
    })

    KPMGFindGlobalVariables.setIsRedirectFromWidget(true)
  }

  return (
    <Box
      id={'con-widget-right-oneintranet'}
      className={
        results.queryResults.length > 3
          ? rightWidgetClasses.widgetAreaExtend
          : rightWidgetClasses.widgetArea
      }
    >
      <h2 className={rightWidgetClasses.widgetHeadlineH2}>
        <ResponsiveEllipsis
          className={rightWidgetClasses.widgetHeadline}
          basedOn="words"
          text={headline}
          maxLine="2"
        />
      </h2>
      {results.queryResults
        .slice(0, itemsShown)
        .map((item: IOneIntranetResult, index: number) => {
          const currentDate = item.Date
            ? intl.formatDate(DOMPurify.sanitize(item.Date), dateFormatOptions)
            : ''

          // Build URL Breadcrumbs
          const urlBreadcrumbs = generateUrlBreadcrumbs(
            'intranet',
            DOMPurify.sanitize(item.Path),
            false
          )

          return (
            <Box key={'ri' + index}>
              <Link
                underline={'hover'}
                key={'ril' + index}
                className={rightWidgetClasses.linkContainer}
                {...(deviceSettings.openLinksInNewTab === true
                  ? { target: '_blank', rel: 'noreferrer' }
                  : {})}
                href={DOMPurify.sanitize(item.Path)}
                onClick={() => {
                  removeQueryParam(navigateFunction, 'cntx')
                  trackRightWidgetClick({
                    title: DOMPurify.sanitize(item.Title),
                    url: DOMPurify.sanitize(item.Path),
                    index,
                    widgetKey: WidgetKey.intranet,
                    body: DOMPurify.sanitize(item.Body)
                  })
                }}
                {...(!deviceSettings.isMobile
                  ? {
                      onAuxClick: (event: any) => {
                        removeQueryParam(navigateFunction, 'cntx')
                        trackRightWidgetClick({
                          title: DOMPurify.sanitize(item.Title),
                          url: DOMPurify.sanitize(item.Path),
                          index,
                          widgetKey: WidgetKey.intranet,
                          body: DOMPurify.sanitize(item.Body),
                          wasRightClicked: event?.button === 2 ? true : false
                        })
                      }
                    }
                  : {})}
              >
                <Typography
                  key={'rit1' + index}
                  variant="h5"
                  color="primary"
                  className={`${classes.title}`}
                  component="div"
                >
                  <TooltipTitle
                    title={DOMPurify.sanitize(item.Title?.trim())}
                    key={'rid1' + index}
                    singleLine={true}
                  />
                </Typography>
              </Link>

              <Typography
                key={'rit2' + index}
                variant="body2"
                color="secondary"
                gutterBottom
                className={classes.body}
                component="div"
              >
                <>
                  <>
                    {item.Body && (
                      <ResponsiveHTMLEllipsis
                        style={{
                          whiteSpace: 'pre-wrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden'
                        }}
                        basedOn="words"
                        unsafeHTML={highlightedText(
                          DOMPurify.sanitize(item.Body).replace(
                            /(\r\n|\n|\r)/gm,
                            ' '
                          ),
                          prepareHighlightWords(
                            DOMPurify.sanitize(searchQuery),
                            DOMPurify.sanitize(item.Body).replace(
                              /(\r\n|\n|\r)/gm,
                              ' '
                            ),
                            results.synonymsApplied
                          ),
                          true
                        )}
                        maxLine="3"
                      />
                    )}
                  </>
                  <ResponsiveEllipsis
                    className={rightWidgetClasses.widgetFooter}
                    basedOn="words"
                    text={currentDate + ' ›  ' + urlBreadcrumbs.trim()}
                    maxLine="1"
                  />
                </>
              </Typography>
            </Box>
          )
        })}
      {results.queryResults.length > 3 && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={rightWidgetClasses.footerGrid}
        >
          {itemsShown === 3 && (
            <Grid
              item
              id={'btn-right-widget-down-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAll
                  : rightWidgetClasses.arrowContainer
              }
            >
              <KeyboardArrowDown
                onClick={showTen}
                className={rightWidgetClasses.arrowItem}
              />
            </Grid>
          )}
          {itemsShown === 10 && (
            <Grid
              item
              id={'btn-right-widget-up-01'}
              className={
                dataSourceEnabled
                  ? rightWidgetClasses.arrowContainerSeeAllExpanded
                  : rightWidgetClasses.arrowContainerExpanded
              }
            >
              <KeyboardArrowUp
                onClick={showThree}
                className={rightWidgetClasses.arrowItem}
              />
            </Grid>
          )}
          {dataSourceEnabled && results.queryResults.length >= 10 && (
            <SeeAllButton
              searchQuery={DOMPurify.sanitize(searchQuery)}
              url={seeAllLink}
              widgetKey={WidgetKey.intranet}
              onClickCallback={setTemporaryFilters}
            />
          )}
        </Grid>
      )}
    </Box>
  )
}

const mapStateToProps = (state: Store) => {
  return {
    deviceSettings: SettingsStore.selectors.getDeviceSettings(state),
    currentDataSources: SettingsStore.selectors.getCurrentDataSources(state),
    userSettings: UserSettingsStore.selectors.getUserSettings(state),
    currentfilters: FilterStore.selectors.getCurrentFilters(state)
  }
}

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
  return {
    clearTemporaryFilter: () =>
      dispatch(FilterStore.actions.clearTemporaryFilter()),
    setTemporaryFilter: (currentFilter: CurrentFilter) =>
      dispatch(FilterStore.actions.setTemporaryFilter(currentFilter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RightIntranetWidget)
